import React, { useEffect, useRef, useState } from "react";
import {
    Flex,
    Image,
    Text,
    TableContainer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Wrap,
    Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import * as ActionTypes from '../../../../constants/ActionTypes';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import LoaderBlack from 'components/loading/LoaderBlack';
import { MdBrightness1 } from "react-icons/md";

export default function GerarPdfLancamentoProducao() {

    const history = useHistory();
    const pdfListMesesRef01 = useRef(null);
    const pdfListMesesRef02 = useRef(null);
    const pdfListMesesRef03 = useRef(null);
    const pdfListMesesRef04 = useRef(null);
    const pdfListMesesRef05 = useRef(null);
    const pdfListMesesRef06 = useRef(null);
    const pdfListMesesRef07 = useRef(null);
    const pdfListMesesRef08 = useRef(null);
    const pdfListMesesRef09 = useRef(null);
    const pdfListMesesRef10 = useRef(null);
    const pdfListMesesRef11 = useRef(null);
    const pdfListMesesRef12 = useRef(null);
    const pdfListMesesRef13 = useRef(null);
    const pdfListMesesRef14 = useRef(null);
    const pdfListMesesRef15 = useRef(null);
    const pdfListMesesRef16 = useRef(null);
    const pdfListMesesRef17 = useRef(null);
    const pdfListMesesRef18 = useRef(null);
    const pdfListMesesRef19 = useRef(null);
    const pdfListMesesRef20 = useRef(null);
    const pdfListMesesRef21 = useRef(null);

    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [arrayMeses01, setArrayMeses01] = useState([]);
    const [arrayMeses02, setArrayMeses02] = useState([]);
    const [arrayMeses03, setArrayMeses03] = useState([]);
    const [arrayMeses04, setArrayMeses04] = useState([]);
    const [arrayMeses05, setArrayMeses05] = useState([]);
    const [arrayMeses06, setArrayMeses06] = useState([]);
    const [arrayMeses07, setArrayMeses07] = useState([]);
    const [arrayMeses08, setArrayMeses08] = useState([]);
    const [arrayMeses09, setArrayMeses09] = useState([]);
    const [arrayMeses10, setArrayMeses10] = useState([]);
    const [arrayMeses11, setArrayMeses11] = useState([]);
    const [arrayMeses12, setArrayMeses12] = useState([]);
    const [arrayMeses13, setArrayMeses13] = useState([]);
    const [arrayMeses14, setArrayMeses14] = useState([]);
    const [arrayMeses15, setArrayMeses15] = useState([]);
    const [arrayMeses16, setArrayMeses16] = useState([]);
    const [arrayMeses17, setArrayMeses17] = useState([]);
    const [arrayMeses18, setArrayMeses18] = useState([]);
    const [arrayMeses19, setArrayMeses19] = useState([]);
    const [arrayMeses20, setArrayMeses20] = useState([]);
    const [arrayMeses21, setArrayMeses21] = useState([]);

    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [totalGeralPrevisto, setTotalGeralPrevisto] = useState('0');
    const [totalGeralRealizado, setTotalGeralRealizado] = useState('0');
    const [totalGeralPercentual, setTotalGeralPercentual] = useState('0');

    const [totalPrevParcial, setTotalPrevParcial] = useState('0');
    const [totalRealParcial, setTotalRealParcial] = useState('0');
    const [totalPercentParcial, setTotalPercentParcial] = useState('0');

    const [larguraPrimeiraColuna, setLarguraPrimeiraColuna] = useState('70px');
    const [nomeRelatorio, setNomeRelatorio] = useState('Lancamento-producao');
    const [marginText, setMarginText] = useState('0px');
    const [formInputsMeses, setFormInputsMeses] = useState([]);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientOrange = 'linear-gradient(orange.300, orange.200, orange.100)';
    const gradientSoma = 'linear-gradient(orange.700, orange.800, orange.900)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            setLoading(false);
            const param = localStorage.getItem('@HE-telaGerarPdfLancamentoProducao');
            if (param != null) {
                const res = JSON.parse(param);

                const formInputsMesesP = res.formInputsMeses;
                const nomeMesCorrenteP = res.nomeMesCorrente;
                const nomeUaP = res.nomeUa;
                const nomeMunicipioP = res.nomeMunicipio;
                const idUfP = res.idUf;
                const totalGeralPrevistoP = res.totalGeralPrevisto;
                const totalGeralRealizadoP = res.totalGeralRealizado;
                const totalGeralPercentualP = res.totalGeralPercentual;

                setNomeMesCorrente(nomeMesCorrenteP);
                setNomeUa(nomeUaP);
                setNomeMunicipio(nomeMunicipioP);
                setIdUf(idUfP);
                setTotalGeralPrevisto(totalGeralPrevistoP);
                setTotalGeralRealizado(totalGeralRealizadoP);
                setTotalGeralPercentual(totalGeralPercentualP);
                setTotalPrevParcial(res.totalPrevParcial);
                setTotalRealParcial(res.totalRealParcial);
                setTotalPercentParcial(res.totalPercentParcial);
                setFormInputsMeses(formInputsMesesP);

                await constroiArrayMeses(formInputsMesesP);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Lancamento-producao-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);
                setTelaCarregada(true);
            }
        }
        loaderScreen();
        return () => {
            setArrayMeses01([]);
            setArrayMeses02([]);
            setArrayMeses03([]);
            setArrayMeses04([]);
            setArrayMeses05([]);
            setArrayMeses06([]);
            setArrayMeses07([]);
            setArrayMeses08([]);
            setArrayMeses09([]);
            setArrayMeses10([]);
            setArrayMeses11([]);
            setArrayMeses12([]);
            setArrayMeses13([]);
            setArrayMeses14([]);
            setArrayMeses15([]);
            setArrayMeses16([]);
            setArrayMeses17([]);
            setArrayMeses18([]);
            setArrayMeses19([]);
            setArrayMeses20([]);
            setArrayMeses21([]);
        }
    }, [])

    const constroiArrayMeses = async (arr = []) => {    // ESSE CARA E PARA PAGINAR A GERACAO DO PDF
        try {
            let arrayQtd01 = [];
            let arrayQtd02 = [];
            let arrayQtd03 = [];
            let arrayQtd04 = [];
            let arrayQtd05 = [];
            let arrayQtd06 = [];
            let arrayQtd07 = [];
            let arrayQtd08 = [];
            let arrayQtd09 = [];
            let arrayQtd10 = [];
            let arrayQtd11 = [];
            let arrayQtd12 = [];
            let arrayQtd13 = [];
            let arrayQtd14 = [];
            let arrayQtd15 = [];
            let arrayQtd16 = [];
            let arrayQtd17 = [];
            let arrayQtd18 = [];
            let arrayQtd19 = [];
            let arrayQtd20 = [];
            let arrayQtd21 = [];

            const arrlength = arr.length;

            if (arrlength > 0) {

                const qtd1 = arrlength > 0 ? 2 : arrlength;    // ESSE PRIMEIRO SO ADICIONO 13 POR QUE TEM O TITULO NA PRIMEIRA PAGINA
                for (let i = 0; i < qtd1; i++) {
                    if (arr[i] != undefined) arrayQtd01.push(arr[i]);
                }

                const qtd2 = arrlength > 2 ? 4 : 0;
                for (let i = 2; i < qtd2; i++) {
                    if (arr[i] != undefined) arrayQtd02.push(arr[i]);
                }

                const qtd3 = arrlength > 4 ? 6 : 0;
                for (let i = 4; i < qtd3; i++) {
                    if (arr[i] != undefined) arrayQtd03.push(arr[i]);
                }

                const qtd4 = arrlength > 6 ? 8 : 0;
                for (let i = 6; i < qtd4; i++) {
                    if (arr[i] != undefined) arrayQtd04.push(arr[i]);
                }

                const qtd5 = arrlength > 8 ? 10 : 0;
                for (let i = 8; i < qtd5; i++) {
                    if (arr[i] != undefined) arrayQtd05.push(arr[i]);
                }

                const qtd6 = arrlength > 10 ? 12 : 0;
                for (let i = 10; i < qtd6; i++) {
                    if (arr[i] != undefined) arrayQtd06.push(arr[i]);
                }

                const qtd7 = arrlength > 12 ? 14 : 0;
                for (let i = 12; i < qtd7; i++) {
                    if (arr[i] != undefined) arrayQtd07.push(arr[i]);
                }

                const qtd8 = arrlength > 14 ? 16 : 0;
                for (let i = 14; i < qtd8; i++) {
                    if (arr[i] != undefined) arrayQtd08.push(arr[i]);
                }

                const qtd9 = arrlength > 16 ? 18 : 0;
                for (let i = 16; i < qtd9; i++) {
                    if (arr[i] != undefined) arrayQtd09.push(arr[i]);
                }

                const qtd10 = arrlength > 18 ? 20 : 0;
                for (let i = 18; i < qtd10; i++) {
                    if (arr[i] != undefined) arrayQtd10.push(arr[i]);
                }

                const qtd11 = arrlength > 20 ? 22 : 0;
                for (let i = 20; i < qtd11; i++) {
                    if (arr[i] != undefined) arrayQtd11.push(arr[i]);
                }

                const qtd12 = arrlength > 22 ? 24 : 0;
                for (let i = 22; i < qtd12; i++) {
                    if (arr[i] != undefined) arrayQtd12.push(arr[i]);
                }

                const qtd13 = arrlength > 24 ? 26 : 0;
                for (let i = 24; i < qtd13; i++) {
                    if (arr[i] != undefined) arrayQtd13.push(arr[i]);
                }

                const qtd14 = arrlength > 26 ? 28 : 0;
                for (let i = 26; i < qtd14; i++) {
                    if (arr[i] != undefined) arrayQtd14.push(arr[i]);
                }

                const qtd15 = arrlength > 28 ? 30 : 0;
                for (let i = 28; i < qtd15; i++) {
                    if (arr[i] != undefined) arrayQtd15.push(arr[i]);
                }

                const qtd16 = arrlength > 30 ? 32 : 0;
                for (let i = 30; i < qtd16; i++) {
                    if (arr[i] != undefined) arrayQtd16.push(arr[i]);
                }

                const qtd17 = arrlength > 32 ? 34 : 0;
                for (let i = 32; i < qtd17; i++) {
                    if (arr[i] != undefined) arrayQtd17.push(arr[i]);
                }

                const qtd18 = arrlength > 34 ? 36 : 0;
                for (let i = 34; i < qtd18; i++) {
                    if (arr[i] != undefined) arrayQtd18.push(arr[i]);
                }

                const qtd19 = arrlength > 36 ? 38 : 0;
                for (let i = 36; i < qtd19; i++) {
                    if (arr[i] != undefined) arrayQtd19.push(arr[i]);
                }

                const qtd20 = arrlength > 38 ? 40 : 0;
                for (let i = 38; i < qtd20; i++) {
                    if (arr[i] != undefined) arrayQtd20.push(arr[i]);
                }

                const qtd21 = arrlength > 40 ? 42 : 0;
                for (let i = 40; i < qtd21; i++) {
                    if (arr[i] != undefined) arrayQtd21.push(arr[i]);
                }

                let qtdCar = 0;
                arr.map(x => x.nome.length > qtdCar ? qtdCar = x.nome.length : qtdCar = qtdCar);
                if (qtdCar > 10) {
                    let xx = 0;
                    xx = qtdCar < 20 ? qtdCar * 7 : 140;
                    setLarguraPrimeiraColuna(`${xx}px`);
                }

                setArrayMeses01(arrayQtd01);
                setArrayMeses02(arrayQtd02);
                setArrayMeses03(arrayQtd03);
                setArrayMeses04(arrayQtd04);
                setArrayMeses05(arrayQtd05);
                setArrayMeses06(arrayQtd06);
                setArrayMeses07(arrayQtd07);
                setArrayMeses08(arrayQtd08);
                setArrayMeses09(arrayQtd09);
                setArrayMeses10(arrayQtd10);
                setArrayMeses11(arrayQtd11);
                setArrayMeses12(arrayQtd12);
                setArrayMeses13(arrayQtd13);
                setArrayMeses14(arrayQtd14);
                setArrayMeses15(arrayQtd15);
                setArrayMeses16(arrayQtd16);
                setArrayMeses17(arrayQtd17);
                setArrayMeses18(arrayQtd18);
                setArrayMeses19(arrayQtd19);
                setArrayMeses20(arrayQtd20);
                setArrayMeses21(arrayQtd21);
            }
        } catch (error) {
            console.log('Erro na classe GerarPdfLancamentoProducao metodo constroiArrayMeses', error);
        }
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('10px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 2000);
    }

    const gerarPDF = async () => {
        const header = pdfListMesesRef01.current;

        let imgData1 = null;
        let imgHeigh1 = 0;
        let imgWWIDTH1 = 0;

        let imgData3 = null;
        let imgHeigh3 = 0;

        let imgData4 = null;
        let imgHeigh4 = 0;

        let imgData5 = null;
        let imgHeigh5 = 0;

        let imgData6 = null;
        let imgHeigh6 = 0;

        let imgData7 = null;
        let imgHeigh7 = 0;

        let imgData8 = null;
        let imgHeigh8 = 0;

        let imgData9 = null;
        let imgHeigh9 = 0;

        let imgData10 = null;
        let imgHeigh10 = 0;

        let imgData11 = null;
        let imgHeigh11 = 0;

        let imgData12 = null;
        let imgHeigh12 = 0;

        let imgData13 = null;
        let imgHeigh13 = 0;

        let imgData14 = null;
        let imgHeigh14 = 0;

        let imgData15 = null;
        let imgHeigh15 = 0;

        let imgData16 = null;
        let imgHeigh16 = 0;

        let imgData17 = null;
        let imgHeigh17 = 0;

        let imgData18 = null;
        let imgHeigh18 = 0;

        let imgData19 = null;
        let imgHeigh19 = 0;

        let imgData20 = null;
        let imgHeigh20 = 0;

        let imgData21 = null;
        let imgHeigh21 = 0;

        let imgData22 = null;
        let imgHeigh22 = 0;

        await html2canvas(header).then((canvas) => {
            imgData1 = canvas.toDataURL('image/png');
            imgHeigh1 = canvas.height;
            imgWWIDTH1 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH1, pdfHeigh / imgHeigh1);
        pdf.addImage(imgData1, 'PNG', 5, 10, pdfWidth - 10, imgHeigh1 * ratio1);

        if (arrayMeses02.length > 0) {
            const input2 = pdfListMesesRef02.current;
            await html2canvas(input2).then((canvas) => {
                imgData3 = canvas.toDataURL('image/png');
                imgHeigh3 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData3, 'PNG', 5, 10, pdfWidth - 10, imgHeigh3 * ratio1);
            });
        }
        if (arrayMeses03.length > 0) {
            const input3 = pdfListMesesRef03.current;
            await html2canvas(input3).then((canvas) => {
                imgData4 = canvas.toDataURL('image/png');
                imgHeigh4 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData4, 'PNG', 5, 10, pdfWidth - 10, imgHeigh4 * ratio1);
            });
        }
        if (arrayMeses04.length > 0) {
            const input4 = pdfListMesesRef04.current;
            await html2canvas(input4).then((canvas) => {
                imgData5 = canvas.toDataURL('image/png');
                imgHeigh5 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData5, 'PNG', 5, 10, pdfWidth - 10, imgHeigh5 * ratio1);
            });
        }
        if (arrayMeses05.length > 0) {
            const input5 = pdfListMesesRef05.current;
            await html2canvas(input5).then((canvas) => {
                imgData6 = canvas.toDataURL('image/png');
                imgHeigh6 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData6, 'PNG', 5, 10, pdfWidth - 10, imgHeigh6 * ratio1);
            });
        }
        if (arrayMeses06.length > 0) {
            const input6 = pdfListMesesRef06.current;
            await html2canvas(input6).then((canvas) => {
                imgData7 = canvas.toDataURL('image/png');
                imgHeigh7 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData7, 'PNG', 5, 10, pdfWidth - 10, imgHeigh7 * ratio1);
            });
        }
        if (arrayMeses07.length > 0) {
            const input7 = pdfListMesesRef07.current;
            await html2canvas(input7).then((canvas) => {
                imgData8 = canvas.toDataURL('image/png');
                imgHeigh8 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData8, 'PNG', 5, 10, pdfWidth - 10, imgHeigh8 * ratio1);
            });
        }
        if (arrayMeses08.length > 0) {
            const input8 = pdfListMesesRef08.current;
            await html2canvas(input8).then((canvas) => {
                imgData9 = canvas.toDataURL('image/png');
                imgHeigh9 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData9, 'PNG', 5, 10, pdfWidth - 10, imgHeigh9 * ratio1);
            });
        }
        if (arrayMeses09.length > 0) {
            const input9 = pdfListMesesRef09.current;
            await html2canvas(input9).then((canvas) => {
                imgData10 = canvas.toDataURL('image/png');
                imgHeigh10 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData10, 'PNG', 5, 10, pdfWidth - 10, imgHeigh10 * ratio1);
            });
        }
        if (arrayMeses10.length > 0) {
            const input10 = pdfListMesesRef10.current;
            await html2canvas(input10).then((canvas) => {
                imgData11 = canvas.toDataURL('image/png');
                imgHeigh11 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData11, 'PNG', 5, 10, pdfWidth - 10, imgHeigh11 * ratio1);
            });
        }
        if (arrayMeses11.length > 0) {
            const input11 = pdfListMesesRef11.current;
            await html2canvas(input11).then((canvas) => {
                imgData12 = canvas.toDataURL('image/png');
                imgHeigh12 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData12, 'PNG', 5, 10, pdfWidth - 10, imgHeigh12 * ratio1);
            });
        }
        if (arrayMeses12.length > 0) {
            const input12 = pdfListMesesRef12.current;
            await html2canvas(input12).then((canvas) => {
                imgData13 = canvas.toDataURL('image/png');
                imgHeigh13 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData13, 'PNG', 5, 10, pdfWidth - 10, imgHeigh13 * ratio1);
            });
        }
        if (arrayMeses13.length > 0) {
            const input13 = pdfListMesesRef13.current;
            await html2canvas(input13).then((canvas) => {
                imgData14 = canvas.toDataURL('image/png');
                imgHeigh14 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData14, 'PNG', 5, 10, pdfWidth - 10, imgHeigh14 * ratio1);
            });
        }
        if (arrayMeses14.length > 0) {
            const input14 = pdfListMesesRef14.current;
            await html2canvas(input14).then((canvas) => {
                imgData15 = canvas.toDataURL('image/png');
                imgHeigh15 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData15, 'PNG', 5, 10, pdfWidth - 10, imgHeigh15 * ratio1);
            });
        }
        if (arrayMeses15.length > 0) {
            const input15 = pdfListMesesRef15.current;
            await html2canvas(input15).then((canvas) => {
                imgData16 = canvas.toDataURL('image/png');
                imgHeigh16 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData16, 'PNG', 5, 10, pdfWidth - 10, imgHeigh16 * ratio1);
            });
        }
        if (arrayMeses16.length > 0) {
            const input16 = pdfListMesesRef16.current;
            await html2canvas(input16).then((canvas) => {
                imgData17 = canvas.toDataURL('image/png');
                imgHeigh17 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData17, 'PNG', 5, 10, pdfWidth - 10, imgHeigh17 * ratio1);
            });
        }
        if (arrayMeses17.length > 0) {
            const input17 = pdfListMesesRef17.current;
            await html2canvas(input17).then((canvas) => {
                imgData18 = canvas.toDataURL('image/png');
                imgHeigh18 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData18, 'PNG', 5, 10, pdfWidth - 10, imgHeigh18 * ratio1);
            });
        }
        if (arrayMeses18.length > 0) {
            const input18 = pdfListMesesRef18.current;
            await html2canvas(input18).then((canvas) => {
                imgData19 = canvas.toDataURL('image/png');
                imgHeigh19 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData19, 'PNG', 5, 10, pdfWidth - 10, imgHeigh19 * ratio1);
            });
        }
        if (arrayMeses19.length > 0) {
            const input19 = pdfListMesesRef19.current;
            await html2canvas(input19).then((canvas) => {
                imgData20 = canvas.toDataURL('image/png');
                imgHeigh20 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData20, 'PNG', 5, 10, pdfWidth - 10, imgHeigh20 * ratio1);
            });
        }
        if (arrayMeses20.length > 0) {
            const input20 = pdfListMesesRef20.current;
            await html2canvas(input20).then((canvas) => {
                imgData21 = canvas.toDataURL('image/png');
                imgHeigh21 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData21, 'PNG', 5, 10, pdfWidth - 10, imgHeigh21 * ratio1);
            });
        }
        if (arrayMeses02.length > 0) {      // O arrayMeses02 E ESSE MESMO, TEM NO INICIO E AQUI, ESSE CARA E O TOTAL GERAL
            const input21 = pdfListMesesRef21.current;
            await html2canvas(input21).then((canvas) => {
                imgData22 = canvas.toDataURL('image/png');
                imgHeigh22 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData22, 'PNG', 5, 10, pdfWidth - 10, imgHeigh22 * ratio1);
            });
        }
        pdf.save(`${nomeRelatorio}.pdf`);
    }

    function renderCardsLancamentoProducao(array = [], possicaoInit = 0, possicaoFim = 5) {
        const ftSize = '11px';
        const largTr = 1;
        const altura = 0;
        return (
            <Box overflowY="hidden" overflowX={'auto'} maxWidth={'100%'}>
                <Flex justify='space-between' align='center'>
                    {array.listMeses.map((item, ww) => {
                        const valorParcial = item.valorParcial != null ? true : false;
                        const valorAnual = item.valorAnual != null ? true : false;
                        const bgColum = valorParcial || valorAnual ? 'blackAlpha.300' : 'blackAlpha.50';
                        const bgBory = valorParcial || valorAnual ? 'blackAlpha.200' : 'white';
                        const listP = item.listProducaoPessoaAlternanciaItem != null && item.listProducaoPessoaAlternanciaItem.length > 0 ? item.listProducaoPessoaAlternanciaItem : [];
                        if (ww >= possicaoInit && ww <= possicaoFim) {
                            return (
                                <Box key={ww}>
                                    <Flex justify='space-between' align='center'>
                                        <Box>
                                            <Text mb={marginText} mt={'4px'} color={'orange.700'} fontSize='12px' fontWeight='bold' lineHeight={'100%'} align={'center'}>{item.descMesAno}</Text>
                                            <Table size="sm" variant="unstyled">
                                                <Thead>
                                                    <Tr bgGradient={gradientHeader} h={'18px'}>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Alt</Text>
                                                        </Th>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Prev</Text>
                                                        </Th>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Real</Text>
                                                        </Th>
                                                        <Th sx={{ paddingX: largTr, paddingY: altura }} w={'40px'} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>%</Text>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {listP.map((x, y) => {
                                                        return (
                                                            <Tr key={y} bgGradient={gradientOrange} h={'14px'}>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    {/* <Text mb={marginText} color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.letraTarefa}</Text> */}
                                                                    <Text mb={marginText} color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.letraCustomizada}</Text>
                                                                </Td>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text mb={marginText} color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.valorPrevTarefa}</Text>
                                                                </Td>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text mb={marginText} color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{x.valorTarefa}</Text>
                                                                </Td>
                                                                <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgBory}>
                                                                    <Text mb={marginText} color={textColor} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>{`${x.percent}%`}</Text>
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                    <Tr bgGradient={gradientSoma} h={'18px'}>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Total</Text>
                                                        </Td>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='light' align={'center'} lineHeight={'100%'}>{item.valorPrevisto}</Text>
                                                        </Td>
                                                        <Td
                                                            sx={{ paddingX: largTr, paddingY: altura }}
                                                            bg={item.idTipoLancamento == 1 ? 'lime' : 'yellow'}
                                                        >
                                                            <Text
                                                                mb={marginText}
                                                                color={'black'}
                                                                fontSize={ftSize}
                                                                fontWeight='light'
                                                                align={'center'}
                                                                lineHeight={'100%'}>{item.valorRealizado}</Text>
                                                        </Td>
                                                        <Td sx={{ paddingX: largTr, paddingY: altura }} bg={bgColum}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={ftSize} fontWeight='light' align={'center'} lineHeight={'100%'}>{`${item.percent}%`}</Text>
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </Box>
                                    </Flex>
                                </Box>
                            )
                        }
                    })}
                    {possicaoFim > 12 &&
                        <Box>
                            <Flex justify='space-between' align='center'>
                                <Table size="sm" variant="unstyled">
                                    <Thead>
                                        <Tr h={'18px'}>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                                <Text mb={marginText} color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Alt</Text>
                                            </Th>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                                <Text mb={marginText} color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Prev</Text>
                                            </Th>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                                <Text mb={marginText} color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>Real</Text>
                                            </Th>
                                            <Th sx={{ paddingX: largTr, paddingY: altura }} w={'40px'}>
                                                <Text mb={marginText} color={'transparent'} fontSize={ftSize} fontWeight='bold' align={'center'} lineHeight={'100%'}>%</Text>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                            <Td sx={{ paddingX: largTr, paddingY: altura }}></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Flex>
                        </Box>
                    }
                </Flex>
            </Box>
        )
    }

    function renderTotalGeral() {
        const bgParcial = 'rgba(204, 102, 0, 0.4)';
        return (
            <Flex justify='center' align='center' mt={'20px'}>
                <TableContainer borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.500'}>
                    <Table size="sm" variant="unstyled" maxW={'500px'}>
                        <Thead>
                            <Tr h={'50px'} bgGradient={gradientHeader}>
                                <Th textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'}>{'Seringueiro'}</Text>
                                </Th>
                                <Th textTransform={'none'} bg={bgParcial}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Parcial'}</Text>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Previsto'}</Text>
                                </Th>
                                <Th textTransform={'none'} bg={bgParcial}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Parcial'}</Text>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                </Th>
                                <Th textTransform={'none'} bg={bgParcial}>
                                    <Text color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Parcial'}</Text>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Percentual'}</Text>
                                </Th>
                                <Th textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Previsto'}</Text>
                                </Th>
                                <Th textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Realizado'}</Text>
                                </Th>
                                <Th textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'Total Percentual'}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {formInputsMeses.map((input, key) => {
                                return (
                                    <Tr key={key} bgGradient={gradientOrange}>
                                        <Td>
                                            <Text mb={marginText} color={'orange.700'} fontSize='13px' fontWeight='bold' lineHeight={'100%'}>{input.nome}</Text>
                                        </Td>
                                        <Td bg={bgParcial}>
                                            <Text mb={marginText} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalParcialPrevisto}</Text>
                                        </Td>
                                        <Td bg={bgParcial}>
                                            <Text mb={marginText} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalParcialRealizado}</Text>
                                        </Td>
                                        <Td bg={bgParcial}>
                                            <Text mb={marginText} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${input.totalParcialPercentual}%`}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalPrevisto}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{input.totalRealizado}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${input.totalPercentual}%`}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })
                            }
                            <Tr bgGradient={gradientSoma}>
                                <Td>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{'TOTAL'}</Text>
                                </Td>
                                <Td bg={bgParcial}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalPrevParcial}</Text>
                                </Td>
                                <Td bg={bgParcial}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalRealParcial}</Text>
                                </Td>
                                <Td bg={bgParcial}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${totalPercentParcial}%`}</Text>
                                </Td>
                                <Td>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalGeralPrevisto}</Text>
                                </Td>
                                <Td>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{totalGeralRealizado}</Text>
                                </Td>
                                <Td>
                                    <Text mb={marginText} color={textColorHeader} fontSize={'13px'} fontWeight='bold' align={'center'}>{`${totalGeralPercentual}%`}</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        )
    }

    function renderArrayGeracao(arr = []) {
        return (
            <Box>
                {arr.length > 0 ?
                    arr.map((input, key) => {
                        return (
                            <Box key={key} mt={'15px'} pb={'10px'} px={'10px'} borderWidth={'3px'} borderColor={'blackAlpha.600'} borderRadius={'10px'} bg={'gray.100'}>
                                <Box mb={'2px'}>
                                    <Text mb={marginText} mt={'5px'} color={'orange.700'} fontSize='18px' fontWeight='bold' lineHeight={'100%'}>{input.nome}</Text>
                                </Box>
                                {renderCardsLancamentoProducao(input, 0, 4)}
                                {renderCardsLancamentoProducao(input, 5, 9)}
                                {renderCardsLancamentoProducao(input, 10, 14)}
                            </Box>
                        )
                    })
                    :
                    <Text color={'transparent'} fontSize={'10px'}>para nao dar erro</Text>
                }
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'30px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='0px' >
                <Box w={'100%'} mb={'20px'}>
                    <Flex px='25px' justify='space-between' mt='20px' align='center'>
                        <Button h={'30px'} variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                        <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' onClick={downloadPdf} />
                    </Flex>
                </Box>
                <Box ref={pdfListMesesRef01} w={'100%'}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>Lançamento de Produção</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesCorrente}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                        </Box>
                    </Box>
                    <Box>
                        <Flex mt={'1px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='20px' height='20px' color='yellow' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Lançamentos por frequência'}</Text>
                        </Flex>
                        <Flex mt={'1px'} justify='start' align='center'>
                            <Icon as={MdBrightness1} width='20px' height='20px' color='lime' />
                            <Text color={textColor} fontSize='14px' fontWeight='bold' ml={'10px'} lineHeight={'100%'}>{'Lançamentos pelo valor total'}</Text>
                        </Flex>
                    </Box>
                    {renderArrayGeracao(arrayMeses01)}
                </Box>
                <Box ref={pdfListMesesRef02} w={'100%'}>
                    {renderArrayGeracao(arrayMeses02)}
                </Box>
                {arrayMeses03.length > 0 &&
                    <Box ref={pdfListMesesRef03} w={'100%'}>
                        {renderArrayGeracao(arrayMeses03)}
                    </Box>
                }
                {arrayMeses04.length > 0 &&
                    <Box ref={pdfListMesesRef04} w={'100%'}>
                        {renderArrayGeracao(arrayMeses04)}
                    </Box>
                }
                {arrayMeses05.length > 0 &&
                    <Box ref={pdfListMesesRef05} w={'100%'}>
                        {renderArrayGeracao(arrayMeses05)}
                    </Box>
                }
                {arrayMeses06.length > 0 &&
                    <Box ref={pdfListMesesRef06} w={'100%'}>
                        {renderArrayGeracao(arrayMeses06)}
                    </Box>
                }
                {arrayMeses07.length > 0 &&
                    <Box ref={pdfListMesesRef07} w={'100%'}>
                        {renderArrayGeracao(arrayMeses07)}
                    </Box>
                }
                {arrayMeses08.length > 0 &&
                    <Box ref={pdfListMesesRef08} w={'100%'}>
                        {renderArrayGeracao(arrayMeses08)}
                    </Box>
                }
                {arrayMeses09.length > 0 &&
                    <Box ref={pdfListMesesRef09} w={'100%'}>
                        {renderArrayGeracao(arrayMeses09)}
                    </Box>
                }
                {arrayMeses10.length > 0 &&
                    <Box ref={pdfListMesesRef10} w={'100%'}>
                        {renderArrayGeracao(arrayMeses10)}
                    </Box>
                }
                {arrayMeses11.length > 0 &&
                    <Box ref={pdfListMesesRef11} w={'100%'}>
                        {renderArrayGeracao(arrayMeses11)}
                    </Box>
                }
                {arrayMeses12.length > 0 &&
                    <Box ref={pdfListMesesRef12} w={'100%'}>
                        {renderArrayGeracao(arrayMeses12)}
                    </Box>
                }
                {arrayMeses13.length > 0 &&
                    <Box ref={pdfListMesesRef13} w={'100%'}>
                        {renderArrayGeracao(arrayMeses13)}
                    </Box>
                }
                {arrayMeses14.length > 0 &&
                    <Box ref={pdfListMesesRef14} w={'100%'}>
                        {renderArrayGeracao(arrayMeses14)}
                    </Box>
                }
                {arrayMeses15.length > 0 &&
                    <Box ref={pdfListMesesRef15} w={'100%'}>
                        {renderArrayGeracao(arrayMeses15)}
                    </Box>
                }
                {arrayMeses16.length > 0 &&
                    <Box ref={pdfListMesesRef16} w={'100%'}>
                        {renderArrayGeracao(arrayMeses16)}
                    </Box>
                }
                {arrayMeses17.length > 0 &&
                    <Box ref={pdfListMesesRef17} w={'100%'}>
                        {renderArrayGeracao(arrayMeses17)}
                    </Box>
                }
                {arrayMeses18.length > 0 &&
                    <Box ref={pdfListMesesRef18} w={'100%'}>
                        {renderArrayGeracao(arrayMeses18)}
                    </Box>
                }
                {arrayMeses19.length > 0 &&
                    <Box ref={pdfListMesesRef19} w={'100%'}>
                        {renderArrayGeracao(arrayMeses19)}
                    </Box>
                }
                {arrayMeses20.length > 0 &&
                    <Box ref={pdfListMesesRef20} w={'100%'}>
                        {renderArrayGeracao(arrayMeses20)}
                    </Box>
                }
                <Box ref={pdfListMesesRef21} w={'100%'} mt={'10px'}>
                    {renderTotalGeral()}
                </Box>
                <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='space-between'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                </Wrap>
            </Card>
            <LoaderBlack isOpen={loading} subTitulo={'Dependendo da quantidade de registros, esse processo pode levar mais de um minuto'} />
        </Box>
    );
}
