import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Wrap,
    WrapItem,
    Select,
    Input,
    TableContainer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { ID_TIPO_ADUBACAO } from 'model/MockData';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import moment from 'moment';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import ModalDialog from 'components/modal/ModalDialog';

export default function LancarAdubacao() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const [screenFullLoad, setScreenFullLoad] = useState(false);
    const [idUa, setIdUa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [dataInput, setDataInput] = useState('');
    const [statuaPeriodoP, setStatuaPeriodoP] = useState('');
    const [idPessoaP, setidPessoaP] = useState('');
    const [numeroSemanaSelecionado, setNumeroSemanaSelecionado] = useState(0);
    const [letra, setLetra] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [acao, setAcao] = useState('');
    const [idEstimulacaoP, setIdEstimulacaoP] = useState(null);
    const [isValidForm, setIsValidForm] = useState(false);
    const [anoEstimulacao, setAnoEstimulacao] = useState(null);
    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [isButtonExcluir, setIsButtonExcluir] = useState(true);
    const [frequencia, setFrequencia] = useState('');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [listAtividadesGeral, setListAtividadesGeral] = useState([]);
    const [qtdArvoes, setQtdArvoes] = useState('');
    const [producaoEsperada, setProducaoEsperada] = useState('');
    const [openModalDialog, setOpenModalDialog] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            const est1 = localStorage.getItem('@HE-DadosTela-formAdubacao');
            if (est1 != null) {
                const dadosEstimulacao = JSON.parse(est1);

                const idEstimula = dadosEstimulacao.idEstimulacao;
                const statusPerio = dadosEstimulacao.statusPeriodo;
                const dtFimSema = dadosEstimulacao.dtFimSemana;
                const dtInicioSemaX = dadosEstimulacao.dtInicioSemana;
                const numeroSema = dadosEstimulacao.numeroSemana;
                const letraPar = dadosEstimulacao.letraParam;
                const idPess = dadosEstimulacao.idPessoa;

                localStorage.setItem('@HE-id-pessoa-retorno', `${idPess}`);      // ESSE CARA E IMPORTANTE PARA O RETORNO DESSA TELA, E SERA REMOVIDO SEMPRE NO RETORNO

                setIdUa(dadosEstimulacao.idUa);
                setIdSafra(dadosEstimulacao.idSafra);
                setidPessoaP(idPess);
                setLetra(letraPar);
                setNumeroSemanaSelecionado(numeroSema);
                setStatuaPeriodoP(statusPerio);
                setIdEstimulacaoP(idEstimula);
                setFrequencia(dadosEstimulacao.frequencia);
                setNomeSeringueiro(dadosEstimulacao.nomeSeringueiro);
                setListAtividadesGeral(dadosEstimulacao.listAtividadesGeral);
                setProducaoEsperada(dadosEstimulacao.producaoEsperada);
                setQtdArvoes(dadosEstimulacao.qtdArvoes);

                const dtInt = localStorage.getItem('@HE-dtInicioSafraAsync');
                const dtFim = localStorage.getItem('@HE-dtFimSafraAsync');
                setDtInicioSafra(dtInt);
                setDtFimSafra(dtFim);

                const ano = moment(dtFimSema).format('YYYY');
                setAnoEstimulacao(ano);

                const max = dtFimSema;
                const min = dtInicioSemaX;
                setMaxDate(max);
                setMinDate(min);

                const dtParam = dadosEstimulacao.dtParam;

                if (idEstimula != 0) {
                    const dt = dtParam != '' && dtParam != null ? moment(dtParam).format('YYYY-MM-DD') : '';
                    const status = statusPerio != '' && statusPerio != null ? statusPerio : '1';
                    const id = idEstimula;

                    setDataInput(dt);
                    setStatuaPeriodoP(status);
                    setIdEstimulacaoP(id);

                    setIsButtonExcluir(false);
                    setAcao('update');
                } else {
                    setDataInput(min);
                    setStatuaPeriodoP('1');
                    setIsButtonExcluir(true);
                    setAcao('insert');
                }
                setScreenFullLoad(true);
            }
        }
        loaderScreen();
    }, [])

    const excluirAdubacao = async () => {
        try {
            setOpenModalDialog(false);
            setIsValidForm(false);

            if (statuaPeriodoP == '' || dataInput == '') {
                setIsValidForm(true);
                return;
            }
            const json = {
                idEstimulacao: idEstimulacaoP,
                idUa: idUa,
                idSafra: idSafra,
                idPessoa: idPessoaP,
                numeroSemana: numeroSemanaSelecionado,
                ano: anoEstimulacao,
                statusPeriodo: statuaPeriodoP,
                dtSelecionada: dataInput,
                letra: letra,
                dtInicio: dtInicioSafra,
                dtFim: dtFimSafra,
                idTipoCalendario: ActionTypes.ID_TIPO_CALENDARIO_ADUBACAO,
            }

            setLoading(true);
            const response = await Api.postRequest(`estimulacao/deleteEstimulacao`, json);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                history.goBack();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe LancarAdubacao metodo excluirAdubacao', error);
        }
    }

    const salvarAdubacao = async () => {
        try {
            setIsValidForm(false);

            if (statuaPeriodoP == '' || dataInput == '') {
                setIsValidForm(true);
                return;
            }
            const json = {
                idEstimulacao: idEstimulacaoP,
                idUa: idUa,
                idSafra: idSafra,
                idPessoa: idPessoaP,
                numeroSemana: numeroSemanaSelecionado,
                ano: anoEstimulacao,
                statusPeriodo: statuaPeriodoP,
                dtSelecionada: dataInput,
                letra: letra,
                dtInicio: dtInicioSafra,
                dtFim: dtFimSafra,
                idTipoCalendario: ActionTypes.ID_TIPO_CALENDARIO_ADUBACAO,
            }

            let response = null;
            setLoading(true);

            if (acao == 'insert') {
                response = await Api.postRequest(`estimulacao/insertEstimulacao`, json);
            } else {
                response = await Api.putRequest(`estimulacao/updatetEstimulacao`, json);
            }

            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                history.goBack();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe LancarAdubacao metodo salvarAdubacao', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    function renderInfo() {
        const sizeF = '13px';
        const largTr = 0;
        const altura = 0;
        return (
            <Box px={'10px'}>
                <Flex px={'20px'} justify='start' mb={'0px'} align='center'>
                    <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Seringueiro:'}</Text>
                    <Text ml={'15px'} color={'orange.600'} fontSize='18px' fontWeight='700' align={'center'} lineHeight={'100%'}>{nomeSeringueiro}</Text>
                </Flex>
                <Flex px={'20px'} justify='start' mt={'0px'} align='center'>
                    <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Frequência:'}</Text>
                    <Text ml={'15px'} color={'orange.600'} fontSize='18px' fontWeight='700' align={'center'} lineHeight={'100%'}>{frequencia}</Text>
                </Flex>

                <TableContainer borderTopRadius={'8px'} borderBottomRadius={'8px'} mt={'10px'}>
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'25px'} bgGradient={gradientHeader}>
                                <Th w={'10%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'} >
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Letra'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Tam Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Pro Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Clone'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Painel'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Idade Sa'}</Text>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listAtividadesGeral.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.letraTarefa}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.tamanhoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.producaoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomeClone}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomePainel}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.idadeSangria}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            {listAtividadesGeral.length > 0 &&
                                <Tr h={'25px'} bgGradient={gradientHeader} >
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'} >
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Total'}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{qtdArvoes}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{producaoEsperada}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderLancarEstimulacao() {
        return (
            <Box>
                <Wrap spacing='10px' my={'20px'} justify='space-around' alignItems={'center'} p={'1'} mx={'10px'}>
                    <WrapItem>
                        <Box w='100%'>
                            {isValidForm && <Text color={'red.500'} fontSize='14px' fontWeight='700' align={'center'}>Selecione pelo menos um item</Text>}
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Status</Text>
                            <Select
                                value={statuaPeriodoP}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize='13px'
                                fontWeight={'bold'}
                                onChange={(e) => setStatuaPeriodoP(e.target.value)}
                                w={'400px'}
                                h={'35px'}>
                                {ID_TIPO_ADUBACAO.map((it, idx) => {
                                    return (
                                        <option key={idx} style={{ background: 'white' }} value={it.value} >{it.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='100%'>
                            <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Data</Text>
                            <Input
                                value={dataInput}
                                errorBorderColor='crimson'
                                placeholder=""
                                variant='filled'
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize='13px'
                                fontWeight={'bold'}
                                onChange={(e) => setDataInput(e.target.value)}
                                w={'400px'}
                                h={'35px'}
                                bg={'white'}
                                color={inputText}
                                type="date"
                                min={minDate}
                                max={maxDate}
                            />
                        </Box>
                    </WrapItem>
                </Wrap>
                <Box px={'20px'} my={'20px'}>
                    <Wrap spacing='3px' w={'100%'} mx={'3px'} my={'30px'} justify='space-between' >
                        <Box>
                            <Button variant="brand" onClick={() => { history.goBack() }} >Voltar</Button>
                        </Box>
                        <Box>
                            <Button variant="redBrand" onClick={()=> { setOpenModalDialog(true) }} disabled={isButtonExcluir} >Excluir</Button>
                            <Button variant="brand" ml={3} onClick={salvarAdubacao}>Salvar</Button>
                        </Box>
                    </Wrap>
                </Box>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!screenFullLoad) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color={textColor} fontSize='15px' lineHeight='100%' p={'5px'}>Carregando...</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='10px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                {renderAlert()}
                {renderInfo()}
                {renderLancarEstimulacao()}
                <Loader isOpen={loading} />
            </Card>
            {openModalDialog && <ModalDialog isOpen={openModalDialog} titulo={'Excluir'} onPressOk={() => { excluirAdubacao() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={'Deseja Realmente excluir?'} />}
        </Box>
    );
};


