import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    SimpleGrid,
    Box,
    Wrap,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Image,
    WrapItem,
    Stat,
    StatLabel,
    StatNumber,
    Checkbox,
    Center,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import Card from "components/card/Card";
import moment from 'moment';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { useHistory } from "react-router-dom";
import TooltipIcon from 'components/tooltip/TooltipIcon';
import LoaderBlack from 'components/loading/LoaderBlack';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { ARRAY_CONSUMO, ARRAY_PROFUNDIDADE, ARRAY_FERIMENTO, ARRAY_ASPECOTOS } from 'model/MockData'
import { calculoGeralRelatorio, carregarArvoresParaUmaSangria } from '../../../../util/CalculosRelatorioQualidadeSangria';
import { calculaTotalDePontosArvoreSemDivisao } from '../../../../util/FuncoesUteis';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import { SALVAR_RELATORIO_PDF_SERVIDOR } from '../../../../util/SalvarImagem';

export default function RelatorioQualidadeSangriaEsporadico() {

    const pdfRef = useRef();
    const pdfRefoto = useRef();
    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);

    const [arrayProfundidadeP, setArrayProfundidadeP] = useState([]);
    const [arrayConsumoP, setArrayConsumoP] = useState([]);
    const [arrayFerimentoP, setArrayFerimentoP] = useState([]);
    const [arrayAspectoP, setArrayAspectoP] = useState([]);
    const [ttConsumo, setTtConsumo] = useState(0);
    const [ttProfundidade, setTtProfundidade] = useState(0);
    const [ttFerimento, setTtFerimento] = useState(0);
    const [ttAspecto, setTtAspecto] = useState(0);
    const [mediaGeralDePontos, setMediaGeralDePontos] = useState(0);
    const [notaGeral, setNotaGeral] = useState(0);
    const [avaliacaoGeral, setAvaliacaoGeral] = useState('');
    const [corNotaGeral, setCorNotaGeral] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');
    const [nome, setNome] = useState('');
    const [observacao, setObservacao] = useState('');
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [nomeMesSelecionado, setNomeMesSelecionado] = useState('');

    const [nomeUa, setNomeUa] = useState('');
    const [letra, setLetra] = useState('-');
    const [nomeQuantidadeTarefas, setNomeQuantidadeTarefas] = useState('-');
    const [nomeCasca, setNomeCasca] = useState('-');
    const [nomeSentido, setNomeSentido] = useState('-');
    const [nomeRelatorio, setNomeRelatorio] = useState('Qualidade-sangria');
    const [marginText, setMarginText] = useState('0px');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [arrayFilesQualidade, setArrayFilesQualidade] = useState([]);
    const [gerarCopiaPdfServidor, setGerarCopiaPdfServidor] = useState(true);

    const colorFooterAndHeader = useColorModeValue("orange.600", "black");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const GRADIENTE = 'linear-gradient(to bottom, #e6e6e6, #ffffff, #e6e6e6)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));

            setNomeMesSelecionado(nmMes);
            setNomeMunicipio(nomeMunicipioP);
            setIdUf(idUfP);

            const dadosR = localStorage.getItem('@HE-dadosParaRelatorioQualidadeSangriaEsporadico');
            if (dadosR != null) {
                const param = JSON.parse(dadosR);

                const nomeSerin = param.nome;
                const nmRelatorio = `Qualidade-sangria_${nomeSerin}_${nmMes}_${ano}`;
                setNomeRelatorio(nmRelatorio);

                const dtPeriodoDisponivel = param.dtPeriodoDisponivel;
                const nmUa = localStorage.getItem('@HE-nomeUa');
                setNomeUa(nmUa);

                const dataParametro = moment(dtPeriodoDisponivel).startOf('month').format('YYYY-MM-DD');
                setMesSelecionado(dataParametro);
                await pesquisaQualidadeSan(dataParametro);
            }
        }
        loaderScreen();
    }, [])

    // CONVERTENDO URL DE IMAGEM EM OBJ File DEPOIS EM BASE64 UNICO JEITO DA IMAGEM APARECER NO RELATORIO PDF
    const carregarImagesRelatorio = async (imageUrls) => {
        try {
            let obj = [];
            const files22 = await Promise.all(
                imageUrls.map(async (imageUrl, index) => {
                    let response = await fetch(imageUrl.urlStorage);
                    response = response.status == 200 ? response : await fetch(`${ActionTypes.LINK_GOOGLE_STORAGE}/sistema_image_notfound2.png`);
                    const blob = await response.blob();
                    return new File([blob], `image_${index}.jpg`, { type: 'image/jpeg' });
                }));
            await new Promise(resolve => {
                resolve(
                    files22.map(async function (file) {
                        if (file) {
                            const nomeFile = file.name;
                            const readerProduca = new FileReader();
                            readerProduca.onloadend = () => {
                                const base64String = readerProduca.result;
                                const objFile = {
                                    nomeFile: nomeFile,
                                    fileBase64: base64String
                                }
                                if (obj.length > 0) {
                                    const newArray = [objFile];
                                    const arr = [...obj, ...newArray];
                                    setArrayFilesQualidade(arr);
                                } else {
                                    const newArray = [objFile];
                                    setArrayFilesQualidade(newArray);
                                }
                                obj.push(objFile);
                            };
                            readerProduca.readAsDataURL(file);
                        }
                    })
                )
            });
        } catch (error) {
            console.log('Erro na classe RelatorioQualidadeSangriaEsporadico metodo carregarImagesRelatorio', error);
        }
    }

    const pesquisaQualidadeSan = async (dataPesquisa22) => {
        try {
            setLoading(true);
            setObservacao('');
            setArrayProfundidadeP(ARRAY_CONSUMO);
            setArrayConsumoP(ARRAY_PROFUNDIDADE);
            setArrayFerimentoP(ARRAY_FERIMENTO);
            setArrayAspectoP(ARRAY_ASPECOTOS);
            setTtConsumo(0);
            setTtProfundidade(0);
            setTtFerimento(0);
            setTtAspecto(0);

            const dadosR = localStorage.getItem('@HE-dadosParaRelatorioQualidadeSangriaEsporadico');
            const param = JSON.parse(dadosR);

            const dt = moment(dataPesquisa22).format('YYYY-MM-DD');
            const ano = moment(dt).format('YYYY');

            setAnoCorrente(ano);
            setNome(param.nome);

            let pontosGeral = 0;
            let notaGeral = 0;
            let qtdDivisao = 0;
            let ultimoId = 0;

            const res = param;
            const listQualidadeSangria = res.listQualidadeSangria;
            const listUrlImagens = res.listUrlImagens;

            if (listUrlImagens.length > 0) {
                const arrImg = listUrlImagens.filter(x => x.idTipoAnexo == ActionTypes.ID_TIPO_ANEXO_IMAGEM);
                await carregarImagesRelatorio(arrImg);
            }

            let arrTemp = [];
            if (listQualidadeSangria.length > 0) {

                const arrayOrdenado = await listQualidadeSangria.sort(function (a, b) { return a.idQualidadeSangria - b.idQualidadeSangria });
                ultimoId = arrayOrdenado[arrayOrdenado.length - 1].idQualidadeSangria;

                const res2 = listQualidadeSangria[0];
                const letraP = res2.letra != null ? res2.letra : '-';
                const nomeQuantidadeTarefasP = res2.nomeQuantidadeTarefas != null ? res2.nomeQuantidadeTarefas : '-';
                const nomeCascaP = res2.nomeCasca != null ? res2.nomeCasca : '-';
                const nomeSentidoP = res2.nomeSentido != null && res2.nomeSentido != '' ? res2.nomeSentido : '-';
                setLetra(letraP);
                setNomeQuantidadeTarefas(nomeQuantidadeTarefasP);
                setNomeCasca(nomeCascaP);
                setNomeSentido(nomeSentidoP);

                const jsonDadosSeringueiro = {
                    idPessoa: res2.idPessoa,
                    nome: res2.nome,
                }
                /*
                ╔══════════════════════════════════════════════════════════════════╗
                    CARREGAR AS ARVORES                        
                ╚══════════════════════════════════════════════════════════════════╝ */
                const arrayArvoresParaTela = await carregarArvoresParaUmaSangria(res2, jsonDadosSeringueiro);
                let urlImagEvidencia = null;
                if (res2.urlArquivo != null) {
                    const urlArq = res2.urlArquivo;
                    const imgP = urlArq.urlStorage;
                    urlImagEvidencia = imgP;
                }

                setObservacao(res2.observacao != null ? res2.observacao : '');
                let totalDePontosP = 0;
                const arr = res2.listArvore;

                if (arr.length > 0) {
                    totalDePontosP = await calculaTotalDePontosArvoreSemDivisao(arr);
                }
                /*
                ╔══════════════════════════════════════════════════════════════════╗
                    CALCULANDO VALORES E CORES DOS CARDS
                ╚══════════════════════════════════════════════════════════════════╝ */
                totalDePontosP = totalDePontosP.toFixed(2);
                totalDePontosP = Number(totalDePontosP);

                const pontosSangria = Number(res2.pontos);
                let notaSeringueiroP = pontosSangria - totalDePontosP;
                notaSeringueiroP = notaSeringueiroP.toFixed(2);
                notaSeringueiroP = Number(notaSeringueiroP);
                const constSoParaCalculo = parseFloat(notaSeringueiroP.toFixed(0));

                let avalia = '';
                let corN = 'black';
                if (constSoParaCalculo >= 95) {
                    corN = 'green.500';
                    avalia = 'Boa';
                } else if (constSoParaCalculo <= 94 && constSoParaCalculo >= 90) {
                    corN = 'orange.600';
                    avalia = 'Média';
                } else {
                    corN = 'red';
                    avalia = 'Ruim';
                }

                if (totalDePontosP > 0) {
                    pontosGeral = pontosGeral + totalDePontosP;
                    notaGeral = notaGeral + notaSeringueiroP;
                    qtdDivisao = qtdDivisao + 1;
                }

                const ss = parseFloat(notaSeringueiroP.toFixed(2));
                const xx = parseFloat(totalDePontosP.toFixed(2));

                const jsonF = {
                    idQualidadeSangria: res2.idQualidadeSangria,
                    idPessoa: res2.idPessoa,
                    nome: res2.nome,
                    observacao: res2.observacao != null ? res2.observacao : '',
                    descMes: res2.descMes,
                    avaliacaoFinalizada: res2.avaliacaoFinalizada,
                    dtPeriodoDisponivel: res2.dtPeriodoDisponivel,
                    listArvore: arrayArvoresParaTela,
                    notaSeringueiro: ss,
                    totalPontos: xx,
                    avaliacaoSangria: avalia,
                    corNota: corN,
                    urlImage: urlImagEvidencia,
                }

                arrTemp.push(jsonF);

                if (ultimoId == res2.idQualidadeSangria) {
                    const res = await calculoGeralRelatorio(pontosGeral, notaGeral, qtdDivisao);
                    setMediaGeralDePontos(res.mediaGeralDePontos);
                    setNotaGeral(res.notaGeral);
                    setAvaliacaoGeral(res.avaliacaoGeral);
                    setCorNotaGeral(res.corNotaGeral);
                }
            } else {
                setMediaGeralDePontos(0);
                setNotaGeral(0);
                setCorNotaGeral('black');
                setAvaliacaoGeral('-');
                setLetra('-');
                setNomeQuantidadeTarefas('-');
                setNomeCasca('-');
                setNomeSentido('-');
            }

            await cacularCriterios(arrTemp[0]);
            setLoading(false);
            setTelaCarregada(true);

            setTelaCarregada(true);
        } catch (error) {
            setLoading(false);
            setTelaCarregada(true);
            console.log('Erro na classe RelatorioQualidadeSangriaEsporadico metodo pesquisaQualidadeSan', error);
        }
    }

    /*
    ╔══════════════════════════════════════════════════════════════════╗
        CALCULANDO VALOR DOS CRITERIOS, SAO OS VALORES TIPO
        CONSUMO, PROFUNDIDADE, FERIMENTO E ASPECTOS
    ╚══════════════════════════════════════════════════════════════════╝ */
    const cacularCriterios = async (re) => {
        try {
            if (re != undefined) {

                if (re.listArvore != null && re.listArvore != undefined) {

                    const arr = re.listArvore;
                    let qtdArvoresParaDivisao = 1;

                    if (qtdArvoresParaDivisao == 0) {
                        qtdArvoresParaDivisao = 1;
                    }
                    let consumoBaixoV = 0;
                    let consumoMedioV = 0;
                    let consumoAltoV = 0;
                    let ataqueGeratrizesV = 0;
                    let declividadeCorteV = 0;
                    let conformidadeEquipamentosV = 0;
                    let impurezasCoaguloV = 0;
                    let facaNaoAmoladaV = 0;
                    let arvoreSangradaV = 0;
                    let controleFitossanitarioV = 0;
                    let latexPerdaProducaoV = 0;
                    let profundidadeBaixoV = 0;
                    let profundidadeMedioV = 0;
                    let profundidadeAltoV = 0;
                    let valorFerimentoDesviadoV = 0;
                    let valorFerimentoNaoDesviadoV = 0;
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        ARRAY CONSUMO
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    arr.map(item => {
                        const obj = item.objArvore;
                        if (obj != null) {
                            if (obj.consumoBaixo != null && obj.consumoBaixo != '') {
                                consumoBaixoV = consumoBaixoV + Number(obj.consumoBaixo);
                            }
                            if (obj.consumoMedio != null && obj.consumoMedio != '') {
                                consumoMedioV = consumoMedioV + Number(obj.consumoMedio);
                            }
                            if (obj.consumoAlto != null && obj.consumoAlto != '') {
                                consumoAltoV = consumoAltoV + Number(obj.consumoAlto);
                            }
                        }
                    })
                    let ttC = consumoBaixoV + consumoMedioV + consumoAltoV;
                    ttC = ttC / qtdArvoresParaDivisao;
                    const s1 = parseFloat(ttC.toFixed(2));
                    setTtConsumo(s1);
                    let arrayConsumo = [
                        { coluna: 'Consumo baixo', valor: consumoBaixoV },
                        { coluna: 'Consumo médio', valor: consumoMedioV },
                        { coluna: 'Consumo alto', valor: consumoAltoV },
                    ]
                    const arr1 = divideCriterios(arrayConsumo, qtdArvoresParaDivisao);
                    setArrayConsumoP(arr1);
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        ARRAY PROFUNDIDADE
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    arr.map(item => {
                        const obj = item.objArvore;
                        if (obj != null) {
                            if (obj.profundidadeBaixo != null && obj.profundidadeBaixo != '') {
                                profundidadeBaixoV = profundidadeBaixoV + Number(obj.profundidadeBaixo);
                            }
                            if (obj.profundidadeMedio != null && obj.profundidadeMedio != '') {
                                profundidadeMedioV = profundidadeMedioV + Number(obj.profundidadeMedio);
                            }
                            if (obj.profundidadeAlto != null && obj.profundidadeAlto != '') {
                                profundidadeAltoV = profundidadeAltoV + Number(obj.profundidadeAlto);
                            }
                        }
                    })
                    let ttProf = profundidadeBaixoV + profundidadeMedioV + profundidadeAltoV;
                    ttProf = ttProf / qtdArvoresParaDivisao;
                    const s2 = parseFloat(ttProf.toFixed(2));
                    setTtProfundidade(s2);
                    let arrayProfundidade = [
                        { coluna: 'Profundidade baixo', valor: profundidadeBaixoV },
                        { coluna: 'Profundidade médio', valor: profundidadeMedioV },
                        { coluna: 'Profundidade alto', valor: profundidadeAltoV }
                    ]
                    const arr2 = divideCriterios(arrayProfundidade, qtdArvoresParaDivisao);
                    setArrayProfundidadeP(arr2);
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        ARRAY FERIMENTOS, O CALCULO DESSE CARA E DIFERENTE
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    arr.map(item => {
                        const obj = item.objArvore;
                        if (obj != null) {
                            if (obj.valorFerimentoDesviado != null && obj.valorFerimentoDesviado != '') {
                                const val0 = Number(obj.valorFerimentoDesviado) * Number(obj.ferimentoDesviado);
                                valorFerimentoDesviadoV = valorFerimentoDesviadoV + val0;
                            }
                            if (obj.valorFerimentoNaoDesviado != null && obj.valorFerimentoNaoDesviado != '') {
                                const val1 = Number(obj.ferimentoNaoDesviado) * Number(obj.valorFerimentoNaoDesviado);
                                valorFerimentoNaoDesviadoV = valorFerimentoNaoDesviadoV + val1;
                            }
                        }
                    })
                    let ttFer = valorFerimentoDesviadoV + valorFerimentoNaoDesviadoV;
                    ttFer = ttFer / qtdArvoresParaDivisao;
                    const s3 = parseFloat(ttFer.toFixed(2));
                    setTtFerimento(s3);
                    let arrayFerimento = [
                        { coluna: 'Ferimento desviado', valor: valorFerimentoDesviadoV },
                        { coluna: 'Ferimento nao desviado', valor: valorFerimentoNaoDesviadoV },
                    ]
                    const arr3 = divideCriterios(arrayFerimento, qtdArvoresParaDivisao);
                    setArrayFerimentoP(arr3);
                    /*
                    ╔══════════════════════════════════════════════════════════════════╗
                        ARRAY ASPECTOS
                    ╚══════════════════════════════════════════════════════════════════╝ */
                    arr.map(item => {
                        const obj = item.objArvore;
                        if (obj != null) {
                            if (obj.ataqueGeratrizes != null && obj.ataqueGeratrizes != '') {
                                ataqueGeratrizesV = ataqueGeratrizesV + Number(obj.ataqueGeratrizes);
                            }
                            if (obj.declividadeCorte != null && obj.declividadeCorte != '') {
                                declividadeCorteV = declividadeCorteV + Number(obj.declividadeCorte);
                            }
                            if (obj.conformidadeEquipamentos != null && obj.conformidadeEquipamentos != '') {
                                conformidadeEquipamentosV = conformidadeEquipamentosV + Number(obj.conformidadeEquipamentos);
                            }
                            if (obj.impurezasCoagulo != null && obj.impurezasCoagulo != '') {
                                impurezasCoaguloV = impurezasCoaguloV + Number(obj.impurezasCoagulo);
                            }
                            if (obj.facaNaoAmolada != null && obj.facaNaoAmolada != '') {
                                facaNaoAmoladaV = facaNaoAmoladaV + Number(obj.facaNaoAmolada);
                            }
                            if (obj.arvoreSangrada != null && obj.arvoreSangrada != '') {
                                arvoreSangradaV = arvoreSangradaV + Number(obj.arvoreSangrada);
                            }
                            if (obj.controleFitossanitario != null && obj.controleFitossanitario != '') {
                                controleFitossanitarioV = controleFitossanitarioV + Number(obj.controleFitossanitario);
                            }
                            if (obj.latexPerdaProducao != null && obj.latexPerdaProducao != '') {
                                latexPerdaProducaoV = latexPerdaProducaoV + Number(obj.latexPerdaProducao);
                            }
                        }
                    })
                    let ttAsp = ataqueGeratrizesV + declividadeCorteV + conformidadeEquipamentosV + impurezasCoaguloV + facaNaoAmoladaV + arvoreSangradaV + controleFitossanitarioV + latexPerdaProducaoV;
                    ttAsp = ttAsp / qtdArvoresParaDivisao;
                    const s4 = parseFloat(ttAsp.toFixed(2));
                    setTtAspecto(s4);
                    let arrayAspectos = [
                        { coluna: 'Ataque geratrizes', valor: ataqueGeratrizesV },
                        { coluna: 'Declividade corte', valor: declividadeCorteV },
                        { coluna: 'Conformidade equipamentos', valor: conformidadeEquipamentosV },
                        { coluna: 'Impurezas coagulo', valor: impurezasCoaguloV },
                        { coluna: 'Faca nao amolada', valor: facaNaoAmoladaV },
                        { coluna: 'Arvore sangrada', valor: arvoreSangradaV },
                        { coluna: 'Controle fitossanitário', valor: controleFitossanitarioV },
                        { coluna: 'Latex Perda Producao', valor: latexPerdaProducaoV },
                    ]
                    const arr4 = divideCriterios(arrayAspectos, qtdArvoresParaDivisao);
                    setArrayAspectoP(arr4);
                }
            }
        } catch (error) {
            console.log('Erro na classe RelatorioQualidadeSangriaEsporadico metodo cacularCriterios', error);
        }
    }

    const divideCriterios = (arr, div) => {
        let temp = [];
        try {
            temp = arr.map(item => {
                let xx = item.valor / div;
                xx = parseFloat(xx.toFixed(2));
                return {
                    coluna: item.coluna,
                    valor: xx
                }
            })
        } catch (error) {
            console.log('Erro na classe DetalhesSeringueiro metodo divideCriterios', error);
        }
        return temp;
    }

    const downloadPdf = async () => {

        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1000);
    }

    const gerarPDF = async () => {
        const input1 = pdfRef.current;

        let imgData1 = null;
        let imgHeigh1 = 0;
        let imgWWIDTH1 = 0;

        let imgData2 = null;
        let imgHeigh2 = 0;

        await html2canvas(input1).then((canvas) => {
            imgData1 = canvas.toDataURL('image/png');
            imgHeigh1 = canvas.height;
            imgWWIDTH1 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH1, pdfHeigh / imgHeigh1);

        pdf.addImage(imgData1, 'PNG', 5, 10, pdfWidth - 10, imgHeigh1 * ratio1);

        if (arrayFilesQualidade.length) {
            const input2 = pdfRefoto.current;

            await html2canvas(input2).then((canvas) => {
                imgData2 = canvas.toDataURL('image/png');
                imgHeigh2 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData2, 'PNG', 5, 10, pdfWidth - 10, imgHeigh2 * ratio1);
            });
        }
        pdf.save(`${nomeRelatorio}.pdf`);

        if (gerarCopiaPdfServidor) {
            const blob = pdf.output('blob');
            SALVAR_RELATORIO_PDF_SERVIDOR(blob, ActionTypes.ID_TIPO_ANEXO_ESPORADICO_PDF);
        }
    }

    function renderItens() {
        return (
            <Box>
                <Flex px={'20px'} mb={'5px'} justify='start' align='center'>
                    <Text mb={marginText} color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>{'Seringueiro: '}</Text>
                    <Text mb={marginText} ml={'10px'} color='orange.700' fontSize='17px' fontWeight='700' lineHeight='100%'>{nome}</Text>
                </Flex>
                <Wrap spacing='0px' justify='space-evenly' py={'5px'} mx={'10px'} mt={'0px'} bgGradient={GRADIENTE} borderRadius={'10px'}>
                    <WrapItem w={'23%'} alignItems={'center'}>
                        <Box w='100%' borderRightWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Text color={'orange.700'} fontSize='15px' fontWeight='700' align={'center'}>{letra}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='14px' fontWeight='700' align={'center'}>{'Tarefa'}</Text>
                        </Box>
                    </WrapItem>
                    <WrapItem w={'23%'} alignItems={'center'}>
                        <Box w='100%' borderRightWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Text color={'orange.700'} fontSize='15px' fontWeight='700' align={'center'}>{nomeQuantidadeTarefas}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='14px' fontWeight='700' align={'center'}>{'Frequência'}</Text>
                        </Box>
                    </WrapItem>
                    <WrapItem w={'23%'} alignItems={'center'}>
                        <Box w='100%' borderRightWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Text color={'orange.700'} fontSize='15px' fontWeight='700' align={'center'}>{nomeCasca}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='14px' fontWeight='700' align={'center'}>{'Casca'}</Text>
                        </Box>
                    </WrapItem>
                    <WrapItem w={'23%'} alignItems={'center'}>
                        <Box w='100%'>
                            <Text color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'} lineHeight={'100%'}>{nomeSentido}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Sentido'}</Text>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderCardsQualidade() {
        let mediaG = `${mediaGeralDePontos}`;
        mediaG = mediaG.length == 1 ? `${mediaG}.0` : mediaG;
        mediaG = mediaG.replace('.', ',');
        let notaG = `${notaGeral}`;
        notaG = notaG.length == 1 ? `${notaG}.0` : notaG;
        notaG = notaG.replace('.', ',');
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'} px={'3'}>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Total de pontos'}</StatLabel>
                            <StatNumber mb={marginText} color={textColor} fontSize={{ base: "xl", }}>{mediaG}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Nota dos seringueiro'}</StatLabel>
                            <StatNumber mb={marginText} color={corNotaGeral} fontSize={{ base: "xl", }}>{`${notaG}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Avaliação da sangria'}</StatLabel>
                            <StatNumber mb={marginText} color={corNotaGeral} fontSize={{ base: "xl", }}>{avaliacaoGeral}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderConsumo() {
        let consum = `${ttConsumo}`;
        consum = consum.length == 1 ? `${consum}.0` : consum;
        consum = consum.replace('.', ',');
        return (
            <Box px={'10px'}>
                <TableContainer borderRadius={'8px'} borderWidth={'1px'} borderColor={colorFooterAndHeader}>
                    <Table variant='striped' colorScheme="blackAlpha" size="sm">
                        <Thead px={'3px'} bg={colorFooterAndHeader} >
                            <Tr>
                                <Th w={'100%'} textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' align={'start'}>{'Consumo'}</Text>
                                </Th>
                                <Th w={'100%'} textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' align={'end'}>{consum}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {arrayConsumoP.map((item, index) => {
                                const xx = item.valor.toFixed(1);
                                const numComVirgula = xx.replace('.', ',');
                                return (
                                    <Tr key={index} >
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' align={'start'}>{item.coluna}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' align={'end'}>{numComVirgula}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderProfundidade() {
        let profun = `${ttProfundidade}`;
        profun = profun.length == 1 ? `${profun}.0` : profun;
        profun = profun.replace('.', ',');
        return (
            <Box px={'10px'} mt={'20px'}>
                <TableContainer borderRadius={'8px'} borderWidth={'1px'} borderColor={colorFooterAndHeader}>
                    <Table variant='striped' colorScheme="blackAlpha" size="sm">
                        <Thead px={'3px'} bg={colorFooterAndHeader}>
                            <Tr>
                                <Th w={'100%'} textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{'Profundidade'}</Text>
                                </Th>
                                <Th w={'100%'} textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' lineHeight={'100%'} align={'end'}>{profun}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {arrayProfundidadeP.map((item, index) => {
                                const xx = item.valor.toFixed(1);
                                const numComVirgula = xx.replace('.', ',');
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{item.coluna}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'end'}>{numComVirgula}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderFerimento() {
        let ferim = `${ttFerimento}`;
        ferim = ferim.length == 1 ? `${ferim}.0` : ferim;
        ferim = ferim.replace('.', ',');
        return (
            <Box px={'10px'} mt={'20px'}>
                <TableContainer borderRadius={'8px'} borderWidth={'1px'} borderColor={colorFooterAndHeader}>
                    <Table variant='striped' colorScheme="blackAlpha" size="sm">
                        <Thead px={'3px'} bg={colorFooterAndHeader} >
                            <Tr>
                                <Th w={'100%'} textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{'Ferimento'}</Text>
                                </Th>
                                <Th w={'100%'} textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' lineHeight={'100%'} align={'end'}>{ferim}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {arrayFerimentoP.map((item, index) => {
                                const xx = item.valor.toFixed(1);
                                const numComVirgula = xx.replace('.', ',');
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{item.coluna}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'end'}>{numComVirgula}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderAspect() {
        let aspct = `${ttAspecto}`;
        aspct = aspct.length == 1 ? `${aspct}.0` : aspct;
        aspct = aspct.replace('.', ',');
        return (
            <Box px={'10px'} mt={'20px'}>
                <TableContainer borderRadius={'8px'} borderWidth={'1px'} borderColor={colorFooterAndHeader}>
                    <Table variant='striped' colorScheme="blackAlpha" size="sm">
                        <Thead px={'3px'} bg={colorFooterAndHeader}>
                            <Tr>
                                <Th w={'100%'} textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{'Aspecto'}</Text>
                                </Th>
                                <Th w={'100%'} textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='15px' fontWeight='bold' lineHeight={'100%'} align={'end'}>{aspct}</Text>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {arrayAspectoP.map((item, index) => {
                                const xx = item.valor.toFixed(1);
                                const numComVirgula = xx.replace('.', ',');
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{item.coluna}</Text>
                                        </Td>
                                        <Td>
                                            <Text mb={marginText} color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'end'}>{numComVirgula}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function remderObservacao() {
        return (
            <Card direction='column' w='100%' px='10px' py='5px'>
                <Text mb={marginText} color={textColor} mt={'10px'} fontSize='20px' fontWeight='bold' align={'center'}>{'Pontos de Atenção'}</Text>
                <Box px={'10px'}>
                    <Text mb={marginText} color={textColor} fontSize='15px' lineHeight='100%' fontWeight='bold' ml={'10px'}>Observação</Text>
                    <Box p={'10px'} minH={'35px'} borderWidth='2px' borderRadius='8px' borderColor={'blackAlpha.800'} bg={'blackAlpha.200'}>
                        <Text mb={marginText} color={textColor} fontSize='15px' lineHeight='100%' fontWeight='bold' >{observacao}</Text>
                    </Box>
                </Box>
            </Card>
        )
    }

    function remderFotos() {
        return (
            <Card direction='column' w='100%' px='10px' py='5px'>
                <Box px={'10px'} mt={'15px'}>
                    <Text mb={marginText} color={textColor} fontSize='20px' lineHeight='100%' fontWeight='bold' ml={'10px'}>Fotos</Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                        {arrayFilesQualidade.length > 0 ?
                            arrayFilesQualidade.map((item, index) => {
                                return (
                                    <Box key={index} >
                                        <Card py='10px' align='center' justify={'start'}>
                                            <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                                <Stat h={'100%'} my='auto' ms={"0px"}>
                                                    <Flex align='center' justify={'center'}>
                                                        <Image boxSize='200px' objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                    </Flex>
                                                </Stat>
                                            </Flex>
                                        </Card>
                                    </Box>
                                )
                            })
                            :
                            <Box display='flex' alignItems='center' justifyContent={'center'} borderWidth='3px' borderRadius='20px' p={'3'}>
                                <Image boxSize='200px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/sistema_image_notfound.png`} alt='Dan Abramov' />
                            </Box>
                        }
                    </SimpleGrid>
                </Box>
            </Card>
        )
    }

    function renderButton() {
        return (
            <Flex px={'20px'} justify='space-between' align='center'>
                <Box>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                </Box>
                <Flex justify='center' align='center'>
                    <Center w='100%' h='50px' alignItems="center" justifyContent="end">
                        <Box mr={'10px'}>
                            <TooltipIcon
                                label={
                                    <Box px={'0px'} py={'5px'} >
                                        <Text align={'center'}>{'Salvar uma cópia no servidor'}</Text>
                                        <Text align={'start'} mt={'10px'} ml={'5px'} lineHeight='100%'>{'Ao gerar o relatório uma cópia será armazenada no servidor.'}</Text>
                                        <Text align={'start'} mt={'10px'} ml={'5px'} lineHeight='100%'>{'Para baixar acesse Relatórios e Relatórios Emitidos.'}</Text>
                                    </Box>
                                }
                                height={'20px'} />
                        </Box>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={gerarCopiaPdfServidor}
                            value={'MONDAY'}
                            onChange={() => setGerarCopiaPdfServidor(!gerarCopiaPdfServidor)}
                            borderWidth={'1px'}
                            borderColor={'blackAlpha.700'} />
                        <Box px={'5px'} mr={'15px'}>
                            <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Salvar uma</Text>
                            <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>cópia no servidor</Text>
                        </Box>
                    </Center>
                    <Box>
                        <Button variant="brand" onClick={downloadPdf}>Gerar PDF</Button>
                    </Box>
                </Flex>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' py='10px' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Box ref={pdfRef} w='100%' mt={'10px'}>
                    <Box px={'5'}>
                        <Box position={'relative'}>
                            <Flex flexDirection={'column'} justify='center' align='center' mb={'5px'}>
                                <Text color={textColor} fontSize='20px' fontWeight={'bold'} lineHeight={'100%'}>{`Relatório Esporádico`}</Text>
                                <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>{`Qualidade de Sangria ${anoCorrente}`}</Text>
                                <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesSelecionado}</Text>
                                <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                                <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                            </Flex>
                            <Box position={'absolute'} top={'1px'} left={'10px'}>
                                <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                            </Box>
                        </Box>
                    </Box>
                    {renderItens()}
                    {renderCardsQualidade()}
                    {renderConsumo()}
                    {renderProfundidade()}
                    {renderFerimento()}
                    {renderAspect()}
                </Box>
                <Box ref={pdfRefoto}>
                    {remderObservacao()}
                    {remderFotos()}
                </Box>
                {renderButton()}
                <LoaderBlack isOpen={loading} />
            </Card>
        </Box>
    );
};
