import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    Icon,
    Box,
    Center,
    Wrap,
    SimpleGrid,
    TableContainer,
    Table,
    Tbody,
    Tr,
    Td,
    Image,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import TooltipIcon from 'components/tooltip/TooltipIcon';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import ModalDialog from 'components/modal/ModalDialog';
import ModalDialogFinalizarSangria from 'components/modal/ModalDialogFinalizarSangria';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import { MdDeleteOutline, MdClose } from "react-icons/md";
import Qrcode from 'assets/img/layout/qrcode.png';
import LetraA from 'assets/img/layout/letra_A.png';
import Seringueiro from 'assets/img/layout/seringueiro.png';
import Percent from 'assets/img/layout/percent.png';

export default function CadastrarSafra() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [idUsuarioProdutor, setIdUsuarioProdutor] = useState(null);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [disabledDtSafra, setdisabledDtSafra] = useState(true);
    const [isValidDtInicioSafra, setIsValidDtInicioSafra] = useState(false);
    const [isValidDtFimSafra, setIsValidDtFimSafra] = useState(false);

    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [dtFimSangria, setDtFimSangria] = useState('');
    const [disabledComponent, setdisabledComponent] = useState(false);
    const [isValidDtInicioSangria, setIsValidDtInicioSangria] = useState(false);
    const [isValidDtFimSangria, setIsValidDtFimSangria] = useState(false);
    const [inpuNomeFazenda, setInpuNomeFazenda] = useState('');

    const [idUaP, setIdUaP] = useState(null);
    const [idSafraP, setIdSafraP] = useState(null);
    const [nomeSafraP, setnomeSafraP] = useState('');
    const [isValidNomeSafra, setIsValidNomeSafra] = useState(false);
    const [safraVigenteP, setSafraVigenteP] = useState(true);
    const [nomeProdutor, setNomeProdutor] = useState('');
    const [listaPessoaBasic, setListaPessoaBasic] = useState([]);
    const [listaPessoaDeletado, setListaPessoaDeletado] = useState([]);
    const [listaUsuarioGerenteBasic, setListaUsuarioGerenteBasic] = useState([]);
    const [listTarefaSafraItem, setListTarefaSafraItem] = useState([]);

    const [programacaoEmAndamento, setProgramacaoEmAndamento] = useState(false);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [idSafraPessoaParaExclusao, setIdSafraPessoaParaExclusao] = useState('');
    const [sangriaAtivaP, setSangriaAtivaP] = useState(false);
    const [mensagemValidacaoDatas, setMensagemValidacaoDatas] = useState('');
    const [qtdPessoaCadastrada, setQtdPessoaCadastrada] = useState(0);
    const [qtdGerentesCadastrado, setQtdGerentesCadastrado] = useState(0);
    const [insertOuUpdate, setInsertOuUpdate] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalDialogFinalizarSafra, setOpenModalDialogFinalizarSafra] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';

    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.5)', 'unset');
    const cardShadof = useColorModeValue('0px 2px 5px rgba(0, 0, 0, 0.5)', 'unset');
    const gradientDeletado = 'linear-gradient(red.800, red.600, red.400)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dados = localStorage.getItem('@HE-propriedadeParaEdicao');
            const param = JSON.parse(dados);

            const idUser = param.idUsuarioProdutor;
            const nomeProdutorP = param.nomeProdutor;
            const idUa = param.idUa;
            const insertOuUpdateP = param.insertOuUpdate;
            const nomeFaz = param.nomeFazenda;

            setIdUsuarioProdutor(idUser);
            setNomeProdutor(nomeProdutorP);
            setIdUaP(idUa);
            setInsertOuUpdate(insertOuUpdateP);
            setInpuNomeFazenda(nomeFaz);

            if (insertOuUpdateP == ActionTypes.OPERACAO_UPDATE) {
                setIdSafraP(param.idSafra);

                await carregarDadosDaSafra(idUa, param.idSafra);
                setTelaCarregada(true);
            } else {
                setdisabledComponent(false);
                setIdSafraP(null);
                setSafraVigenteP(true);
                setSangriaAtivaP(true);

                const dataInici = await retornaDataInicioSafra();
                const anoSeguinte = moment(dataInici).add(1, 'year').format('YYYY');
                const stringFim = `${anoSeguinte}-08-31`;
                const initSangria = moment().subtract(1, 'day').format('YYYY-MM-DD');

                setdisabledDtSafra(false);
                setDtInicioSafra(dataInici);
                setDtFimSafra(stringFim);
                setDtInicioSangria(initSangria);

                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const carregarDadosDaSafra = async (idUa, idSafra) => {
        try {
            const idTipoUsuarioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');

            const url = `safra/pesquisarSafra?idTipoUsuario=${idTipoUsuarioLogado}&idUa=${idUa}&idSafra=${idSafra}&deletado=false`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const objSafra = await response.respostaRequisicao.data;

                const nomeSafr = objSafra.nomeSafra;
                const vigenteX = objSafra.vigente != null ? objSafra.vigente : false;
                const dtInicioSafraX = objSafra.dtInicio !== null && objSafra.dtInicio !== '' ? moment(objSafra.dtInicio).format('YYYY-MM-DD') : '';
                const dtFimSafraX = objSafra.dtFim !== null && objSafra.dtFim !== '' ? moment(objSafra.dtFim).format('YYYY-MM-DD') : '';
                const dtInicioSangriaX = objSafra.dtInicioSangria !== null && objSafra.dtInicioSangria !== '' ? moment(objSafra.dtInicioSangria).format('YYYY-MM-DD') : '';
                const dtFimSangriaX = objSafra.dtFimSangria !== null && objSafra.dtFimSangria !== '' ? moment(objSafra.dtFimSangria).format('YYYY-MM-DD') : '';
                const qtdPessoa = objSafra.qtdPessoaCadastrada !== null ? objSafra.qtdPessoaCadastrada : 0;
                const qtdGerente = objSafra.qtdGerentesCadastrado !== null ? objSafra.qtdGerentesCadastrado : 0;
                const sangriaAtiv = objSafra.sangriaAtiva != null ? objSafra.sangriaAtiva : false;
                const programacaoEmAndamentoP = objSafra.programacaoEmAndamento != null && objSafra.programacaoEmAndamento != '' ? objSafra.programacaoEmAndamento : false;
                const listTarefaSafr = objSafra.listTarefaSafraItem;

                if (vigenteX == false || sangriaAtiv == false) {
                    setdisabledComponent(true);
                    setdisabledDtSafra(true);
                } else {
                    setdisabledComponent(false);
                    setdisabledDtSafra(programacaoEmAndamentoP ? true : false);
                }
                setnomeSafraP(nomeSafr);
                setSafraVigenteP(vigenteX);
                setDtInicioSafra(dtInicioSafraX);
                setDtFimSafra(dtFimSafraX);
                setDtInicioSangria(dtInicioSangriaX);
                setDtFimSangria(dtFimSangriaX);
                setIdSafraP(objSafra.idSafra);
                setSangriaAtivaP(sangriaAtiv);
                setQtdPessoaCadastrada(qtdPessoa);
                setQtdGerentesCadastrado(qtdGerente);
                setProgramacaoEmAndamento(programacaoEmAndamentoP);
                setListaPessoaBasic(objSafra.listaPessoaBasic);
                setListaPessoaDeletado(objSafra.listaPessoaDeletado);
                setListaUsuarioGerenteBasic(objSafra.listaUsuarioGerenteBasic);
                setListTarefaSafraItem(listTarefaSafr);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe CadastrarSafra metodo carregarDadosDaSafra', error);
        }
    }

    const retornaDataInicioSafra = async () => {
        const dataAtual = moment();

        const mesDia = '-09-01';
        const zzz = moment().format('YYYY');
        const anoInicio = Number(zzz);
        let stringInit = `${anoInicio}${mesDia}`;
        const dxx = moment(stringInit);

        if (dataAtual.isBefore(dxx)) {
            const zk = anoInicio - 1;
            stringInit = `${zk}${mesDia}`;
        }
        const dataInicio = moment(stringInit);
        const dtIni = moment(dataInicio).format('YYYY-MM-DD');

        return dtIni;
    }

    const salvarSafra = async () => {
        try {
            let valid = false;

            if (nomeSafraP.trim().length < 3) {
                setIsValidNomeSafra(true);
                valid = true;
            } else {
                setIsValidNomeSafra(false);
            }

            if (dtInicioSafra == '') {
                valid = true;
                setIsValidDtInicioSafra(true);
            } else {
                setIsValidDtInicioSafra(false);
            }
            if (dtFimSafra == '') {
                valid = true;
                setIsValidDtFimSafra(true);
            } else {
                setIsValidDtFimSafra(false);
            }

            if (dtInicioSangria == '') {
                valid = true;
                setIsValidDtInicioSangria(true);
            } else {
                setIsValidDtInicioSangria(false);
            }

            const validDatas = await validaDatasSafra();
            if (validDatas) {
                window.scrollTo(0, 0);
                return;
            }
            if (valid) return;

            const dtInicioP = moment(dtInicioSafra).format('YYYY-MM-DD');
            const dtFimP = moment(dtFimSafra).format('YYYY-MM-DD');
            const dtInicioSangriaP = dtInicioSangria != '' ? moment(dtInicioSangria).format('YYYY-MM-DD') : null;
            const dtFimSangriaP = dtFimSangria != '' ? moment(dtFimSangria).format('YYYY-MM-DD') : null;

            const jsonSagra = {
                idSafra: idSafraP,
                idUa: idUaP,
                nomeSafra: nomeSafraP,
                dtInicio: dtInicioP,
                dtFim: dtFimP,
                dtInicioSangria: dtInicioSangriaP,
                dtFimSangria: dtFimSangriaP,
                vigente: safraVigenteP,
                sangriaAtiva: sangriaAtivaP,
                programacaoEmAndamento: programacaoEmAndamento,
            }

            setLoading(true);
            if (insertOuUpdate == ActionTypes.OPERACAO_INSERT) {
                const response = await Api.postRequest(`safra/cadastrarSafra`, jsonSagra);
                setLoading(false);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    const idSafraRes = res.idSafra;
                    const idUaRes = res.idUa;

                    const jsonParam = {
                        idUa: idUaP,
                        idUsuarioProdutor: idUsuarioProdutor,
                        nomeProdutor: nomeProdutor,
                        idSafra: idSafraRes,
                        nomeFazenda: inpuNomeFazenda,
                        insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
                    }
                    localStorage.setItem('@HE-propriedadeParaEdicao', JSON.stringify(jsonParam));

                    setInsertOuUpdate(ActionTypes.OPERACAO_UPDATE);
                    await carregarDadosDaSafra(idUaRes, idSafraRes);

                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            } else {
                const response = await Api.putRequest(`safra/atualisarSafra`, jsonSagra);
                setLoading(false);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    await carregarDadosDaSafra(idUaP, idSafraP);

                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                } else {
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe CadastrarSafra metodo salvarPropriedades', error);
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const validaDatasSafra = async () => {
        let result = false;
        setMensagemValidacaoDatas('');

        const dtInicioP = moment(dtInicioSafra).format('YYYY-MM-DD');
        const dtFimP = moment(dtFimSafra).format('YYYY-MM-DD');
        const dtInicioSangriaP = dtInicioSangria != '' ? moment(dtInicioSangria).format('YYYY-MM-DD') : null;

        const dataAtual = moment();
        const zz = moment(dtInicioP).format('DD/MM/YYYY');

        const start = moment(dtInicioP);
        const end = moment(dtFimP);
        const inSagr = moment(dtInicioSangriaP);

        // 1 - VALIDACAO - MINIMO INICIO SAFRA E DATA ATUAL MENOS 18 MESES E DATA FIM SERA DATA INICIO MAIS 2 ANOS E MEIO
        // 1 - VALIDACAO - SE A DATA INICIO SAFRA E MAIOR QUE DATA ATUAL
        const val1 = moment.duration(start.diff(dataAtual));
        const s1 = val1.asDays();
        const res1 = parseFloat(s1.toFixed(0));

        if (res1 >= 0) {
            setMensagemValidacaoDatas(`Data início safra está maior que data atual ${zz}`);
            result = true;
        }

        // 2 - VALIDACAO - SE A DATA INICIO SAFRA E MAIOR QUE DATA FIM SAFRA, PRECISA TER NO MINIMO UM MES DE DIFERENCA
        const va2 = moment.duration(end.diff(start));
        const s2 = va2.asMonths();
        const res2 = parseFloat(s2.toFixed(0));

        if (res2 <= 1) {
            setMensagemValidacaoDatas(`Condições: Data início safra está menor que data fim safra, ou está com menos de um mês de difereça`);
            result = true;
        }

        // 3 - SE A DATA FIM SAFRA MENOR QUE DATA ATUAL 
        const va3 = moment.duration(end.diff(dataAtual));
        const s3 = va3.asDays();
        const res3 = parseFloat(s3.toFixed(0));
        if (res3 < 0) {
            setMensagemValidacaoDatas(`Data fim safra está menor que data atual`);
            result = true;
        }

        // 4 - SE A DATA INICIO SANGRIA MAIOR QUE INICIO SAFRA E MENOR QUE FIM SAFRA  -- after depois
        if (inSagr.isBefore(start) || inSagr.isAfter(end)) {
            setMensagemValidacaoDatas(`Data início sangria, deve está entre data início e fim da safra`);
            result = true;
        }

        if (dtFimSangria != '' && dtFimSangria != null) {
            const fimSang = moment(dtFimSangria);
            if (fimSang.isBefore(inSagr) || fimSang.isAfter(end)) {     // DEPOIS DO INICIO SANGRIA E ANTES DO FIM DA SAFRA
                setIsValidDtFimSangria(true);
                setMensagemValidacaoDatas('Data fim sangria, deve está entre data início sangria e fim da safra')
                result = true;
            }
        }
        return result;
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    const changeInicioSafra = (e) => {
        const val = e.target.value;
        setDtInicioSafra(val);
        setIsValidDtInicioSafra(val == '' ? true : false);
    }

    const changeFimSafra = (e) => {
        const val = e.target.value;
        setDtFimSafra(val);
        setIsValidDtFimSafra(val == '' ? true : false);
    }

    const changeInicioSangria = (e) => {
        const val = e.target.value;
        setDtInicioSangria(val);
        setIsValidDtInicioSangria(val == '' ? true : false);
    }

    const changeFimSangria = (e) => {
        const val = e.target.value;
        setDtFimSangria(val);
    }

    const geracaoDaProgramacao = async () => {
        const json = {
            idUsuario: idUsuarioProdutor,
            idSafra: idSafraP,
            idUa: idUaP,
            nomeFazenda: inpuNomeFazenda,
            dtInicio: dtInicioSafra,
            dtFim: dtFimSafra,
            dtInicioSangria: dtInicioSangria,
            dtFimSangria: dtFimSangria,
            nomeSafra: nomeSafraP,
            qtdGerentesCadastrado: qtdGerentesCadastrado,
            qtdPessoaCadastrada: qtdPessoaCadastrada,
            sangriaAtiva: sangriaAtivaP,
            vigente: safraVigenteP,
            programacaoEmAndamento: programacaoEmAndamento,
        }
        localStorage.setItem('@HE-datosParaProgramacaoIndividualAndReprogracao', JSON.stringify(json));
        history.push("/admin/gerar-programacao");
    }

    const novoSeringueiro = () => {
        const jsonPar = {
            idUa: idUaP,
            idUsuario: idUsuarioProdutor,
            idSafra: idSafraP,
            nomeFazenda: inpuNomeFazenda,
            insertOuUpdate: ActionTypes.OPERACAO_INSERT,
            listTarefaSafraItem: listTarefaSafraItem,
        }
        localStorage.setItem('@HE-datosParaEdicaoSeringueiro', JSON.stringify(jsonPar));
        history.push("/admin/associar-seringueiro-safra");
    }

    const editarSeringueiro = (item) => {
        const jsonParam = {
            idUa: idUaP,
            idUsuario: idUsuarioProdutor,
            idSafra: item.idSafra,
            nomeFazenda: inpuNomeFazenda,
            idPessoa: item.idPessoa,
            idSafraPessoa: item.idSafraPessoa,
            insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
            listTarefaSafraItem: listTarefaSafraItem,
        }
        localStorage.setItem('@HE-datosParaEdicaoSeringueiro', JSON.stringify(jsonParam));
        history.push("/admin/associar-seringueiro-safra");
    }

    const novoGerente = () => {
        const jsonParam = {
            idUa: idUaP,
            idSafra: idSafraP,
            insertOuUpdate: ActionTypes.OPERACAO_INSERT,
            idTipoUsuario: ActionTypes.TIPO_USUARIO_GERENTE,
        }
        localStorage.setItem('@HE-dadosProdutorParaEdicao', JSON.stringify(jsonParam));
        history.push("/admin/form-gerente");
    }

    const editarGerente = (item) => {
        const jsonParam = {
            idUa: idUaP,
            idSafra: item.idSafra,
            idUsuario: item.idUsuario,
            insertOuUpdate: ActionTypes.OPERACAO_UPDATE,
            idTipoUsuario: ActionTypes.TIPO_USUARIO_GERENTE,
        }
        localStorage.setItem('@HE-dadosProdutorParaEdicao', JSON.stringify(jsonParam));
        history.push("/admin/form-gerente");
    }

    const gerarQrCodeSeringueiro = () => {
        const jsonParam = {
            idUa: idUaP,
            nomeFazenda: inpuNomeFazenda,
            idSafra: idSafraP,
            nomeSafra: nomeSafraP,
            sangriaAtiva: sangriaAtivaP,
        }
        localStorage.setItem('@HE-dadosParaGeracaoQrCode', JSON.stringify(jsonParam));
        history.push("/admin/qr-code-generate");
    }

    const functionOpenModalDialog = async (item) => {
        setIdSafraPessoaParaExclusao(item.idSafraPessoa);
        setOpenModalDialog(true);
    }

    const deletarSeringueiro = async () => {
        setOpenModalDialog(false);
        setLoading(true);

        const jsonParam = {
            idSafraPessoa: idSafraPessoaParaExclusao,
        }

        const response = await Api.putRequest(`safraPessoa/ativarCampoDeletadoPessoa`, jsonParam);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await carregarDadosDaSafra(idUaP, idSafraP);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const finalizarSangria = async () => {
        setOpenModalDialogFinalizarSafra(false);

        setLoading(true);

        const jsonParam = {
            idUa: idUaP,
            idSafra: idSafraP,
        }

        const response = await Api.putRequest(`safra/finalizarSangria`, jsonParam);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await carregarDadosDaSafra(idUaP, idSafraP);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const acessarSazonalidade = () => {
        const json = {
            idUa: idUaP,
            nomeFazenda: inpuNomeFazenda,
            nomeSafra: nomeSafraP,
            idSafra: idSafraP,
        }
        localStorage.setItem('@HE-dadosParaSazonalidade', JSON.stringify(json));
        history.push("/admin/sazonalidade-propriedade");
    }

    const acessarTarefasSafra = () => {
        const json = {
            nomeFazenda: inpuNomeFazenda,
            nomeSafra: nomeSafraP,
            idSafra: idSafraP,
        }
        localStorage.setItem('@HE-idSafraParaTelaTarefasSafra', JSON.stringify(json));
        history.push("/admin/tarefas-safra");
    }

    function renderTitulo() {
        return (
            <Box>
                <Flex px={'10px'} justify='start' align='center'>
                    <Box w={'100px'}>
                        <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Produtor:</Text>
                    </Box>
                    <Box>
                        <Text color={'orange.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{nomeProdutor}</Text>
                    </Box>
                </Flex>
                <Flex px={'10px'} mt={'5px'} mb={'10px'} justify='start' align='center'>
                    <Box w={'100px'}>
                        <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Propriedade:</Text>
                    </Box>
                    <Box>
                        <Text color={'orange.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{`${inpuNomeFazenda}`}</Text>
                    </Box>
                </Flex>
            </Box>
        )
    }

    function renderSafra() {
        const largTr = 2;
        const dataAtual = moment().format('YYYY-MM-DD');
        const minInicioSangria = moment().subtract(30, 'days').format('YYYY-MM-DD');
        const mimInicioSafra = dtInicioSafra;
        const SHADOW_GREEN = '1px 1px #00b300';
        const SHADOW_RED = '1px 1px #ff0000';
        const SHADOW_YELLOW = '1px 1px #ffff00';
        return (
            <Box px='5px' mt={'20px'}>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Text opacity={disabledComponent ? '0.5' : '1'} color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Nome Safra *</Text>
                        <Input
                            value={nomeSafraP}
                            onChange={(e) => setnomeSafraP(e.target.value)}
                            isInvalid={isValidNomeSafra}
                            errorBorderColor='crimson'
                            variant="filled"
                            placeholder=""
                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            disabled={disabledComponent}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                        />
                    </Box>
                    <Box mt={{ base: 4, md: 0 }}>
                        {insertOuUpdate == ActionTypes.OPERACAO_UPDATE &&
                            <Box>
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'} align={'center'}>Status da Safra</Text>
                                <Flex px={'10px'} py={'5px'} justify='space-around' align='center' bg={'blackAlpha.100'} borderRadius={'10px'} >
                                    {safraVigenteP == true ?
                                        <Text textShadow={SHADOW_GREEN} color={'blackAlpha.600'} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%'>{'Vigente'}</Text>
                                        :
                                        <Text textShadow={SHADOW_RED} color={'blackAlpha.600'} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%'>{'Finalizada'}</Text>
                                    }
                                    {!programacaoEmAndamento ?
                                        <Flex flexDirection={'column'} px={'10px'} py={'5px'} justify='center' align='center'>
                                            <Text textShadow={SHADOW_YELLOW} color={'blackAlpha.600'} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%'>{'Sem Programação'}</Text>
                                        </Flex>
                                        :
                                        <Flex flexDirection={'column'} px={'10px'} py={'5px'} justify='center' align='center'>
                                            <Text textShadow={SHADOW_GREEN} color={'blackAlpha.600'} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%'>{'Com Programação'}</Text>
                                        </Flex>
                                    }
                                </Flex>
                            </Box>
                        }
                    </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mt='10px'>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Center ml={'5px'} mb={'2px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                <Text opacity={'0.5'} color={textColor} fontSize='13px' fontWeight={'bold'} mx={'10px'}>Início Safra *</Text>
                                <TooltipIcon
                                    label={
                                        <Box py={'5px'}>
                                            <Text align={'center'}>{'Início Safra'}</Text>
                                            <Text align={'start'} mt={'10px'} ml={'10px'} lineHeight={'100%'}>{'Esse campo é statico, defina o inicio da sua programação no campo início sangria'}</Text>
                                        </Box>
                                    }
                                    height={'18px'} />
                            </Center>
                            <Input
                                value={dtInicioSafra}
                                variant='filled'
                                onChange={changeInicioSafra}
                                isInvalid={isValidDtInicioSafra}
                                errorBorderColor='crimson'
                                placeholder=""
                                bg={inputBg}
                                color={inputText}
                                borderColor={textColor}
                                borderRadius="10px"
                                size="md"
                                type="date"
                                min={mimInicioSafra}
                                max={dataAtual}
                                //disabled={disabledDtSafra}
                                disabled={true}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text mb={'2px'} opacity={disabledDtSafra ? '0.5' : '1'} color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Fim Safra *</Text>
                            <Input
                                value={dtFimSafra}
                                variant='filled'
                                onChange={changeFimSafra}
                                isInvalid={isValidDtFimSafra}
                                errorBorderColor='crimson'
                                placeholder=""
                                bg={inputBg}
                                color={inputText}
                                borderColor={textColor}
                                borderRadius="10px"
                                size="md"
                                type="date"
                                min={dataAtual}
                                disabled={disabledDtSafra}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                            />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Center ml={'5px'} mb={'2px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                <Text opacity={disabledDtSafra ? '0.5' : '1'} fontSize='13px' fontWeight={'bold'} mx={'10px'}>Início Sangria *</Text>
                                <TooltipIcon
                                    label={
                                        <Box px={'0px'} py={'5px'} >
                                            <Text align={'center'}>{'Início Sangria'}</Text>
                                            <Text align={'start'} mt={'10px'} ml={'10px'} lineHeight={'100%'}>{'A partir dessa data você pode iniciar a programação'}</Text>
                                            <Text align={'start'} mt={'10px'} ml={'10px'} lineHeight={'100%'}>{'Lembrando que a primeira programação precisa ser feita pelo usuário na tela de programação, acessando no botão abaixo'}</Text>
                                        </Box>
                                    }
                                    height={'18px'} />
                            </Center>
                            <Input
                                value={dtInicioSangria}
                                variant='filled'
                                onChange={changeInicioSangria}
                                isInvalid={isValidDtInicioSangria}
                                errorBorderColor='crimson'
                                placeholder=""
                                bg={inputBg}
                                color={inputText}
                                borderColor={textColor}
                                borderRadius="10px"
                                size="md"
                                type="date"
                                min={minInicioSangria}
                                onKeyDown={(e) => { e.preventDefault() }}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                disabled={disabledDtSafra}
                            />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Center ml={'5px'} mb={'2px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                <Text opacity={!programacaoEmAndamento ? '0.5' : '1'} color={textColor} fontSize='13px' fontWeight={'bold'} mx={'10px'}>Fim Sangria</Text>
                                <TooltipIcon
                                    label={
                                        <Box px={'0px'} py={'5px'} >
                                            <Text align={'center'}>{'Fim Sangria'}</Text>
                                            <Text align={'start'} mt={'10px'} ml={'10px'} lineHeight={'100%'}>{'A programação será encerrada automaticamente nessa data, caso não exista, será encerrada na data fim da safra'}</Text>
                                            <Text align={'start'} mt={'10px'} ml={'10px'} lineHeight={'100%'}>{'Esse campo ficará editável depois que a programação for iniciada'}</Text>
                                        </Box>
                                    }
                                    height={'18px'} />
                            </Center>
                            <Input
                                value={dtFimSangria}
                                variant='filled'
                                onChange={changeFimSangria}
                                isInvalid={isValidDtFimSangria}
                                errorBorderColor='crimson'
                                placeholder=""
                                bg={inputBg}
                                color={inputText}
                                borderColor={textColor}
                                borderRadius="10px"
                                size="md"
                                type="date"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                onKeyDown={(e) => { e.preventDefault() }}
                                disabled={!programacaoEmAndamento}
                            />
                        </Box>
                    </SimpleGrid>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mt={'15px'}>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Flex w={'100%'} px={'15px'} py={'8px'} justify={'start'} align={'center'} borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'} bgGradient={gradientHeader}>
                            <Text color={"white"} fontSize='13px' fontWeight={'bold'} >{'Seringueiros'}</Text>
                            <Center ml={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                <TooltipIcon
                                    label={
                                        <Box px={'10px'} py={'5px'} >
                                            <Text align={'center'}>{'Seringueiros'}</Text>
                                            <Text align={'start'} mt={'10px'} lineHeight={'100%'} color={'lime'} fontWeight={'bold'}>{'Verde'}</Text>
                                            <Text align={'start'} mt={'5px'} ml={'15px'} lineHeight={'100%'} color={'white'}>{'Com programação.'}</Text>
                                            <Box py={'5px'} w={'100%'} borderBottomWidth={'1px'} borderColor={'gray.400'} />
                                            <Text align={'start'} mt={'10px'} lineHeight={'100%'} color={'gold'} fontWeight={'bold'}>{'Amarelo'}</Text>
                                            <Text align={'start'} my={'5px'} ml={'15px'} lineHeight={'100%'} color={'white'}>{'Sem programação.'}</Text>
                                        </Box>
                                    }
                                    height={'18px'} color={'white'} />
                            </Center>
                        </Flex>
                        <TableContainer w={'100%'} h={'300px'} overflowY={'auto'} borderBottomLeftRadius={'10px'} borderBottomRightRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                                <Tbody>
                                    {listaPessoaBasic.map((x, yy) => {
                                        const colorExisteProgramacao = safraVigenteP == true && x.isExisteProgramacao == false ? '#ffff66' : 'green.600';
                                        return (
                                            <Tr key={yy}>
                                                <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                                    <Flex justify={'start'} align={'center'}>
                                                        <Box w={'14px'} h={'14px'} borderRadius={'7px'} bg={colorExisteProgramacao} />
                                                        <Box px={'10px'} py={'3px'} cursor={'pointer'} borderRadius={'5px'} onClick={() => editarSeringueiro(x)} >
                                                            <Text color={textColor} fontSize={'13px'} fontWeight={'medium'} lineHeight={'100%'}>{x.nomePessoa}</Text>
                                                        </Box>
                                                    </Flex>
                                                </Td>
                                                <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                                    <Box>
                                                        <Button
                                                            isDisabled={disabledComponent}
                                                            w="30px"
                                                            h="30px"
                                                            variant="outline"
                                                            color={'black'}
                                                            bg="blackAlpha.200"
                                                            p={'0px'}
                                                            onClick={() => { functionOpenModalDialog(x) }}>
                                                            <Icon as={MdDeleteOutline} width='20px' height='20px' color='inherit' />
                                                        </Button>
                                                    </Box>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Flex justify='space-between' align='center' mt={'2px'}>
                            <Flex justify='center' align='center' mt={'2px'}>
                                <Button
                                    isDisabled={disabledComponent || idSafraP == null}
                                    w={'150px'}
                                    variant="miniBrand"
                                    onClick={() => { novoSeringueiro() }}>Novo Seringueiro</Button>
                                {idSafraP == null &&
                                    <Center ml={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                        <TooltipIcon
                                            label={
                                                <Box px={'0px'} py={'5px'} >
                                                    <Text align={'center'}>{'Cadastrar Seringueiro'}</Text>
                                                    <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'Essa opção será habilitada após o cadastro da safra'}</Text>
                                                </Box>
                                            }
                                            height={'18px'} />
                                    </Center>
                                }
                            </Flex>
                        </Flex>
                    </Box>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Flex w={'100%'} px={'15px'} py={'8px'} justify={'start'} align={'center'} borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'} bgGradient={gradientHeader}>
                            <Text color={"white"} fontSize='13px' fontWeight={'bold'} >{'Gerentes'}</Text>
                        </Flex>
                        <TableContainer w={'100%'} h={'300px'} overflowY={'auto'} borderBottomLeftRadius={'10px'} borderBottomRightRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                                <Tbody>
                                    {listaUsuarioGerenteBasic.map((x, yy) => {
                                        return (
                                            <Tr key={yy}>
                                                <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                                    <Box>
                                                        <Button variant='link' disabled={disabledComponent} onClick={() => editarGerente(x)}>
                                                            <Text color={'blue.800'} fontSize={'13px'} fontWeight={'medium'}>{x.nomeUsuario}</Text>
                                                        </Button>
                                                    </Box>
                                                </Td>
                                                <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'medium'} align={'center'}>{''}</Text>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Flex justify='end' align='center' mt={'2px'}>
                            <Button
                                isDisabled={disabledComponent || idSafraP == null}
                                w={'150px'}
                                variant="miniBrand"
                                onClick={() => { novoGerente() }}>Novo Gerente</Button>
                            {idSafraP == null &&
                                <Center ml={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Cadastrar Gerente'}</Text>
                                                <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'Essa opção será habilitada após o cadastro da safra'}</Text>
                                            </Box>
                                        }
                                        height={'18px'} />
                                </Center>
                            }
                        </Flex>
                    </Box>
                </SimpleGrid>
            </Box>
        )
    }

    function renderSeringueirosDeletados() {
        const largTr = 2;
        return (
            <Box px='5px' mt={'5px'} mb={'10px'}>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mt={'15px'}>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Accordion allowToggle >
                            <AccordionItem borderWidth={'0px'} border={'none'}>
                                {({ isExpanded }) => (
                                    <>
                                        <AccordionButton
                                            px={'10px'}
                                            h={'30px'}
                                            bgGradient={gradientDeletado}
                                            borderLeftWidth={'1px'}
                                            borderRightWidth={'1px'}
                                            borderTopWidth={'1px'}
                                            borderColor={'blackAlpha.700'}
                                            borderTopLeftRadius={'10px'}
                                            borderTopRightRadius={'10px'}
                                            _focus={{ boxShadow: 'none' }}
                                            _hover={{ boxShadow: "none", bg: "none", bgGradient: gradientDeletado }}>
                                            <AccordionIcon w={'25px'} h={'25px'} mr={'10px'} color={"white"} />
                                            <Flex w={'100%'} justify='space-between' align='center'>
                                                <Text color={"white"} fontSize='13px' fontWeight={'medium'} lineHeight='100%'>{'Seringueiros desativados'}</Text>
                                                <Text color={"white"} fontSize='13px' fontWeight={'medium'} lineHeight='100%'>{'Dt Desativado'}</Text>
                                            </Flex>
                                        </AccordionButton>
                                        <AccordionPanel px={'0px'} py={'0px'} pb={4}>
                                            <Box w={'100%'}>
                                                <TableContainer
                                                    w={'100%'}
                                                    maxH={'200px'}
                                                    overflowY={'auto'}
                                                    borderBottomLeftRadius={'10px'}
                                                    borderBottomRightRadius={'10px'}
                                                    borderBottomWidth={'1px'}
                                                    borderLeftWidth={'1px'}
                                                    borderRightWidth={'1px'}
                                                    borderColor={'blackAlpha.700'}>
                                                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                                                        <Tbody>
                                                            {listaPessoaDeletado.map((x, yy) => {
                                                                const dtDeletado = x.dtDeletado != null ? moment(x.dtDeletado).format('DD/MM/YY') : '';
                                                                return (
                                                                    <Tr key={yy}>
                                                                        <Td w={'80%'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                                                            <Box>
                                                                                <Text color={textColor} fontSize={'13px'} fontWeight={'medium'}>{x.nomePessoa}</Text>
                                                                            </Box>
                                                                        </Td>
                                                                        <Td w={'20%'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                                                            <Text color={textColor} fontSize={'13px'} fontWeight={'medium'} align={'center'}>{dtDeletado}</Text>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        </Accordion>
                    </Box>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} align={'center'}>{''}</Text>
                    </Box>
                </SimpleGrid>
            </Box>
        )
    }

    function renderAtividades() {
        return (
            <Box mt={'20px'} py={'20px'} bg={'white'} borderRadius={'20px'} boxShadow={cardShadow}>
                <SimpleGrid columns={{ base: 2, md: 4, xl: 4 }} gap='10px'>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadof}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { geracaoDaProgramacao() }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                align='center' >
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Programação Seringueiro'}</Text>
                                <Image h={'60px'} w={'60px'} src={Seringueiro} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadof}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { gerarQrCodeSeringueiro() }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Geração de'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'QR Code'}</Text>
                                <Image h={'60px'} w={'60px'} src={Qrcode} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadof}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { acessarTarefasSafra() }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Customizar'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'nome das tarefas'}</Text>
                                <Image h={'60px'} w={'60px'} src={LetraA} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadof}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { acessarSazonalidade() }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Editar'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Sazonalidade'}</Text>
                                <Image h={'60px'} w={'60px'} src={Percent} />
                            </Flex>
                        </Flex>
                    </Box>
                </SimpleGrid>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'50px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button disabled={disabledComponent} variant="brand" onClick={salvarSafra}>Salvar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px='0px' py='10px' bg={'transparent'}>
                {isVisibleAlert && renderAlert()}
                {renderTitulo()}
                <Box mt={'5px'}>
                    <Card direction='column' px='10px' py='10px' boxShadow={cardShadow} borderWidth={'2px'} borderColor={'blackAlpha.700'}>
                        {mensagemValidacaoDatas != '' &&
                            <Flex justify='center' align='center' >
                                <Flex px={'10px'} py={'5px'} justify='center' align='center' bg={'red'} borderColor={'red.700'} borderRadius={'10px'} borderWidth={'1px'}>
                                    <Icon as={MdClose} width='25px' height='25px' color='white' />
                                    <Text color={'white'} ml={'5px'} fontSize='15px' fontWeight='bold' align={'center'}>{mensagemValidacaoDatas}</Text>
                                </Flex>
                            </Flex>
                        }
                        {renderSafra()}
                        {listaPessoaDeletado.length > 0 && renderSeringueirosDeletados()}
                    </Card>
                </Box>
                {insertOuUpdate == ActionTypes.OPERACAO_UPDATE && renderAtividades()}
                {renderButton()}
                {openModalConfimacao && <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />}
                {openModalDialog && <ModalDialog isOpen={openModalDialog} titulo={'Desativar'} onPressOk={() => { deletarSeringueiro() }} onPressCancel={() => { setOpenModalDialog(false) }} />}
                {openModalDialogFinalizarSafra && <ModalDialogFinalizarSangria isOpen={openModalDialogFinalizarSafra} onPressOk={() => { finalizarSangria() }} onPressCancel={() => { setOpenModalDialogFinalizarSafra(false) }} />}
                {loading && <Loader isOpen={loading} />}
            </Card>
        </Box>
    );
}
