import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    TableContainer,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
    Wrap,
    WrapItem,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import { truncarAndFormatarNomeSeringueiro } from '../../../util/FuncoesUteis';

const IMG_LOGO = require('assets/img/layout/logo_21.png');

export default function QrCodeGenerate() {

    const svgRefs = useRef([]);
    // const svgRefsParaTodos = useRef([]);
    // const qrCodeRefs = useRef([]);

    const history = useHistory();
    let count = 0;

    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [idTipoUsuarioLogado, setIdTipoUsuarioLogado] = useState(null);
    const [listUa, setListUa] = useState([]);
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listaSafraCombo, setListaSafraCombo] = useState([]);

    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [dtFimSangria, setDtFimSangria] = useState('');
    const [nomeSafraP, setnomeSafraP] = useState('');
    const [safraVigenteP, setSafraVigenteP] = useState(null);
    const [listaPessoaBasic, setListaPessoaBasic] = useState([]);
    const [listaQRCParaTodos, setListaQRCParaTodos] = useState([]);
    const [sangriaAtivaP, setSangriaAtivaP] = useState(null);
    const [objetoQrCode, setObjetoQrCode] = useState(null);
    const [programacaoEmAndamento, setProgramacaoEmAndamento] = useState(false);
    const [nomeUaSelecionada, setNomeUaSelecionada] = useState('');



    const [dadosPessoaAndQrCode, setDadosPessoaAndQrCode] = useState([]);
    const [nomePessoa, setNomePessoa] = useState('');
    const [disabledPdf, setDisabledPdf] = useState(true);


    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const cardShadow = useColorModeValue('0px 0px 5px rgba(0, 0, 0, 0.5)', 'unset');
    const textColorHeader = useColorModeValue("white", "white");
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const shadowBlack = ('0px 2px 5px 2px rgba(0, 0, 0, 0.30)');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            //const idUaAtivaNoSistemaX = localStorage.getItem('@HE-idUa');
            //setIdUaAtivaNoSistema(idUaAtivaNoSistemaX);
            await pesquisarPropriedades();
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const pesquisarPropriedades = async () => {

        const param = localStorage.getItem('@HE-dadosParaGeracaoQrCode');
        const dadosProdutor = JSON.parse(param);

        const idUa = dadosProdutor.idUa;
        const nomeFazenda = dadosProdutor.nomeFazenda;
        const idSafra = dadosProdutor.idSafra;
        const nomeSafra = dadosProdutor.nomeSafra;
        const sangriaAtiva = dadosProdutor.sangriaAtiva;
        //const vigente = dadosProdutor.vigente;

        //setUaSelecionado(idUa);
        setSafraSelecionado(idSafra);
        setnomeSafraP(nomeSafra);
        setNomeUaSelecionada(nomeFazenda);
        setSangriaAtivaP(sangriaAtiva);

        setUaSelecionado('');
        const idTipoUserioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');
        setIdTipoUsuarioLogado(idTipoUserioLogado);
        await changeSafra(idTipoUserioLogado, idUa, idSafra);
    }

    const changeSafra = async (tipoUsuarioX, idUaX, idSafraX) => {
        try {
            //const idSafra = e;
            setObjetoQrCode(null);
            setSafraSelecionado(idSafraX);

            if (idSafraX != '') {
                //const url = `safra/retornaSafraAndSeringueirosAndGerentes?idTipoUsuario=${idTipoUsuarioLogado}&idUa=${uaSelecionado}&idSafra=${idSafra}&deletado=false`;
                const url = `safra/retornaSafraAndSeringueirosAndGerentes?idTipoUsuario=${tipoUsuarioX}&idUa=${idUaX}&idSafra=${idSafraX}&deletado=false`;

                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const objSafra = await response.respostaRequisicao.data;

                    const nomeSafr = objSafra.nomeSafra;
                    const vigenteX = objSafra.vigente != null ? objSafra.vigente : false;
                    const dtInicioSafraX = objSafra.dtInicio !== null && objSafra.dtInicio !== '' ? moment(objSafra.dtInicio).format('DD/MM/YYYY') : '';
                    const dtFimSafraX = objSafra.dtFim !== null && objSafra.dtFim !== '' ? moment(objSafra.dtFim).format('DD/MM/YYYY') : '';
                    const dtInicioSangriaX = objSafra.dtInicioSangria !== null && objSafra.dtInicioSangria !== '' ? moment(objSafra.dtInicioSangria).format('DD/MM/YYYY') : '';
                    const dtFimSangriaX = objSafra.dtFimSangria !== null && objSafra.dtFimSangria !== '' ? moment(objSafra.dtFimSangria).format('DD/MM/YYYY') : '';
                    const sangriaAtiv = objSafra.sangriaAtiva != null ? objSafra.sangriaAtiva : false;
                    const programacaoEmAndamentoX = objSafra.programacaoEmAndamento != null ? objSafra.programacaoEmAndamento : false;

                    setnomeSafraP(nomeSafr);
                    setSafraVigenteP(vigenteX);
                    setDtInicioSafra(dtInicioSafraX);
                    setDtFimSafra(dtFimSafraX);
                    setDtInicioSangria(dtInicioSangriaX);
                    setDtFimSangria(dtFimSangriaX);
                    setSangriaAtivaP(sangriaAtiv);
                    setListaPessoaBasic(objSafra.listaPessoaBasic);

                    setProgramacaoEmAndamento(programacaoEmAndamentoX);
                }
            } else {
                limpaCampos();
            }
        } catch (error) {
            console.log('Erro na classe QrCodeGenerate metodo changeSafra', error);
        }
    }

    const limpaCampos = async () => {
        setListaPessoaBasic([]);
        setListaSafraCombo([]);
        setListaQRCParaTodos([]);
        setObjetoQrCode(null);
        setUaSelecionado('');
        setNomeUaSelecionada('');
        setnomeSafraP('');
        setSafraVigenteP(null);
        setDtInicioSafra('');
        setDtFimSafra('');
        setDtInicioSangria('');
        setDtFimSangria('');
        setSangriaAtivaP(null);
        setSafraSelecionado('');
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const changeGerarQrCode = async (item) => {
        if (item != null) {
            setListaQRCParaTodos([]);
            setObjetoQrCode(item);
        } else {
            setObjetoQrCode(null);
        }
    }

    const downloadQRCode = async (nomePessoa = '', item, index) => {

        //const nomePessoaX = `${nomePessoa}`;
        //const letraTarefa = `Tarefa: ${item.letraTarefa}`;
        const letraCustomizada = `Tarefa: ${item.letraCustomizada}`;
        const nomeSafraX = `Safra: ${nomeSafraP}`;

        const nomePessoaX = nomePessoa != null && nomePessoa != '' ? nomePessoa : 'seringueiro';
        const nomeFormatado = await truncarAndFormatarNomeSeringueiro(nomePessoaX, 30);
        const ano = moment().format('YYYY');
        const mes = moment().format('MM');
        const nomeArquivo = `QrCode_${letraCustomizada}_${nomeFormatado}_${mes}_${ano}.pdf`;

        const svg = svgRefs.current[index].querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        const qrSize = 500; // tamanho do QR Code
        const margin = 60;
        const textPadding = 60;
        const fontSize = 20; // Tamanho da fonte 16px
        const totalHeight = qrSize + 2 * margin + textPadding + fontSize * 2;

        // Configura o tamanho do canvas (inclui espaco para o texto acima)
        canvas.width = qrSize + 2 * margin;
        canvas.height = totalHeight;

        // Desenha o fundo branco
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Adiciona o texto "nomeFazenda" e "nome" acima do QR Code
        ctx.fillStyle = '#000000';
        ctx.font = `${fontSize}px Arial`;
        ctx.textAlign = 'center';

        // Desenha o nome pessoa
        ctx.fillText(nomeSafraX, canvas.width / 2, margin);
        //ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize);

        // Desenha a letra datarefa
        ctx.fillText(nomePessoaX, canvas.width / 2, margin + fontSize + 5);
        ctx.fillText(letraCustomizada, canvas.width / 2, margin + fontSize * 2 + 10);

        img.onload = function () {
            const logoImg = new Image();
            logoImg.src = IMG_LOGO;

            const qrYPosition = canvas.height - qrSize - margin; // Move o QR Code para o final do canvas

            // Desenha o QR Code na parte inferior
            ctx.drawImage(img, margin, qrYPosition, qrSize, qrSize);

            logoImg.onload = function () {
                const logoSize = 100;
                const logoX = (canvas.width - logoSize) / 2;
                const logoY = qrYPosition + (qrSize - logoSize) / 2;

                // Desenha a logo no centro do QR Code
                ctx.drawImage(logoImg, logoX, logoY, logoSize, logoSize);

                // Realiza o download da imagem como PNG
                const pngFile = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.href = pngFile;
                downloadLink.download = `${nomeArquivo}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            };
        };
        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };

    function renderListaSafra() {
        const colorTextHeader = 'white';
        const espacoW = 2;
        const SHADOW_GREEN = '1px 1px #00b300';
        const SHADOW_RED = '1px 1px #ff0000';
        const SHADOW_YELLOW = '1px 1px #ffff00';

        let descStatus = '';
        let descProgramacao = '';
        let shadowColorProgramacao = '';
        let shadowStatus = '';

        if (sangriaAtivaP == true) {
            if (safraVigenteP == true) {
                descStatus = 'Vigente';
                shadowStatus = SHADOW_GREEN;
            } else {
                descStatus = 'Finalizado';
                shadowStatus = SHADOW_RED;
            }
            if (programacaoEmAndamento == true) {
                descProgramacao = 'Com Programação';
                shadowColorProgramacao = SHADOW_GREEN;
            } else {
                descProgramacao = 'Sem Programação';
                shadowColorProgramacao = SHADOW_YELLOW;
            }
        } else {
            descStatus = 'Finalizado';
            shadowStatus = SHADOW_RED;
            descProgramacao = 'Sem Programação';
            shadowColorProgramacao = SHADOW_YELLOW;
        }
        return (
            <Box mt={'10px'}>
                <Box px={'20px'}>
                    <Text color={'black'} fontSize='15px' fontWeight={'light'} lineHeight='100%' align={'start'}>{'Propriedade'}</Text>
                    <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'start'}>{nomeUaSelecionada}</Text>
                </Box>

                <Box borderWidth={'2px'} borderColor={'blackAlpha.600'} borderRadius={'20px'} boxShadow={shadowBlack} mt={'10px'}>
                    <Card px='10px' py='10px'>
                        <Flex justify={'center'} align={'center'}>
                            <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Safra</Text>
                        </Flex>
                        <Flex direction="column" align="center" mt={'10px'}>
                            <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                                <Table variant="striped" colorScheme='blackAlpha' size="sm" >
                                    <Thead>
                                        <Tr h={'35px'} bgGradient={gradientHeader}>
                                            <Th w={'16%'} pl={'20px'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='start' align='center' textTransform={'none'}>
                                                    <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{'Nome'}</Text>
                                                </Flex>
                                            </Th>
                                            <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center' textTransform={'none'}>
                                                    <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Safra'}</Text>
                                                </Flex>
                                            </Th>
                                            <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center' textTransform={'none'}>
                                                    <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Sangria'}</Text>
                                                </Flex>
                                            </Th>
                                            <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center' textTransform={'none'}>
                                                    <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Status'}</Text>
                                                </Flex>
                                            </Th>
                                            <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center' textTransform={'none'}>
                                                    <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Programação'}</Text>
                                                </Flex>
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {safraSelecionado != '' &&
                                            <Tr>
                                                <Td pl={'20px'} sx={{ paddingX: espacoW }}>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{nomeSafraP}</Text>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex flexDirection={'column'} justify='center' align='center'>
                                                        <Flex justify='start' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSafra}</Text>
                                                        </Flex>
                                                        <Flex mt={'5px'} justify='center' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSafra}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex flexDirection={'column'} justify='center' align='center'>
                                                        <Flex justify='start' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSangria}</Text>
                                                        </Flex>
                                                        <Flex mt={'5px'} justify='start' align='center'>
                                                            <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                            <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSangria}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex justify='center' align='center'>
                                                        <Text textShadow={shadowStatus} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descStatus}</Text>
                                                    </Flex>
                                                </Td>
                                                <Td sx={{ paddingX: espacoW }}>
                                                    <Flex justify='center' align='center'>
                                                        <Text textShadow={shadowColorProgramacao} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descProgramacao}</Text>
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Flex>
                    </Card>
                </Box>
            </Box>
        )
    }

    function renderTableSeringueiros() {
        const sizeF = '15px';
        let corLinha = false;
        return (
            <Box
                mt={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}>
                <Card px='10px' py='10px'>
                    <Flex justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Seringueiros</Text>
                    </Flex>
                    <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'} mt={'10px'}>
                        <Table size='sm' variant='unstyled'>
                            <Thead bg={colorFooterAndHeader}>
                                <Tr bgGradient={gradientHeader} h={'35px'}>
                                    <Th w={'80%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{'Nome'}</Text>
                                        </Flex>
                                    </Th>
                                    <Th w={'20%'}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{''}</Text>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {listaPessoaBasic.map((item, key) => {
                                    corLinha = !corLinha;
                                    const deletado = item.deletado == true ? true : false;
                                    let isDesabilita = true;
                                    let colorIndicator = 'transparent';
                                    if (deletado) {
                                        colorIndicator = 'red';
                                        isDesabilita = true;
                                    } else {
                                        colorIndicator = item.isExisteProgramacao == true ? 'green' : 'yellow';
                                        isDesabilita = item.isExisteProgramacao == true ? false : true;
                                    }
                                    return (
                                        <Tr key={key + 1} alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.5)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                            <Td>
                                                <Flex justify='start' align='center'>
                                                    <Box w={'14px'} h={'14px'} borderRadius={'6px'} bg={colorIndicator} />
                                                    <Text ml={'5px'} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{item.nomePessoa}</Text>
                                                </Flex>
                                            </Td>
                                            <Td justifyContent={'center'} alignItems={'center'}>
                                                <Flex justify='end' align='center'>
                                                    <Button isDisabled={isDesabilita} variant="action" onClick={() => { changeGerarQrCode(item) }}>Gerar QR Code</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        )
    }

    function renderQrCodes() {
        return (
            <Box>
                {objetoQrCode != null && (
                    <Box
                        mt={'20px'}
                        bg={'white'}
                        borderWidth={'2px'}
                        borderColor={'blackAlpha.600'}
                        borderRadius={'20px'}
                        boxShadow={shadowBlack}>
                        <Flex px='25px' my='20px' justify='center' align='center' >
                            <Text color={'black'} fontSize={'20px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{objetoQrCode.nomePessoa}</Text>
                        </Flex>
                        <Wrap spacing={'20px'} mx={'10px'} mb={'15px'} justify='start'>
                            {objetoQrCode.listaTarefa.map((item, index) => {
                                const jsonQr = {
                                    idPessoa: objetoQrCode.idPessoa,
                                    idSafra: objetoQrCode.idSafra,
                                    idUa: objetoQrCode.idUa,
                                    idTarefa: item.idTarefa,
                                    letraTarefa: item.letraTarefa,
                                }
                                return (
                                    <WrapItem key={index} ref={(el) => (svgRefs.current[index] = el)} >
                                        <Box bg={'blackAlpha.50'} borderRadius={'20px'} borderWidth={'1px'} borderColor={'blackAlpha.500'}>
                                            <Text mt={'10px'} color={'black'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'} fontFamily={'body'}>{item.letraCustomizada}</Text>
                                            <Box px={'10px'} py={'10px'}>
                                                <QRCodeSVG
                                                    value={JSON.stringify(jsonQr)}
                                                    size={128}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#000000"}
                                                    level={"L"}
                                                    marginSize={0}
                                                    imageSettings={{
                                                        src: IMG_LOGO,
                                                        height: 25,
                                                        width: 25,
                                                        opacity: 1,
                                                        excavate: true,
                                                    }}
                                                />
                                            </Box>
                                            <Flex px='5px' mb={'10px'} justify='center' align='center' >
                                                <Button variant="action" mt={'10px'} onClick={() => downloadQRCode(objetoQrCode.nomePessoa, item, index)}>Baixar</Button>
                                            </Flex>
                                        </Box>
                                    </WrapItem>
                                )
                            })}
                        </Wrap>
                    </Box>
                )}
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='start' mt={'20px'} align='center' >
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Box mt={'60px'}>
                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Carregando...'}</Text>
            </Box>
        )
    }

    return (
        <Card px='0px' py='0px' bg={'transparent'}>
            <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
                {isVisibleAlert && renderAlert()}
                <Box >
                    {renderListaSafra()}
                    {renderTableSeringueiros()}
                    {objetoQrCode != null && renderQrCodes()}
                </Box>
                {renderButton()}
            </Box>
            {loading && <Loader isOpen={loading} />}
        </Card>
    );
};
