import React from 'react';
import {
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Collapse,
} from "@chakra-ui/react";
import * as ActionTypes from '../../constants/ActionTypes';

function MessageSreen({ numeroStatus = '02', isVisibleAlert = false, closeMesage }) {

    let statusP = 'success';
    let titleP = '';
    let descriptionP = '';
    let bgColor = '#ffa500';

    if (numeroStatus == ActionTypes.SUCESSO_NA_REQUISICAO) {
        statusP = 'success';
        bgColor = '#33cc33';
        titleP = ActionTypes.MESSAGE_SUCESSO_NA_REQUISICAO;
        descriptionP = ActionTypes.MESSAGE_DETALHE_SUCESSO_NA_REQUISICAO;

    } else if (numeroStatus == ActionTypes.TIME_OUT_BACK_END) {
        statusP = 'error';
        bgColor = '#1a1a1a';
        titleP = ActionTypes.MESSAGE_TIME_OUT_BACK_END;
        descriptionP = ActionTypes.MESSAGE_DETALHE_TIME_OUT_BACK_END;

    } else if (numeroStatus == ActionTypes.PARAMETROS_DE_ENVIO_INCORRETOS) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = ActionTypes.MESSAGE_PARAMETROS_DE_ENVIO_INCORRETOS;
        descriptionP = ActionTypes.MESSAGE_DETALHE_PARAMETROS_DE_ENVIO_INCORRETOS;

    } else if (numeroStatus == ActionTypes.FALHA_NA_AUTENTICACAO) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'Usuário ou senha inválido';

    } else if (numeroStatus == ActionTypes.DATA_INICIO_MAIOR_QUE_DATA_FIM) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'Data início maior que data fim';

    } else if (numeroStatus == ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS';
        descriptionP = 'Verifique os campos obrigatórios.';

    } else if (numeroStatus == ActionTypes.CPF_JA_EXISTE) {
        statusP = 'warning';
        bgColor = '#ffa500';
        titleP = 'OPS';
        descriptionP = 'CPF já existe!';

    } else if (numeroStatus == ActionTypes.CELULAR_JA_EXISTE) {
        statusP = 'warning';
        bgColor = '#ffa500';
        titleP = 'OPS';
        descriptionP = 'Celular já existe!';

    } else if (numeroStatus == ActionTypes.NAO_EXISTE_PROGRAMACAO_PARA_ESSA_DATA) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'Data inválida';
        descriptionP = 'Não existe programação para essa data!';

    } else if (numeroStatus == ActionTypes.DATA_SELECIONADA_IGUAL_DATA_PROGRAMADA) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'Data inválida';
        descriptionP = 'A data selecinada é igual a data programada. Informe outra data ou selecione - Realizada no dia!';

    } else if (numeroStatus == ActionTypes.INFORME_DATA_POSTERIOR) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS';
        descriptionP = 'Para tarefas não realizadas, informe uma data de lançamento posterior a data programada';

    } else if (numeroStatus == ActionTypes.QUALIDADE_SANGRIA_FINALIZADA) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'Qualidade sangria finalizada';

    } else if (numeroStatus == ActionTypes.CAMPOS_OBRIGATORIOS_QUALIDADE_SANGRIA) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'Verifique os campos obrigatórios, e avalie pelo menos uma árvore';

    } else if (numeroStatus == ActionTypes.ARQUIVO_JA_EXISTE) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'O arquivo selecionado já existe.';

    } else if (numeroStatus == ActionTypes.ARQUIVO_MUITO_GRANDE) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'Arquivo maior que 5MB';

    } else if (numeroStatus == ActionTypes.FREQUENCIA_MAIOR_QUE_DIAS_UTEIS) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS!';
        descriptionP = 'O número da frequência está maior que a quantidade de dias úteis';

    } else if (numeroStatus == ActionTypes.ERRO_INESPERADO) {
        statusP = 'error';
        bgColor = '#cc0000';
        titleP = 'OPS';
        descriptionP = 'Ocorreu um erro inesperado';

    } else {
        statusP = 'warning';
        bgColor = '#ffa500';
        titleP = 'OPS';
        descriptionP = 'Algo inesperado ocorreu, estamos analisando.';
    }

    return (
        <Collapse in={isVisibleAlert} transition={{ exit: { delay: 0.1 }, enter: { duration: 0.5 } }}>
            <Alert
                status={statusP}
                variant='solid'
                borderRadius={'10px'}
                bg={bgColor}
                justifyContent={'start'}
                alignItems={'flex-start'}
            >
                <AlertIcon />
                <AlertTitle>
                    <Text color={'white'} fontSize='16px' fontWeight={'bold'}>{titleP}</Text>
                </AlertTitle>
                <AlertDescription maxWidth={'300px'} textAlign={'center'}> 
                    <Text color={'white'} fontSize='15px' fontWeight={'light'} >{descriptionP}</Text>
                </AlertDescription>

                <CloseButton
                    alignSelf='flex-start'
                    position='relative'
                    right={-1}
                    top={1}
                    onClick={closeMesage}
                    h={'20px'}
                />
            </Alert>
        </Collapse>
    );
}

export default MessageSreen;

// ESSES SAO OS TIPOS DE STATUS error, success, warning, info