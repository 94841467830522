import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    SimpleGrid,
    Select,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Card from "components/card/Card";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import * as Api from '../../../store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { mascaraTelefone } from '../../../util/Masck';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function RedefinirSenhaAdmin() {

    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [loading, setLoading] = useState(false);
    const [senha, setSenha] = useState('');
    const [isValidSenha, setIsValidSenha] = useState(false);
    const [mensageSenha, setMensageSenha] = useState('');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [listaProdutores, setListaProdutores] = useState([]);
    const [produtorSelecionado, setProdutorSelecionado] = useState('');
    const [listComboCompleto, setListComboCompleto] = useState([]);
    const [perfil, setPerfil] = useState('');
    const [celular, setcelular] = useState('');

    const textColor = useColorModeValue("navy.700", "white");
    const cardShadow = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loadScreen() {
            await listaUsuarios();
        }
        loadScreen();
    }, []);

    const listaUsuarios = async () => {
        try {
            const url = `api/usuario/retornaUsuariosRetirandoSeringueiros`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                setListComboCompleto(res);

                let arrayUser = [];
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];

                    const colorBg = item.idPermissao == ActionTypes.ID_PERMISSAO_SERINGUEIRO ? 'gray' : 'black';

                    const obj = {
                        value: `${item.idUsuario}`,
                        label: item.nome,
                        idPermissao: `${item.idPermissao}`,
                        colorBg: colorBg,
                    }
                    arrayUser.push(obj);
                }
                setListaProdutores(arrayUser);
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe PermissoesAndAcessosSistema metodo listaUsuarios', error);
        }
    }

    const redefinirSenhaApi = async () => {
        try {
            let valid = false;

            if (senha.length < 6) {
                setIsValidSenha(true);
                setMensageSenha('Mínimo 6 caracteres');
                valid = true;
            } else {
                setIsValidSenha(false);
                setMensageSenha('');
            }

            if (valid) return;

            const jsonRedefinirSenha = {
                idUsuario: produtorSelecionado,
                novaSenha: senha,
            }

            setLoading(true);
            const response = await Api.putRequestSemAccessToken(`api/usuario/redefinirSenha`, jsonRedefinirSenha);
            setLoading(false);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setOpenModalConfimacao(true);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe RedefinirSenhaAdmin metodo redefinirSenhaApi', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const handleSenha = (e) => {
        const pass = e.target.value
        setSenha(pass.trim());
    }

    const changeUsuario = (val) => {
        setProdutorSelecionado(val);
        setSenha('');

        if(val != ''){
            const userFilter = listComboCompleto.filter(x => x.idUsuario == val);
            if (userFilter.length > 0) {
                const obj = userFilter[0];
    
                const telComMascara = mascaraTelefone(obj.numeroCelular);
                setcelular(telComMascara);
                setPerfil(obj.descPermissao);
            }
        }
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} >
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderUsuarioAndSenha() {
        const disabledInput = produtorSelecionado != '' ? false : true;
        return (
            <Box px={'5px'} mt={'10px'} py={'20px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.700'} boxShadow={cardShadow}>
                <Box>
                    <Flex flexDirection={'column'} px='10px' justify='center' align='center'>
                        <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%'>{'Redefinição de senha'}</Text>
                        <Text color={textColor} fontSize='15px' fontWeight='light' lineHeight='100%' py={'5px'} >{'Os seringueiros não estão incluidos na lista de usuários.'}</Text>
                    </Flex>
                </Box>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap='20px' mt={'20px'}>
                    <Flex flexDirection={'column'} justify={'start'} align={'center'}>
                        <Box>
                            <Text align={'start'} color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Usuários</Text>
                            <Select
                                value={produtorSelecionado}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'300px'}
                                onChange={(e) => changeUsuario(e.target.value)}>
                                {listaProdutores.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        <Box minH={'40px'}>
                            {!disabledInput &&
                                <Flex mt={'20px'} justify={'start'} align={'center'}>
                                    <Flex px={'10px'} justify='start' align='start'>
                                        <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'start'}>Celular:</Text>
                                        <Text color={'orange.600'} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'start'} ml={'10px'} >{celular}</Text>
                                    </Flex>
                                    <Flex px={'10px'} mt={'5px'} justify='start' align='center'>
                                        <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'start'}>Perfil:</Text>
                                        <Text color={'orange.600'} fontSize='15px' fontWeight='bold' lineHeight='100%' align={'start'} ml={'10px'}>{perfil}</Text>
                                    </Flex>
                                </Flex>
                            }
                        </Box>
                    </Flex>
                    <Flex justify={'center'} align={'start'}>
                        <Box>
                            <Text align={'start'} color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'} opacity={disabledInput ? '0.5' : '1'}>Nova Senha</Text>
                            <InputGroup size='md'>
                                <Input
                                    onChange={handleSenha}
                                    value={senha}
                                    isInvalid={isValidSenha}
                                    errorBorderColor='crimson'
                                    placeholder='Min. 6 characteres'
                                    mb='5px'
                                    size='lg'
                                    type={show ? "text" : "password"}
                                    variant='filled'
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    w={'300px'}
                                    borderColor={textColor}
                                    borderRadius={'10px'}
                                    disabled={disabledInput}
                                />
                                <InputRightElement display='flex' alignItems='center'>
                                    <Icon
                                        color={textColor}
                                        _hover={{ cursor: "pointer" }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            {isValidSenha && <Text pl={'10px'} color={'red.400'} fontSize='14px' fontWeight='700' lineHeight='100%'>{mensageSenha}</Text>}
                        </Box>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Flex px={'20px'} py={'20px'} justify={'space-between'} align={'center'}>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button isDisabled={produtorSelecionado != '' ? false : true} variant="brand" onClick={() => { redefinirSenhaApi() }}>Salvar</Button>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                {renderAlert()}
                {renderUsuarioAndSenha()}
                {renderButton()}
                <Loader isOpen={loading} />
                <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { setOpenModalConfimacao(false) }} descricao={'Operação realizada com sucesso!'} />
            </Card>
        </Box>
    );
}


