import React from "react";
import { Tooltip, Text, Box, Flex } from "@chakra-ui/react";
import { WarningTwoIcon } from '@chakra-ui/icons';

const TooltipObservacao = ({ arrayLabel = [], height = '18px', color = 'orange.600' }) => {

    return (
        <Box w={'100%'}>
            {arrayLabel.length > 0 ?
                <Flex justify={'space-around'} align={'center'}>
                    {arrayLabel.map((item, index) => {
                        return (
                            <Box key={index}>
                                <Tooltip
                                    label={
                                        <Box px={'5px'} py={'5px'} >
                                            <Text align={'center'}>{'Observação'}</Text>
                                            <Text align={'start'} mt={'8px'} mb={'5px'} lineHeight='100%'>{item.observacao}</Text>
                                        </Box>
                                    }
                                    borderRadius={'10px'}
                                    size="sm"
                                    bg={'black'}>
                                    <WarningTwoIcon w={height} h={height} color={color} />
                                </Tooltip>
                            </Box>
                        )
                    })}
                </Flex>
                :
                <Text align={'center'} lineHeight={'100%'}>{''}</Text>
            }
        </Box>
    );
};

export default TooltipObservacao;
