import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Button,
    Wrap,
    WrapItem,
    Radio,
    RadioGroup,
    Select,
    Input,
    SimpleGrid,
    Textarea,
    Grid,
    GridItem,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import MessageSreen from 'components/message/MessageSreen';
import Loader from 'components/loading/Loader';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import moment from 'moment';
import { MOTIVO_TAREFA_NAO_RELIZADA } from 'model/MockData';
import { retornaNomeSemanaAndMes, RETORNA_NOME_SEMANA } from '../../../util/Masck';
import Calendario from 'assets/img/layout/calendario.png';

export default function LancarTarefa() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);

    const [dtProgramadoP, setDtProgramadoP] = useState('');
    const [maximumDate, setMaximumDate] = useState('');
    const [minimumDate, setMinimumDate] = useState('');
    const [dataRealizadoP, setDataRealizadoP] = useState('');
    const [descricaoData, setDescricaoData] = useState('');
    const [diaSemana, setDiaSemana] = useState('');

    const [idControleSangriaP, setIdControleSangriaP] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dsStatusP, setDsStatusP] = useState('');
    const [corStatus, setCorStatus] = useState('gray');
    const [letraTarefaP, setLetraTareP] = useState('');
    const [letraCustomizada, setLetraCustomizada] = useState('');
    const [idPessoaP, setIdPessoaP] = useState(0);
    const [idUaP, setIdUaP] = useState(0);

    const [valueRadioRealizadaNoDia, setValueRadioRealizadaNoDia] = useState('1');
    const [valueRadioForaDoPrazo, setValueRadioForaDoPrazo] = useState('3');
    const [showInserirData, setShowInserirData] = useState(false);
    const [showRadioForaDoPrazo, setShowRadioForaDoPrazo] = useState(false);
    const [showMotivo, setShowMotivo] = useState(false);
    const [motivoSelecionado, setMotivoSelecionado] = useState('');
    const [isValidMotivo, setIsValidMotivo] = useState(false);
    const [isValidData, setIsValidData] = useState(false);
    const [listaDiasUteisSeringueiroP, setListaDiasUteisSeringueiroP] = useState([]);

    const [diaSemanaRealizadaAtual, setDiaSemanaRealizadaAtual] = useState('');
    const [descDataRealizadaAtual, setDescDataRealizadaAtual] = useState('');
    const [descMotivoAtual, setDescMotivoAtual] = useState('');
    const [idStatusTarefaAtual, setIdStatusTarefaAtual] = useState('');
    const [pontoVermelhoAtual, setPontoVermelhoAtual] = useState(false);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [observacao, setObservacao] = useState('');
    const [maxCharacterObservacao, setMaxCharacterObservacao] = useState(1000);
    const [idTarefa, setidTarefa] = useState(null);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const shadowBlack = ('0px 2px 5px 3px rgba(0, 0, 0, 0.30)');
    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const res2 = localStorage.getItem('@HE-dadosParaTelaLancarTarefa');
            if (res2 != null) {
                const obj2 = JSON.parse(res2);

                const idUa = localStorage.getItem('@HE-idUa');
                const idStatusTarefaAt = obj2.idStatusTarefa != null && obj2.idStatusTarefa != '' ? obj2.idStatusTarefa : '';
                const dt = obj2.dtRealizado != null && obj2.dtRealizado != '' ? moment(obj2.dtRealizado).format('DD/MM/YYYY') : '--';
                const diaSemanaReali = obj2.diaSemanaRealizado != null && obj2.diaSemanaRealizado != '' ? obj2.diaSemanaRealizado : '--';
                const descMotivoAt = obj2.descMotivo != null && obj2.descMotivo != '' ? obj2.descMotivo : '--';
                const pontoVermelhoAt = obj2.pontoVermelho != null ? obj2.pontoVermelho : false;
                const observacaoP = obj2.observacao != null ? obj2.observacao : '';
                const idTarefaP = obj2.idTarefa != null ? obj2.idTarefa : '';

                setIdStatusTarefaAtual(idStatusTarefaAt);
                setDiaSemanaRealizadaAtual(diaSemanaReali);
                setDescDataRealizadaAtual(dt);
                setDescMotivoAtual(descMotivoAt);
                setPontoVermelhoAtual(pontoVermelhoAt);
                setListaDiasUteisSeringueiroP(obj2.listaDiasUteisSeringueiro);
                setIdPessoaP(obj2.idPessoa);
                setIdUaP(idUa);
                setLetraTareP(obj2.letraTarefa);
                setLetraCustomizada(obj2.letraCustomizada);
                setIdControleSangriaP(obj2.idControleSangria);
                setDsStatusP(obj2.dsStatus);
                setDtProgramadoP(obj2.dtProgramado);
                setObservacao(observacaoP);
                setidTarefa(idTarefaP);

                calcObservacao(observacaoP);

                const diaSemanaX = await RETORNA_NOME_SEMANA(obj2.dtProgramado);
                const dtProg = moment(obj2.dtProgramado).format('DD/MM/YYYY')

                setDiaSemana(diaSemanaX);
                setDescricaoData(dtProg);
                const maxDate = moment().format('YYYY-MM-DD');
                setMaximumDate(maxDate);
                const mimDate = moment(obj2.dtProgramado).format('YYYY-MM-DD');
                setMinimumDate(mimDate);
                setDataRealizadoP(mimDate);

                let corStatus = 'orange';
                if (obj2.idStatusTarefa == 1) {           // realizada
                    corStatus = 'green';
                } else if (obj2.idStatusTarefa == 2) {     // pendente
                    corStatus = 'orange';
                } else if (obj2.idStatusTarefa == 3) {     // atrazada            
                    corStatus = 'red';
                } else if (obj2.idStatusTarefa == 4) {     // nao realizada
                    corStatus = 'red';
                } else {
                    corStatus = 'orange';
                }
                setCorStatus(corStatus);
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, []);

    const changeRadioButton = async (val) => {
        setValueRadioRealizadaNoDia(val);
        if (val == '2') {
            setValueRadioForaDoPrazo('3');
            setShowRadioForaDoPrazo(true);
            setShowInserirData(true);
            setShowMotivo(false);
        } else {
            setShowRadioForaDoPrazo(false);
            setShowInserirData(false);
            setShowMotivo(false);
        }
    }

    const changeRadioForaDoPrazo = async (val) => {
        setValueRadioForaDoPrazo(val);
        if (val == '3') {
            setShowInserirData(true);
            setShowMotivo(false);
        } else {
            setShowInserirData(false);
            setShowMotivo(true);
        }
    }

    const changeComboMotivo = async (val2) => {
        const val = val2;
        setMotivoSelecionado(val);
        if (val != '') {
            setIsValidMotivo(false);
        }
    }

    const onChangeRealizado = (val) => {
        setDataRealizadoP(val);
        setIsValidData(false);
    }

    const salvarTarefa = async () => {
        try {
            let descDataP = '';
            let dtRealiza = null;
            let idMotivoP = null;
            let tarefaNaoRealizadaP = false;
            let idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_REALIZADA;
            let dsStatusP = 'Realizada';
            let valueRadioRealizadaNoDiaP = true;

            const valid = await validarSeTarefaEstaComStatusDeNaoRealizada();
            if (!valid) return;

            const val1 = moment(dtProgramadoP).format('YYYY-MM-DD');
            const val2 = moment(dataRealizadoP).format('YYYY-MM-DD');
            const datasIguais = val1 == val2 ? true : false;

            if (valueRadioRealizadaNoDia == '1') {
                dtRealiza = null;
                idMotivoP = null;
                tarefaNaoRealizadaP = false;
                idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_REALIZADA;
                dsStatusP = 'Realizada';
                valueRadioRealizadaNoDiaP = true;
                descDataP = await retornaNomeSemanaAndMes(dtProgramadoP);
            } else {
                if (valueRadioForaDoPrazo == '3') {
                    const isRes = await validarDiasUteisSeringueiro();
                    dtRealiza = moment(dataRealizadoP).format('YYYY-MM-DD HH:mm:ss');
                    descDataP = await retornaNomeSemanaAndMes(dataRealizadoP);
                    if (!isRes) {
                        setNumStatusResposta(ActionTypes.NAO_EXISTE_PROGRAMACAO_PARA_ESSA_DATA);
                        showAlert();
                        setIsValidData(true);
                        return;
                    } else if (datasIguais == true) {
                        setNumStatusResposta(ActionTypes.DATA_SELECIONADA_IGUAL_DATA_PROGRAMADA);
                        showAlert();
                        setIsValidData(true);
                        return;
                    } else {
                        setIsValidData(false);
                        valueRadioRealizadaNoDiaP = false;
                    }
                } else {
                    if (motivoSelecionado == '') {
                        setNumStatusResposta(ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS);
                        showAlert();
                        setIsValidMotivo(true);
                        return;
                    } else {
                        setIsValidMotivo(false);
                        dtRealiza = null;
                        idMotivoP = motivoSelecionado;
                        tarefaNaoRealizadaP = true;
                        idStatusTarefaP = ActionTypes.ID_STATUS_TAREFA_NAO_REALIZADA;
                        dsStatusP = 'Não Realizada';
                        valueRadioRealizadaNoDiaP = false;
                        descDataP = '';
                    }
                }
            }
            const dtInicioSafraP = localStorage.getItem('@HE-dtInicioSafraAsync');
            const idSafraP = localStorage.getItem('@HE-idSafra');
            const idUserLogado = localStorage.getItem('@HE-idUsuarioLogado');
            const dtProgram = moment(dtProgramadoP).format('YYYY-MM-DD HH:mm:ss');
            const observacaoSemEspacosLaterais = observacao.trim();

            const arrayTarefa = [{
                idControleSangria: idControleSangriaP,
                idUsuario: idUserLogado,
                idPessoa: idPessoaP,
                idUa: idUaP,
                idSafra: idSafraP,
                dtProgramado: dtProgram,
                dtRealizado: dtRealiza,  // SE A TAREFA REALIZADA NO DIA EU PASSO A DATA DA PROGRAMACAO NO BACK
                idMotivo: idMotivoP,
                idStatusTarefa: idStatusTarefaP,
                tarefaFoiRealizadaNoDia: valueRadioRealizadaNoDiaP,
                tarefaNaoRealizada: tarefaNaoRealizadaP,
                dtInicioSafra: dtInicioSafraP,
                descData: descDataP,
                dsStatus: dsStatusP,
                observacao: observacaoSemEspacosLaterais,
                idTarefa: idTarefa,
            }]

            setLoading(true);
            const response = await Api.postRequestSemAccessToken(`api/lancarTarefa/realizarLancamentoVariasTarefas`, arrayTarefa);
            setLoading(false);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {

                history.goBack();
                //setOpenModalConfimacao(true);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Error classe LancarTarefa metodo salvarTarefa', error);
        }
    }

    const validarSeTarefaEstaComStatusDeNaoRealizada = async () => {
        let result = true;

        if (idStatusTarefaAtual == 4) {
            if (valueRadioRealizadaNoDia == 2 && valueRadioForaDoPrazo == 3) {
                result = true;
            } else {
                setNumStatusResposta(ActionTypes.INFORME_DATA_POSTERIOR);
                showAlert();
                result = false;
                return;
            }
        }
        return result;
    }

    const changeObservacao = (val) => {
        setObservacao(val);
        calcObservacao(val);
    }

    const limparObservacao = () => {
        setObservacao('');
        setMaxCharacterObservacao(1000);
    }

    const calcObservacao = (val) => {
        const xx = val.length;
        const num = 1000 - xx;
        setMaxCharacterObservacao(num);
    }

    const validarDiasUteisSeringueiro = async () => {
        let result = true;
        try {
            const dtRealiza = moment(dataRealizadoP).format('YYYY-MM-DD HH:mm:ss');
            const nomeDiaSemana = moment(dtRealiza).format('dddd').toUpperCase();
            await new Promise(resolve => {
                resolve(
                    listaDiasUteisSeringueiroP.map((item, index) => {
                        if (nomeDiaSemana == item) {
                            result = true;
                        }
                    })
                )
            });
        } catch (error) {
            setLoading(false);
            console.log('Error classe LancarTarefa metodo validarDiasUteisSeringueiro', error);
        }
        return result;
    }

    const showAlert = () => {
        window.scrollTo(0, 0);

        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 15000);
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    function renderTarefa222() {
        const ftSizeP = '15px';
        return (
            <Box mt={'20px'} py={'15px'} px={'25px'} boxShadow={shadowBlack} borderRadius={'15px'}>
                <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px'>
                    <Flex flexDirection={'column'} justify={'start'} align={'center'}>
                        <Text color={textColor} fontSize={ftSizeP} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{'Programada para:'}</Text>
                        <Flex mt={'5px'} justify={'start'} align={'center'}>
                            <Text color={'green'} fontSize={'12px'} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{`${diaSemana}`}</Text>
                            <Text color={'green'} fontSize={'12px'} fontWeight={'bold'} align={'start'} lineHeight={'100%'} ml={'10px'}>{descricaoData}</Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={'column'} justify={'start'} align={'center'}>
                        {idStatusTarefaAtual == 4 ?
                            <Flex px={'20px'} flexDirection={'column'} justify={'start'} align={'center'}>
                                <Text color={textColor} fontSize={ftSizeP} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{'Não realizada, motivo:'}</Text>
                                <Text color={'red'} fontSize={ftSizeP} fontWeight={'bold'} align={'start'} lineHeight={'100%'} mt={'5px'} >{`${descMotivoAtual} `}</Text>
                            </Flex>
                            :
                            <Flex px={'20px'} flexDirection={'column'} justify={'start'} align={'center'}>
                                <Text color={textColor} fontSize={ftSizeP} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{'Realizada em:'}</Text>
                                <Flex ml={'0px'} mt={'5px'} justify={'start'} align={'center'}>
                                    <Text color={'green'} fontSize={'12px'} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{`${diaSemanaRealizadaAtual}`}</Text>
                                    <Text color={'green'} fontSize={'12px'} fontWeight={'bold'} align={'start'} lineHeight={'100%'} ml={'10px'}>{descDataRealizadaAtual}</Text>
                                </Flex>
                            </Flex>
                        }
                    </Flex>
                    <Flex flexDirection={'column'} justify={'start'} align={'center'}>
                        <Text pr={'20px'} color={textColor} fontSize={'40px'} fontWeight={'bold'} align={'end'} lineHeight={'100%'}>{letraCustomizada}</Text>
                        <Flex mt={'10px'} justify={'end'} align={'center'}>
                            <Text color={corStatus} fontSize={ftSizeP} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{`Tarefa ${dsStatusP}`}</Text>
                            {pontoVermelhoAtual && <Text ml={'10px'} color={'red'} fontSize={ftSizeP} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{'fora do prazo'}</Text>}
                        </Flex>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderTarefa() {
        const ftSizeP = '15px';
        const heightP = '90px';
        const textPontoVermelho = pontoVermelhoAtual ? 'fora do prazo' : 'Realizada no dia Programado';
        const corPonto = pontoVermelhoAtual ? 'red' : 'green';
        return (
            <Box mt={'20px'} py={'15px'} px={'25px'}>
                <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px'>
                    <Flex minH={heightP} flexDirection={'column'} justify={'center'} align={'center'} boxShadow={shadowBlack} borderRadius={'15px'} >
                        <Flex w={'100%'} h={'100%'} justify={'center'} align={'center'}>
                            <Flex w={'40%'} justify={'center'} align={'center'}>
                                <Flex w={'56px'} h={'56px'} borderRadius={'28px'} bg={'blackAlpha.200'} justify={'center'} align={'center'}>
                                    <Image h='30px' w='30px' src={Calendario} alt='logo' />
                                </Flex>
                            </Flex>
                            <Flex w={'60%'} h={'100%'} flexDirection={'column'} justify={'space-around'} align={'start'}>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{'Programada para:'}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'black'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{`${diaSemana} - ${descricaoData}`}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'center'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{''}</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex minH={heightP} flexDirection={'column'} justify={'center'} align={'center'} boxShadow={shadowBlack} borderRadius={'15px'}>
                        {idStatusTarefaAtual == 1 &&
                            <Flex w={'100%'} h={'100%'} flexDirection={'column'} justify={'space-around'} align={'center'}>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{'Realizada em:'}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'green'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{`${diaSemanaRealizadaAtual} ${descDataRealizadaAtual}`}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'center'}>
                                    <Text color={corPonto} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{`${textPontoVermelho}`}</Text>
                                </Flex>
                            </Flex>
                        }
                        {idStatusTarefaAtual == 2 &&
                            <Flex w={'100%'} h={'100%'} flexDirection={'column'} justify={'space-around'} align={'center'}>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{'Pendente:'}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={corStatus} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{`${dsStatusP}`}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'center'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{''}</Text>
                                </Flex>
                            </Flex>
                        }
                        {idStatusTarefaAtual == 3 &&
                            <Flex w={'100%'} h={'100%'} flexDirection={'column'} justify={'space-around'} align={'center'}>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{'Atrasada:'}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'red'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{`${dsStatusP}`}</Text>
                                </Flex>
                                <Flex h={'33%'} justify={'center'} align={'center'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{''}</Text>
                                </Flex>
                            </Flex>
                        }
                        {idStatusTarefaAtual == 4 &&
                            <Flex w={'100%'} h={'100%'} flexDirection={'column'} justify={'space-around'} align={'center'}>
                                <Flex h={'30%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{'Não realizada, motivo:'}</Text>
                                </Flex>
                                <Flex h={'40%'} justify={'center'} align={'flex-end'}>
                                    <Text color={'red'} fontSize={'25px'} fontWeight={'bold'} lineHeight={'100%'}>{`${descMotivoAtual}`}</Text>
                                </Flex>
                                <Flex h={'30%'} justify={'center'} align={'center'}>
                                    <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{''}</Text>
                                </Flex>
                            </Flex>
                        }
                    </Flex>
                    <Flex minH={heightP} flexDirection={'column'} justify={'center'} align={'center'} boxShadow={shadowBlack} borderRadius={'15px'}>
                        <Flex w={'100%'} h={'100%'} flexDirection={'column'} justify={'space-around'} align={'center'}>
                            <Flex h={'30%'} justify={'center'} align={'flex-end'}>
                                <Text color={'gray.500'} fontSize={ftSizeP} fontWeight={'light'} lineHeight={'100%'}>{'Tarefa'}</Text>
                            </Flex>
                            <Flex h={'50%'} justify={'center'} align={'flex-end'}>
                                <Text color={corStatus} fontSize={'35px'} fontWeight={'bold'} lineHeight={'100%'}>{`${letraCustomizada}`}</Text>
                            </Flex>
                            <Flex h={'20%'} justify={'center'} align={'center'}>
                                <Text color={corStatus} fontSize={ftSizeP} fontWeight={'bold'} lineHeight={'100%'}>{``}</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderRadioRealizadaNoDia() {
        return (
            <Flex mt={'20px'} px={'20px'} flexDirection={'column'} justify={'start'} align={'start'}>
                <Text py={'10px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'start'}>{'Esta tarefa foi relizada no dia programado?'}</Text>
                <RadioGroup onChange={(e) => { changeRadioButton(e) }} value={valueRadioRealizadaNoDia}>
                    <Wrap spacing='100px' mx={'10px'}>
                        <WrapItem>
                            <Box>
                                <Radio value='1' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'} >
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box>
                                <Radio value='2' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                    </Wrap>
                </RadioGroup>
            </Flex>
        )
    }

    function renderRadioRealizadaForaDoPrazo() {
        return (
            <Flex mt={'20px'} px={'20px'} flexDirection={'column'} justify={'start'} align={'start'}>
                <Text py={'10px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'start'}>{'Ela foi realizada?'}</Text>
                <RadioGroup onChange={(e) => { changeRadioForaDoPrazo(e) }} value={valueRadioForaDoPrazo}>
                    <Wrap spacing='100px' mx={'10px'}>
                        <WrapItem>
                            <Box>
                                <Radio value='3' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'} >
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Sim</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box>
                                <Radio value='4' size="lg" borderWidth={'1px'} borderColor={'blackAlpha.600'}>
                                    <Text ml={'1px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Não</Text>
                                </Radio>
                            </Box>
                        </WrapItem>
                    </Wrap>
                </RadioGroup>
            </Flex>
        )
    }

    function renderCalender() {
        return (
            <Box w={'100%'} px={'20px'} mt={'30px'}>
                <Text ml={'10px'} mb={'5px'} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>Em que dia ela foi realizada?</Text>
                <Input
                    value={dataRealizadoP}
                    variant='filled'
                    onChange={(e) => onChangeRealizado(e.target.value)}
                    errorBorderColor='crimson'
                    isInvalid={isValidData}
                    placeholder=""
                    bg={inputBg}
                    color={inputText}
                    borderColor={textColor}
                    borderRadius="10px"
                    size="md"
                    type="date"
                    min={minimumDate}
                    max={maximumDate}
                    onKeyDown={(e) => { e.preventDefault() }}
                    fontSize={'13px'}
                    fontWeight={'bold'}
                    h={'35px'}
                    w={'250px'}
                />
            </Box>
        )
    }

    function renderComboMotivo() {
        return (
            <Box px={'20px'} w='100%' mt={'30px'}>
                <Text ml={'10px'} mb={'5px'} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>Motivo</Text>
                <Select
                    value={motivoSelecionado}
                    errorBorderColor='crimson'
                    isInvalid={isValidMotivo}
                    placeholder="Todos"
                    variant="filled"
                    borderRadius="10px"
                    borderColor={textColor}
                    textColor={textColor}
                    fontSize={'13px'}
                    fontWeight={'bold'}
                    onChange={(e) => changeComboMotivo(e.target.value)}
                    h={'35px'}
                    w={'250px'}
                >
                    {MOTIVO_TAREFA_NAO_RELIZADA.map((item, index) => {
                        return (
                            <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                        )
                    })}
                </Select>
            </Box>
        )
    }

    function remderObservacoes() {
        return (
            <Box minH={'170px'} p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Observação'}</Text>
                    <Textarea
                        value={observacao}
                        onChange={(e) => { changeObservacao(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={1000}
                    />
                </Flex>
                <Flex px={'10px'} pt={'10px'} align='center' justify={'space-between'}>
                    <Button variant="action" borderRadius={'10px'} h={'30px'} color={'gray.500'} borderColor={'gray.500'} onClick={() => { limparObservacao() }}>Limpar</Button>
                    <Text color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterObservacao}`}</Text>
                </Flex>
            </Box>
        )
    }

    function renderVoltar() {
        return (
            <Wrap spacing='30px' px={'20px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' px={'10px'} mt={'30px'} justify='space-between'>
                <Button variant="brand" onClick={salvarTarefa}>Lançar tarefa</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-5px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center'>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'}>
                {isVisibleAlert && renderAlert()}
                {renderVoltar()}
                {renderTarefa()}
                <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={4} mt={'10px'}>
                    <GridItem>
                        <Box minH={'170px'} pb={'10px'} borderRadius={'10px'} boxShadow={cardShadowObs}>
                            {renderRadioRealizadaNoDia()}
                            {showRadioForaDoPrazo && renderRadioRealizadaForaDoPrazo()}
                            {showInserirData && renderCalender()}
                            {showMotivo && renderComboMotivo()}
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box borderRadius={'10px'}>
                            {remderObservacoes()}
                        </Box>
                    </GridItem>
                </Grid>
                {renderButton()}
            </Card>
            {openModalConfimacao && <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />}
            {loading && <Loader isOpen={loading} />}
        </Box>
    );
}
