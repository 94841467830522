import {
    Flex,
    Text,
    useColorModeValue,
    Box,
    Wrap,
    Center,
    Image,
    Button,
    TableContainer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as ActionTypes from '../../../constants/ActionTypes';
import moment from 'moment';
import LoaderBlack from 'components/loading/LoaderBlack';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';

const EstimulacaoPdf = () => {

    const pdfRef = useRef();
    const pdfRef2 = useRef();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [screenFullLoad, setScreenFullLoad] = useState(false);
    const [marginText, setMarginText] = useState('0px');
    const [frequencia, setFrequencia] = useState('');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [listTarefaBasica, setListTarefaBasica] = useState([]);
    const [arrayPart1, setArrayPart1] = useState([]);
    const [arrayPart2, setArrayPart2] = useState([]);
    const [listAtividadesGeral, setListAtividadesGeral] = useState([]);
    const [qtdArvoes, setQtdArvoes] = useState('');
    const [producaoEsperada, setProducaoEsperada] = useState('');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeUa, setNomeUa] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');

    const [nomeRelatorio, setNomeRelatorio] = useState('Calendario-estimulacao');
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);

                const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
                const nomeMunicipioP = localStorage.getItem('@HE-idNomeUfCadastradadoNaUa');
                const nomeUaP = localStorage.getItem('@HE-nomeUa');
                setIdUf(idUfP);
                setNomeMunicipio(nomeMunicipioP);
                setNomeUa(nomeUaP);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Calendario-estimulacao-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);
                const descData = `${nmMes}/${ano}`;
                setNomeMesCorrente(descData);

                const est = localStorage.getItem('@HE-telaForm-estimulacao');
                const json = JSON.parse(est);
                const qtdTarefas2 = Number(json.qtdTarefas);

                setFrequencia(json.frequencia);
                setNomeSeringueiro(json.nomeSeringueiro);
                setListAtividadesGeral(json.listAtividadesGeral);
                setQtdArvoes(json.qtdArvoes);
                setProducaoEsperada(json.producaoEsperada);
                const idPess = json.idPessoa;

                localStorage.setItem('@HE-id-pessoa-retorno', `${idPess}`);      // ESSE CARA E IMPORTANTE PARA O RETORNO DESSA TELA, E SERA REMOVIDO SEMPRE NO RETORNO

                const listSemana = json.arrySeringueiros;
                setListTarefaBasica(json.listTarefaBasica);

                let array1 = [];
                let array2 = [];
                let array3 = [];
                let array4 = [];
                let array5 = [];
                let arrayP1 = [];
                let arrayP2 = [];
                new Promise(resolve => {
                    resolve(
                        listSemana.map((item, index) => {       // QUANTIDADE DE COLUNAS PARA CADA ARRAY
                            const num = Number(item.numeroSemana);
                            if (index < 11) {
                                const json = {
                                    ...item
                                }
                                array1.push(json);
                            } else if (index >= 11 && index < 22) {
                                const json = {
                                    ...item
                                }
                                array2.push(json);
                            } else if (index >= 22 && index < 33) {
                                const json = {
                                    ...item
                                }
                                array3.push(json);
                            } else if (index >= 33 && index < 44) {
                                const json = {
                                    ...item
                                }
                                array4.push(json);
                            } else if (index >= 44 && index < 55) {
                                const json = {
                                    ...item
                                }
                                array5.push(json);
                            } else {

                            }
                        })
                    )
                    if (qtdTarefas2 < 6) {     // AGORA VOU VER QUANTAS CABE EM CADA PAGINA, TIPO SE O CARA FOR D7 TEM QUE SER MENOS
                        arrayP1.push(array1);
                        arrayP1.push(array2);
                        arrayP1.push(array3);
                        arrayP2.push(array4);
                        arrayP2.push(array5);
                    } else {
                        arrayP1.push(array1);
                        arrayP1.push(array2);
                        arrayP2.push(array3);
                        arrayP2.push(array4);
                        arrayP2.push(array5);
                    }
                    setArrayPart1(arrayP1);
                    setArrayPart2(arrayP2);
                })
                setScreenFullLoad(true);

            } catch (error) {
                setLoading(false);
                setScreenFullLoad(true);
                console.log('Erro na classe EstimulacaoPdf metodo useEffect', error);
            }
        }
        loaderScreen();
    }, [])

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1000);
    }

    const gerarPDF = async () => {
        const input1 = pdfRef.current;

        let imgData1 = null;
        let imgHeigh1 = 0;
        let imgWWIDTH1 = 0;

        let imgData2 = null;
        let imgHeigh2 = 0;

        await html2canvas(input1).then((canvas) => {
            imgData1 = canvas.toDataURL('image/png');
            imgHeigh1 = canvas.height;
            imgWWIDTH1 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH1, pdfHeigh / imgHeigh1);

        pdf.addImage(imgData1, 'PNG', 5, 10, pdfWidth - 10, imgHeigh1 * ratio1);

        if (arrayPart2.length) {
            const input2 = pdfRef2.current;

            await html2canvas(input2).then((canvas) => {
                imgData2 = canvas.toDataURL('image/png');
                imgHeigh2 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData2, 'PNG', 5, 10, pdfWidth - 10, imgHeigh2 * ratio1);
            });
        }
        pdf.save(`${nomeRelatorio}.pdf`);
    }

    function renderInfo() {
        const sizeF = '13px';
        const largTr = 0;
        const altura = 0;
        return (
            <Box px={'10px'}>
                <TableContainer borderTopRadius={'8px'} borderBottomRadius={'8px'}>
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'25px'} bgGradient={gradientHeader}>
                                <Th w={'10%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'} >
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Tarefa/Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Árvores em Sangria'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Produção Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Clone'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Painel'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Idade Sangria'}</Text>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listAtividadesGeral.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.letraCustomizada}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.tamanhoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.producaoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomeClone}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomePainel}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.idadeSangria}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            <Tr h={'25px'} bgGradient={gradientHeader}>
                                <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'} >
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Total'}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{qtdArvoes}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{producaoEsperada}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                    </Box>
                                </Th>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderEstimulacao(arr = []) {
        const corLingt = 'rgba(255, 166, 77, 0.03)';
        const corBlack = 'rgba(255, 166, 77, 0.4)';
        const SHADOW_GREEN = '1px 1px #00b300';
        const SHADOW_BLUE = '1px 1px #1a1aff';
        return (
            <Box>
                {arr.map((val, k) => {
                    return (
                        <Box key={k} py={'20px'}>
                            <Card direction='column' w='100%' px='0px' mt={'-5'} overflowX={{ sm: "scroll", lg: "hidden" }}>
                                <Box position={'relative'}>
                                    <Box display={'flex'} maxWidth={'100%'}>
                                        <Box w={'100%'} >
                                            <Flex px='0px' w={'100%'} justify='start' mt={'0px'} align='start'>
                                                {/* aa */}
                                                <Box w={'420px'}>
                                                    <Box h={'30px'} borderTopLeftRadius={'10px'} bg={'white'}>
                                                        <Text color={'white'} fontSize='12px' fontWeight='700' align={'center'}>{''}</Text>
                                                    </Box>
                                                    <Box h={'60px'} borderTopLeftRadius={'10px'} bg={val.length > 0 ? 'white' : 'white'}>
                                                        <Text color={'white'} fontSize='12px' fontWeight='700' align={'center'}>{''}</Text>
                                                    </Box>
                                                </Box>
                                                {val.map((item, index) => {
                                                    let dtInicioSemana = item.dtInicioSemana != null && item.dtInicioSemana != '' ? moment(item.dtInicioSemana).format('DD/MM/YY') : '';
                                                    let dtFimSemana = item.dtFimSemana != null && item.dtFimSemana != '' ? moment(item.dtFimSemana).format('DD/MM/YY') : '';
                                                    return (
                                                        <Flex
                                                            key={index}
                                                            w={'100%'}
                                                            justify='center'
                                                            align='center'>
                                                            <Box w={'100%'} >
                                                                {/* NUMERO SEMANA */}
                                                                <Box w={'100%'} h={'30px'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                                                    <Box
                                                                        w={'30px'}
                                                                        h={'25px'}
                                                                        display={'flex'}
                                                                        justifyContent={'center'}
                                                                        alignItems={'center'}
                                                                        borderRadius={'8px'}
                                                                        borderWidth={'2px'}
                                                                        borderColor={'orange.900'}
                                                                        bg={'orange.800'}
                                                                    >
                                                                        <Text mb={marginText} color={'white'} fontSize='12px' fontWeight='700'>{item.numeroSemana}</Text>
                                                                    </Box>
                                                                </Box>

                                                                {/* DATA INICIO E FIM */}
                                                                <Box w={'100%'} h={'50px'} borderRightWidth={'1px'} borderColor={'white'} bgGradient={gradientHeader}>
                                                                    <Center w={'100%'} h={'100%'} flexDirection={'column'}>
                                                                        <Text color={'white'} fontSize='12px' fontWeight='100' align={'center'} lineHeight='100%'>{dtInicioSemana}</Text>
                                                                        <Text color={'white'} fontSize='12px' fontWeight='100' align={'center'} lineHeight='100%'>{'à'}</Text>
                                                                        <Text mb={marginText} color={'white'} fontSize='12px' fontWeight='100' align={'center'} lineHeight='100%'>{dtFimSemana}</Text>
                                                                    </Center>
                                                                </Box>
                                                                {item.listTarefas.map((x, j) => {
                                                                    let corLinha2 = corLingt;
                                                                    if (x.letra == 'A') {
                                                                        corLinha2 = corLingt;
                                                                    } else if (x.letra == 'B') {
                                                                        corLinha2 = corBlack;
                                                                    } else if (x.letra == 'C') {
                                                                        corLinha2 = corLingt;
                                                                    } else if (x.letra == 'D') {
                                                                        corLinha2 = corBlack;
                                                                    } else if (x.letra == 'E') {
                                                                        corLinha2 = corLingt;
                                                                    } else if (x.letra == 'F') {
                                                                        corLinha2 = corBlack;
                                                                    } else {
                                                                        corLinha2 = corLingt;
                                                                    }
                                                                    let descDtSelecionada = '';
                                                                    let colorDt = 'transparent';
                                                                    let shadowStatus = 'transparent';

                                                                    if (x.descDtSelecionada == '') {
                                                                        descDtSelecionada = '__/__/__';
                                                                    } else {
                                                                        descDtSelecionada = x.descDtSelecionada;
                                                                        colorDt = 'blackAlpha.900';

                                                                        if (x.statusPeriodo != '') {
                                                                            colorDt = 'blackAlpha.600';

                                                                            if (x.statusPeriodo == '1') {
                                                                                shadowStatus = SHADOW_BLUE;
                                                                            } else if (x.statusPeriodo == '2') {
                                                                                shadowStatus = SHADOW_GREEN;
                                                                            }
                                                                        }
                                                                    }
                                                                    let descStatus = x.descStatus == '' ? '-' : x.descStatus;
                                                                    return (
                                                                        <Box
                                                                            key={j}
                                                                            position={'relative'}
                                                                            w={'100%'}
                                                                            h={'40px'}
                                                                            borderRightWidth={'1px'}
                                                                            borderBottomWidth={'1px'}
                                                                            borderColor={'blackAlpha.300'}
                                                                            bg={corLinha2} >
                                                                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'100%'}>
                                                                                <Flex h={'100%'} w={'100%'} flexDirection={'column'} justify='center' align='center'>
                                                                                    <Text textShadow={shadowStatus} color={colorDt} fontSize='14px' fontWeight={'bold'} align={'center'} lineHeight={'100%'} whiteSpace={'break-spaces'}>{descStatus}</Text>
                                                                                    <Text mb={marginText} mt={'5px'} textShadow={shadowStatus} color={colorDt} fontSize='14px' fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{descDtSelecionada}</Text>
                                                                                </Flex>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </Flex>
                                                    )
                                                })}
                                            </Flex>
                                        </Box>
                                    </Box>
                                    {/* LETRAS FREQUENCIA */}
                                    <Box pos="absolute" left={'0px'} top={'80px'} zIndex={2} >
                                        {listTarefaBasica.map((rr, ww) => {
                                            return (
                                                <Box
                                                    key={ww}
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    w={'37px'}
                                                    h={'40px'}
                                                    //bg={colorDadosSeringueiro}
                                                    bg={'white'}
                                                >
                                                    <Box display={'flex'} w={'30px'} h={'25px'} justifyContent={'center'} alignItems={'center'} borderRadius={'8px'} bg={'orange.400'}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize='13px' fontWeight='700' >{rr.letraCustomizada}</Text>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} my={'10px'} justify='start'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    if (!screenFullLoad) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='0px'>
                <Flex px='25px' justify='end' mt='10px' align='center'>
                    <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' onClick={downloadPdf} />
                </Flex>
                <Box ref={pdfRef}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={'orange.600'} fontSize='20px' fontWeight={'bold'}>{'Calendário Estimulação'}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesCorrente}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                        </Box>
                    </Box>
                    <Flex px={'20px'} justify='space-between' align='center'>
                        <Flex px={'20px'} justify='start' align='center'>
                            <Text mb={marginText} color={'gray.500'} fontSize='18px' fontWeight='bold'>{'Seringueiro:'}</Text>
                            <Text mb={marginText} color={'orange.600'} fontSize='18px' fontWeight='bold' ml={'10px'}>{nomeSeringueiro}</Text>
                        </Flex>

                        <Flex px={'20px'} justify='end' align='center'>
                            <Text mb={marginText} color={'gray.500'} fontSize='18px' fontWeight='700'>{'Frequência:'}</Text>
                            <Text mb={marginText} color={'orange.600'} fontSize='18px' fontWeight='700' ml={'15px'}>{frequencia}</Text>
                        </Flex>
                    </Flex>
                    {renderInfo()}
                    {renderEstimulacao(arrayPart1)}
                </Box>
                <Box ref={pdfRef2}>
                    {renderEstimulacao(arrayPart2)}
                </Box>
                {renderButton()}
            </Card>
            <LoaderBlack isOpen={loading} />
        </Box>
    );

};

export default EstimulacaoPdf;
