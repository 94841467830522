import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    Wrap,
    SimpleGrid,
    Icon,
    Link,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { TbAlertTriangleFilled } from "react-icons/tb";
import { IoArrowRedo } from "react-icons/io5";
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { mascaraValorComPontoAposPrimeiroNumero } from '../../../util/Masck';
import MessageSreen from 'components/message/MessageSreen';

export default function CustomizarPontuacaoItem() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [produtivBaixoP, setProdutivBaixoP] = useState('');
    const [produtivMedioP, setProdutivMedioP] = useState('');
    const [produtivAltoP, setProdutivAltoP] = useState('');
    const [consumoBaixoP, setConsumoBaixoP] = useState('');
    const [consumoMedioP, setConsumoMedioP] = useState('');
    const [consumoAltoP, setConsumoAltoP] = useState('');
    const [ataqueGeratrizesP, setAtaqueGeratrizesP] = useState('');
    const [declividadeCorteP, setDeclividadeCorteP] = useState('');
    const [conformidadeEquipamentosP, setConformidadeEquipamentosP] = useState('');
    const [impurezasCoaguloP, setImpurezasCoaguloP] = useState('');
    const [facaNaoAmoladaP, setFacaNaoAmoladaP] = useState('');
    const [arvoreSangradaP, setArvoreSangradaP] = useState('');
    const [controleFitossanitarioP, setControleFitossanitarioP] = useState('');
    const [latexPerdaProducaoP, setLatexPerdaProducaoP] = useState('');
    const [imputFerimentoDesviadoP, setImputFerimentoDesviadoP] = useState('');
    const [imputFerimentoNaoDesviadoP, setImputFerimentoNaoDesviadoP] = useState('');

    const [isProdutivBaixoP, setIsProdutivBaixoP] = useState(false);
    const [isProdutivMedioP, setIsProdutivMedioP] = useState(false);
    const [isProdutivAltoP, setIsProdutivAltoP] = useState(false);
    const [isConsumoBaixoP, setIsConsumoBaixoP] = useState(false);
    const [isConsumoMedioP, setIsConsumoMedioP] = useState(false);
    const [isConsumoAltoP, setIsConsumoAltoP] = useState(false);
    const [isAtaqueGeratrizesP, setIsAtaqueGeratrizesP] = useState(false);
    const [isDeclividadeCorteP, setIsDeclividadeCorteP] = useState(false);
    const [isConformidadeEquipamentosP, setIsConformidadeEquipamentosP] = useState(false);
    const [isImpurezasCoaguloP, setIsImpurezasCoaguloP] = useState(false);
    const [isFacaNaoAmoladaP, setIsFacaNaoAmoladaP] = useState(false);
    const [isArvoreSangradaP, setIsArvoreSangradaP] = useState(false);
    const [isControleFitossanitarioP, setIsControleFitossanitarioP] = useState(false);
    const [isLatexPerdaProducaoP, setIsLatexPerdaProducaoP] = useState(false);
    const [isValidFeriDesviado, setisValidFeriDesviado] = useState(false);
    const [isValidFeriNaoDesviado, setisValidFeriNaoDesviado] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');

    const [descMesP, setDescMesP] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [loading, setLoading] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const cardShadowCard = useColorModeValue('0px 2px 5px rgba(0, 0, 0, 0.5)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);
                const ano = moment().format('YYYY');
                const mes = moment().format('YYYY-MM-DD');
                const nomeMes = await retornaNomeDoMes(mes);
                setAnoCorrente(ano);
                setDescMesP(nomeMes);
                setUaSelecionado('');

                const par = localStorage.getItem('@HE-dadosParaCustomizacaoPontos');
                if (par != null) {
                    const param = JSON.parse(par);
                    setUaSelecionado(param.idUa);
                    setNomeFazenda(param.nomeFazenda);

                    await changePropriedade(param.idUa);
                }

                const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
                setSangriaAtiva(sangriaAti == 'true' ? true : false);

                setTelaCarregada(true);

            } catch (error) {
                console.log('Error na classe CustomizarPontuacaoItem metodo useEffect ', error);
            }
        }
        loaderScreen();
        return () => {
            setIsVisibleAlert(false);
        }
    }, []);

    const changePropriedade = async (e) => {
        const idUa = e;
        setUaSelecionado(idUa);

        if (idUa != '') {
            const url = `valorPonto/retornaValorPontoParaUmaUa?idUa=${idUa}`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                const avr = res[0];

                setArvoreSangradaP(avr.arvoreSangrada != null ? avr.arvoreSangrada : '');
                setAtaqueGeratrizesP(avr.ataqueGeratrizes != null ? avr.ataqueGeratrizes : '');
                setConformidadeEquipamentosP(avr.conformidadeEquipamentos != null ? avr.conformidadeEquipamentos : '');
                setControleFitossanitarioP(avr.controleFitossanitario != null ? avr.controleFitossanitario : '');
                setDeclividadeCorteP(avr.declividadeCorte != null ? avr.declividadeCorte : '');
                setFacaNaoAmoladaP(avr.facaNaoAmolada != null ? avr.facaNaoAmolada : '');
                setImpurezasCoaguloP(avr.impurezasCoagulo != null ? avr.impurezasCoagulo : '');
                setLatexPerdaProducaoP(avr.latexPerdaProducao != null ? avr.latexPerdaProducao : '');
                setConsumoAltoP(avr.consumoAlto != null ? avr.consumoAlto : '');
                setConsumoBaixoP(avr.consumoBaixo != null ? avr.consumoBaixo : '');
                setConsumoMedioP(avr.consumoMedio != null ? avr.consumoMedio : '');
                setProdutivAltoP(avr.profundidadeAlto != null ? avr.profundidadeAlto : '');
                setProdutivBaixoP(avr.profundidadeBaixo != null ? avr.profundidadeBaixo : '');
                setProdutivMedioP(avr.profundidadeMedio != null ? avr.profundidadeMedio : '');
                setImputFerimentoDesviadoP(avr.ferimentoDesviado != null && avr.ferimentoDesviado != '' ? avr.ferimentoDesviado : '');
                setImputFerimentoNaoDesviadoP(avr.ferimentoNaoDesviado != null && avr.ferimentoNaoDesviado != '' ? avr.ferimentoNaoDesviado : '');

                setLoading(false);
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } else {
            setArvoreSangradaP('');
            setAtaqueGeratrizesP('');
            setConformidadeEquipamentosP('');
            setControleFitossanitarioP('');
            setDeclividadeCorteP('');
            setFacaNaoAmoladaP('');
            setImpurezasCoaguloP('');
            setLatexPerdaProducaoP('');
            setConsumoAltoP('');
            setConsumoBaixoP('');
            setConsumoMedioP('');
            setProdutivAltoP('');
            setProdutivBaixoP('');
            setProdutivMedioP('');
        }
    }

    const salvarAvaliacao = async () => {
        try {
            let valid = true;

            const validarCa = await validarCampos();
            if (validarCa) {
                valid = false;
                setNumStatusResposta(ActionTypes.CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS);
                showAlert();
            }

            const idUaP = localStorage.getItem('@HE-idUa');

            if (!valid) {
                return;
            }

            const jsonAvaliacao = {
                idUa: idUaP,
                profundidadeBaixo: produtivBaixoP,
                profundidadeMedio: produtivMedioP,
                profundidadeAlto: produtivAltoP,
                consumoBaixo: consumoBaixoP,
                consumoMedio: consumoMedioP,
                consumoAlto: consumoAltoP,
                ataqueGeratrizes: ataqueGeratrizesP,
                declividadeCorte: declividadeCorteP,
                conformidadeEquipamentos: conformidadeEquipamentosP,
                impurezasCoagulo: impurezasCoaguloP,
                facaNaoAmolada: facaNaoAmoladaP,
                arvoreSangrada: arvoreSangradaP,
                controleFitossanitario: controleFitossanitarioP,
                latexPerdaProducao: latexPerdaProducaoP,
                ferimentoDesviado: imputFerimentoDesviadoP,
                ferimentoNaoDesviado: imputFerimentoNaoDesviadoP,
            }

            const response = await Api.putRequest(`valorPonto/insertAndUpdateValorPonto`, jsonAvaliacao);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            console.log('Error na classe CustomizarPontuacaoItem metodo salvarAvaliacao', error);
        }
    }

    const changeProdutivBaixo = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setProdutivBaixoP(soNumeros);
    }
    const changeProdutivMedio = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setProdutivMedioP(soNumeros);
    }
    const changeProdutivAlto = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setProdutivAltoP(soNumeros);
    }
    const changeConsumoBaixo = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setConsumoBaixoP(soNumeros);
    }
    const changeConsumoMedio = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setConsumoMedioP(soNumeros);
    }
    const changeConsumoAlto = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setConsumoAltoP(soNumeros);
    }
    const changeAtaqueGeratrizes = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setAtaqueGeratrizesP(soNumeros);
    }
    const changeDeclividadeCorte = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setDeclividadeCorteP(soNumeros);
    }
    const changeConformidadeEquipamentos = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setConformidadeEquipamentosP(soNumeros);
    }
    const changeImpurezasCoagulo = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setImpurezasCoaguloP(soNumeros);
    }
    const changeFacaNaoAmolada = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setFacaNaoAmoladaP(soNumeros);
    }
    const changeArvoreSangrada = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setArvoreSangradaP(soNumeros);
    }
    const changeControleFitossanitario = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setControleFitossanitarioP(soNumeros);
    }
    const changeLatexPerdaProducao = async (e) => {
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(e);
        setLatexPerdaProducaoP(soNumeros);
    }
    const changeFerimentoDesviado = async (e) => {
        const val = e.target.value;
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(val);
        setImputFerimentoDesviadoP(soNumeros);
    }
    const changeFerimentoNaoDesviado = async (e) => {
        const val = e.target.value;
        const soNumeros = await mascaraValorComPontoAposPrimeiroNumero(val);
        setImputFerimentoNaoDesviadoP(soNumeros);
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 9000);
    }

    const validarCampos = async () => {
        let valid = false;

        setIsProdutivBaixoP(false);
        setIsProdutivMedioP(false);
        setIsProdutivAltoP(false);
        setIsConsumoBaixoP(false);
        setIsConsumoMedioP(false);
        setIsConsumoAltoP(false);
        setIsAtaqueGeratrizesP(false);
        setIsDeclividadeCorteP(false);
        setIsConformidadeEquipamentosP(false);
        setIsImpurezasCoaguloP(false);
        setIsFacaNaoAmoladaP(false);
        setIsArvoreSangradaP(false);
        setIsControleFitossanitarioP(false);
        setIsLatexPerdaProducaoP(false);
        setisValidFeriDesviado(false);
        setisValidFeriNaoDesviado(false);

        if (produtivBaixoP.trim().length < 1) { valid = true; setIsProdutivBaixoP(true) }
        if (produtivMedioP.trim().length < 1) { valid = true; setIsProdutivMedioP(true) }
        if (produtivAltoP.trim().length < 1) { valid = true; setIsProdutivAltoP(true) }
        if (consumoBaixoP.trim().length < 1) { valid = true; setIsConsumoBaixoP(true) }
        if (consumoMedioP.trim().length < 1) { valid = true; setIsConsumoMedioP(true) }
        if (consumoAltoP.trim().length < 1) { valid = true; setIsConsumoAltoP(true) }
        if (ataqueGeratrizesP.trim().length < 1) { valid = true; setIsAtaqueGeratrizesP(true) }
        if (declividadeCorteP.trim().length < 1) { valid = true; setIsDeclividadeCorteP(true) }
        if (conformidadeEquipamentosP.trim().length < 1) { valid = true; setIsConformidadeEquipamentosP(true) }
        if (impurezasCoaguloP.trim().length < 1) { valid = true; setIsImpurezasCoaguloP(true) }
        if (facaNaoAmoladaP.trim().length < 1) { valid = true; setIsFacaNaoAmoladaP(true) }
        if (arvoreSangradaP.trim().length < 1) { valid = true; setIsArvoreSangradaP(true) }
        if (controleFitossanitarioP.trim().length < 1) { valid = true; setIsControleFitossanitarioP(true) }
        if (latexPerdaProducaoP.trim().length < 1) { valid = true; setIsLatexPerdaProducaoP(true) }
        if (imputFerimentoDesviadoP.trim().length < 1) { valid = true; setisValidFeriDesviado(true) }
        if (imputFerimentoNaoDesviadoP.trim().length < 1) { valid = true; setisValidFeriNaoDesviado(true) }

        return valid;
    }

    function renderTitulo() {
        return (
            <Box mb={'10px'}>
                <Flex justify='center' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'Altere as pontuções para sua propriedade'}</Text>
                </Flex>
                <Flex px={'10px'} justify='space-between' align='center'>
                    <Flex px={'10px'} justify='start' align='center'>
                        <Box w={'100px'}>
                            <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Propriedade:</Text>
                        </Box>
                        <Box>
                            <Text color={'orange.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{nomeFazenda}</Text>
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderProdutividade() {
        return (
            <Box borderRadius={'15px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{`Profundidade`}</Text>
                </Box>
                <Flex
                    pointerEvents={uaSelecionado != '' ? 'auto' : 'none'}
                    opacity={uaSelecionado != '' ? 1 : 0.6}
                    px={'20px'}
                    flexDirection={'column'}
                    justify='center'
                    align='center'>
                    <Flex w={'100%'} py={'15px'} justify='space-between' align='center'>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Baixo'}</Text>
                            <Input
                                value={produtivBaixoP}
                                onChange={(e) => { changeProdutivBaixo(e.target.value) }}
                                isInvalid={isProdutivBaixoP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'100px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Médio'}</Text>
                            <Input
                                value={produtivMedioP}
                                onChange={(e) => { changeProdutivMedio(e.target.value) }}
                                isInvalid={isProdutivMedioP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'100px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Alto'}</Text>
                            <Input
                                value={produtivAltoP}
                                onChange={(e) => { changeProdutivAlto(e.target.value) }}
                                isInvalid={isProdutivAltoP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'100px'}
                                maxLength={3}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderConsumo() {
        return (
            <Box borderRadius={'15px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'} align={'center'}>{'Consumo'}</Text>
                </Box>
                <Flex
                    pointerEvents={uaSelecionado != '' ? 'auto' : 'none'}
                    opacity={uaSelecionado != '' ? 1 : 0.6}
                    px={'20px'}
                    flexDirection={'column'}
                    justify='center'
                    align='center'>
                    <Flex w={'100%'} py={'15px'} justify='space-between' align='center'>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Baixo'}</Text>
                            <Input
                                value={consumoBaixoP}
                                onChange={(e) => { changeConsumoBaixo(e.target.value) }}
                                isInvalid={isConsumoBaixoP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'100px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Médio'}</Text>
                            <Input
                                value={consumoMedioP}
                                onChange={(e) => { changeConsumoMedio(e.target.value) }}
                                isInvalid={isConsumoMedioP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'100px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Alto'}</Text>
                            <Input
                                value={consumoAltoP}
                                onChange={(e) => { changeConsumoAlto(e.target.value) }}
                                isInvalid={isConsumoAltoP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'100px'}
                                maxLength={3}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderAspecto() {
        return (
            <Box borderRadius={'15px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Aspecto'}</Text>
                </Box>
                <Flex
                    pointerEvents={uaSelecionado != '' ? 'auto' : 'none'}
                    opacity={uaSelecionado != '' ? 1 : 0.6}
                    flexDirection={'column'}
                    px={'5px'}
                    mb={'20px'}
                    justify='center'
                    align='start'>
                    <Flex w={'100%'} mt={'15px'} justify='space-around' align='center'>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Ataque a geratrizes'}</Text>
                            <Input
                                value={ataqueGeratrizesP}
                                onChange={(e) => { changeAtaqueGeratrizes(e.target.value) }}
                                isInvalid={isAtaqueGeratrizesP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Declividade do corte'}</Text>
                            <Input
                                value={declividadeCorteP}
                                onChange={(e) => { changeDeclividadeCorte(e.target.value) }}
                                isInvalid={isDeclividadeCorteP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={'15px'} justify='space-around' align='center'>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Conformidade equip'}</Text>
                            <Input
                                value={conformidadeEquipamentosP}
                                onChange={(e) => { changeConformidadeEquipamentos(e.target.value) }}
                                isInvalid={isConformidadeEquipamentosP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Impurezas no coagulo'}</Text>
                            <Input
                                value={impurezasCoaguloP}
                                onChange={(e) => { changeImpurezasCoagulo(e.target.value) }}
                                isInvalid={isImpurezasCoaguloP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={'15px'} justify='space-around' align='center'>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Faca não amolada'}</Text>
                            <Input
                                value={facaNaoAmoladaP}
                                onChange={(e) => { changeFacaNaoAmolada(e.target.value) }}
                                isInvalid={isFacaNaoAmoladaP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Árvore não sangrada'}</Text>
                            <Input
                                value={arvoreSangradaP}
                                onChange={(e) => { changeArvoreSangrada(e.target.value) }}
                                isInvalid={isArvoreSangradaP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={'15px'} justify='space-around' align='center'>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Controle Fitossanitário'}</Text>
                            <Input
                                value={controleFitossanitarioP}
                                onChange={(e) => { changeControleFitossanitario(e.target.value) }}
                                isInvalid={isControleFitossanitarioP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight='bold' lineHeight={'100%'} mb={'3px'}>{'Látex não guiado/perda'}</Text>
                            <Input
                                value={latexPerdaProducaoP}
                                onChange={(e) => { changeLatexPerdaProducao(e.target.value) }}
                                isInvalid={isLatexPerdaProducaoP}
                                placeholder="0.0"
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderFerimento() {
        return (
            <Box borderRadius={'15px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Ferimento'}</Text>
                </Box>
                <Flex
                    pointerEvents={uaSelecionado != '' ? 'auto' : 'none'}
                    opacity={uaSelecionado != '' ? 1 : 0.6}
                    flexDirection={'column'}
                    h={'100%'}
                    px={'10px'}
                    justify='start'
                    align='start'>
                    <Flex w={'100%'} py={'15px'} px={'10px'} justify='center' align='start'>
                        <Icon as={TbAlertTriangleFilled} width='25px' height='25px' color={'orange'} />
                        <Text color={textColor} fontSize={'16px'} fontWeight={'bold'} lineHeight={'100%'} ml={'10px'}>{'O campo Não desviado será multiplicado pela penalização de 4 vezes o indicado'}</Text>
                    </Flex>
                    <Flex w={'100%'} px={'10px'} mt={'10px'} justify='center' align='center'>
                        <Flex w={'50%'} flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight={'bold'} lineHeight={'100%'} mb={'3px'}>Desviado</Text>
                            <Input
                                value={imputFerimentoDesviadoP}
                                onChange={changeFerimentoDesviado}
                                isInvalid={isValidFeriDesviado}
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'14px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'150px'}
                                maxLength={3}
                            />
                        </Flex>
                        <Flex flexDirection={'column'} w={'50%'} justify='center' align='center'>
                            <Text color={textColor} fontSize={'14px'} fontWeight={'bold'} lineHeight={'100%'} mb={'3px'}>Não desviado</Text>
                            <Box>
                                <InputGroup size='md'>
                                    <Input
                                        value={imputFerimentoNaoDesviadoP}
                                        onChange={changeFerimentoNaoDesviado}
                                        isInvalid={isValidFeriNaoDesviado}
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        fontSize={'14px'}
                                        fontWeight={'bold'}
                                        h={'35px'}
                                        w={'150px'}
                                        maxLength={3}
                                    />
                                    <InputRightElement display='flex' alignItems='center'>
                                        <Text color={'green.500'} fontSize={'14px'} fontWeight={'bold'}>{''}</Text>
                                    </InputRightElement>
                                </InputGroup>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderReferencias() {
        return (
            <Flex flexDirection={'column'} px={'10px'} mt={'10px'} justify='center' align='center'>
                <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%' mr={'5px'}>{'Documentação de referência'}</Text>
                <Flex px={'10px'} mt={'10px'} justify='center' align='center'>
                    <Flex px={'15px'} justify='center' align='end'>
                        <Link color='teal.500' target="_blank" href={`${ActionTypes.URL_PONTUACAO_FORMULARIO_PDF}`} >
                            <Flex justify='end' align='center'>
                                <Text color={'blue.500'} fontSize='15px' fontWeight='bold' lineHeight='100%' mr={'8px'}>{'Formulário de Avaliação'}</Text>
                                <Icon as={IoArrowRedo} width='25px' height='25px' color={'blue.500'} />
                            </Flex>
                        </Link>
                    </Flex>
                    <Flex px={'15px'} justify='center' align='end'>
                        <Link color='teal.500' target="_blank" href={`${ActionTypes.URL_PONTUACAO_QUALIDADE_DE_SANGRIA_PDF}`} >
                            <Flex justify='end' align='center' >
                                <Text color={'blue.500'} fontSize='15px' fontWeight='bold' lineHeight='100%' mr={'8px'}>{'Qualidade de Sangria'}</Text>
                                <Icon as={IoArrowRedo} width='25px' height='25px' color={'blue.500'} />
                            </Flex>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    function renderAlert() {
        return (
            <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                <Flex px='25px' justify='center' my='5px' align='center' >
                    <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                </Flex>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button isDisabled={uaSelecionado != '' ? false : true} variant="brand" onClick={salvarAvaliacao}>Salvar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'}>
                <Box mb={'20px'}>
                    {renderTitulo()}
                </Box>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                    {renderProdutividade()}
                    {renderConsumo()}
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                    {renderAspecto()}
                    {renderFerimento()}
                </SimpleGrid>
                {renderReferencias()}
                {isVisibleAlert && renderAlert()}
            </Card>
            {renderButton()}
        </Box>
    );
}
