import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Select,
    Box,
    FormLabel,
    Image,
    Icon,
    Center,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import ReactApexChart from "react-apexcharts";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LoaderBlack from 'components/loading/LoaderBlack';
import { ARRAY_MESES_SAFRA_PRODUCAO } from "views/admin/default/variables/MockData";
import * as ActionTypes from '../../../../constants/ActionTypes';
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import TooltipSeringueiro from 'components/tooltip/TooltipSeringueiro';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { TiArrowLeftThick, TiArrowRightThick } from "react-icons/ti";

export default function AcompanhamentoProducao() {

    const pdfRef = useRef();
    const [loading, setLoading] = useState(false);
    const [marginText, setMarginText] = useState('0px');
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [arrayProducaoCompleto, setarrayProducaoCompleto] = useState([]);
    const [totalArvoresSangradas, setTotalArvoresSangradas] = useState(0);
    const [totalDistribuicao, setTotalDistribuicao] = useState(0);
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [totalPercentual, setTotalPercentual] = useState(0);

    const [totalPrevisaoKg, setTotalPrevisaoKg] = useState(0);
    const [totalPercentualPrevisaoKg, setTotalPercentualPrevisaoKg] = useState(0);
    const [extratoProducao, setExtratoProducao] = useState([]);
    const [listGraficoRealizado, setListGraficoRealizado] = useState([]);
    const [listGraficoPrevisto, setListGraficoPrevisto] = useState([]);
    const [listGraficoEficiencia, setListGraficoEficiencia] = useState([]);
    const [arrayMesesAndData, setArrayMesesAndData] = useState([]);

    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [nomePessoa, setNomePessoa] = useState('Todos');
    const [nomeRelatorio, setNomeRelatorio] = useState('Acompanhamento-producao');
    const [expandedColunas, setExpandedColunas] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientSubTotal = 'linear-gradient(#ff8000, #ff9933, #ffcc99)';
    const gradientFocus = 'linear-gradient(blackAlpha.500, blackAlpha.400, blackAlpha.300)';
    const gradientPercent = 'linear-gradient(orange.300, orange.200, orange.50)';

    useEffect(() => {
        async function loadScreen() {
            try {
                const nomeUa = localStorage.getItem('@HE-nomeUa');
                setNomeFazenda(nomeUa);
                const nmSafra = localStorage.getItem('@HE-nomeSafra');
                setNomeSafra(nmSafra);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Acompanhamento-producao-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);

                localStorage.removeItem('@HE-DadosParaTelaGeracaoPdfAcompanhamentoProducao');

                const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
                const fim = localStorage.getItem('@HE-dtFimSafraAsync');

                const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
                setArrayMesesAndData(ARRAY_MES);

                const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
                const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');
                const dtIn = new Date(dtInicioSafra);
                const dtFn = new Date(dtFimSafra);

                await pesquisar(dtIn, dtFn, ARRAY_MES);
                setTelaCarregada(true);

            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loadScreen();
    }, []);

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        await html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const pesquisar = async (dtInicioP, dtFimP, ARRAY_MES) => {
        try {
            const dtI = moment(dtInicioP).format('YYYY-MM-DD');
            const dtF = moment(dtFimP).format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: ua,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                arrayMesDataFilter: ARRAY_MES
            }
            const response = await Api.postRequest(`producao/relatorioAcompanhamentoProducao`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listSeringueiros = res.listSeringueiros;
                const responseProducao = res.responseProducao;

                await carregaDadosSeringueiro(responseProducao.listRelatorioFazenda[0], false, 'Todos');
                setarrayProducaoCompleto(responseProducao);

                if (listSeringueiros.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }
            } else {

                return;
            }
        } catch (error) {
            console.log('Erro na classe AcompanhamentoDaProducao metodo pesquisar', error);
        }
    }

    const carregaDadosSeringueiro = async (arraySeringueirosComMeses, seringueiroSelecionadoP = false, nomePess = 'Todos') => {

        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);
        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setTotalArvoresSangradas(res.totalArvoresSangradas);
        setTotalDistribuicao(res.totalDistribuicao);
        setExtratoProducao(res.arrayProducaoAndGrafico);
        setTotalPercentual(res.totalPercentual);
        setTotalPrevisaoKg(res.totalPrevisaoKg);
        setTotalPercentualPrevisaoKg(res.totalPercentualPrevisaoKg);
        const arraysGraficoProd = await constroiArrasGrafico(res.arrayProducaoAndGrafico);

        const jsonProducao = {
            totalRealizado: res.totalRealizado,
            totalPrevisto: res.totalPrevisto,
            totalArvoresSangradas: res.totalArvoresSangradas,
            totalDistribuicao: res.totalDistribuicao,
            extratoProducao: res.arrayProducaoAndGrafico,
            totalPercentual: res.totalPercentual,
            arraysGraficoProdcao: arraysGraficoProd,
            nomePessoa: nomePess
        }
        localStorage.setItem('HE@-dadosRelatorioAcompanhamentoProducao', JSON.stringify(jsonProducao));
    }

    const constroiArrasGrafico = async (arr) => {
        let result = null;
        try {
            if (arr.length > 0) {

                let arrqyRealizadas = [];
                let arrqyProvisto = [];
                let arrqyEficiencia = [];
                await new Promise(resolve => {
                    resolve(
                        arr.map(function (item) {
                            let qtdRealizado = item.realizadoGrafico != '' ? item.realizadoGrafico : 0;
                            let qtdPrevisto = item.previstoGrafico != '' ? item.previstoGrafico : 0;
                            let qtdEficiencia = item.percentual != '' ? item.percentual : 0;

                            arrqyRealizadas.push(qtdRealizado);
                            arrqyProvisto.push(qtdPrevisto);
                            arrqyEficiencia.push(Number(qtdEficiencia));
                        })
                    )
                });

                let pp1 = null;
                let pp2 = null;
                let pp3 = null;

                let arr1 = arrqyRealizadas.map(x => x);
                let arr2 = arrqyProvisto.map(x => x);
                let arr3 = arrqyEficiencia.map(x => x);

                arr1.sort(function (a, b) { return b - a });
                arr2.sort(function (a, b) { return b - a });
                arr3.sort(function (a, b) { return b - a });

                if (arr1[0] == 0) {
                    pp1 = arrqyRealizadas.map(x => '0%');
                } else {
                    pp1 = arrqyRealizadas;
                }
                if (arr2[0] == 0) {
                    pp2 = arrqyProvisto.map(x => '0%');
                } else {
                    pp2 = arrqyProvisto;
                }
                if (arr3[0] == 0) {
                    pp3 = [];
                } else {
                    pp3 = arrqyEficiencia;
                }

                setListGraficoRealizado(pp1);
                setListGraficoPrevisto(pp2);
                setListGraficoEficiencia(pp3);

                result = {
                    listGraficoRealizado: pp1,
                    listGraficoPrevisto: pp2,
                    listGraficoEficiencia: pp3,
                }
            }
        } catch (error) {
            console.log('Erro na classe AcompanhamentoDaProducao metodo constroiArrasGrafico', error);
        }
        return result;
    }

    const changePessoa = async (e) => {
        try {
            const val = e.target.value;
            setPessoaSelecionado(val);
            let nomePess = 'Todos';

            if (val != '') {
                const arraySeringueiros = arrayProducaoCompleto.listRelatorioAcompanhamentoProducao;
                const arrFilter = arraySeringueiros.filter(x => x.idPessoa == val);
                const arraySeringueirosComMeses = arrFilter.length > 0 ? arrFilter[0] : ARRAY_MESES_SAFRA_PRODUCAO;

                nomePess = arraySeringueirosComMeses.nomePessoa;
                setNomePessoa(nomePess);
                await carregaDadosSeringueiro(arraySeringueirosComMeses, true, nomePess);
            } else {
                const arrayFazenda = arrayProducaoCompleto.listRelatorioFazenda;
                const xxx = arrayFazenda.length > 0 ? arrayFazenda[0] : ARRAY_MESES_SAFRA_PRODUCAO;

                setNomePessoa('Todos');
                await carregaDadosSeringueiro(xxx, false, nomePess);
            }
        } catch (error) {
            setExtratoProducao(ARRAY_MESES_SAFRA_PRODUCAO[0].listMeses);
            console.log('Erro na classe AcompanhamentoDaProducao metodo changePessoa', error);
        }
    };

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await criarRelatorioExcel();
            setLoading(false);
        }, 1000);
    }

    const criarRelatorioExcel = async () => {

        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');
        const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
        const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');
        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const nomeUserLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');

        const json = {
            idUa: ua,
            idSafra: idSafra,
            dtInicio: dtInicioSafra,
            dtFim: dtFimSafra,
            nomeUsuario: nomeUserLogado,
            arrayMesDataFilter: arrayMesesAndData
        }

        const url = `producao/downloadRelatorioExcelAcompanhamentoProducao`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    const series = [
        {
            name: 'Previsto (kg)',
            type: 'column',
            data: listGraficoPrevisto
        },
        {
            name: 'Realizado (kg)',
            type: 'column',
            data: listGraficoRealizado
        },
        {
            name: '% Realizado',
            type: 'line',
            data: listGraficoEficiencia
        }
    ]

    const options = {
        chart: {
            height: 350,
            type: 'line',
            foreColor: 'black',
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
            },
            toolbar: {      // ESSE CARA MOSTRA OU OCULTA OS BOTOES DE DOLWNLOAD, ZOOM, HOME...
                show: false,    // OCULTA OU MOSTRA TODOS AS OPCOES
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        colors: ['#00004d', '#0099ff', '#ff0000'],
        stroke: {
            width: [1, 1, 0],       // ESSE CARA MOSTRA A LINHA DO GRAFICO
            curve: 'smooth'         // ARREDONDA ALINHA DO GRAFICO, OPCOES 'smooth', 'straight', 'stepline'
        },

        plotOptions: {
            bar: {
                enabled: false,
                dataLabels: {
                    enabled: false, // Define como false para ocultar as etiquetas de dados (colunas)
                }
            }
        },

        tooltip: {
            enabled: true,
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2],
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
            type: 'category',
            categories: ['SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO'],
            position: 'bottom',
            labels: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                style: {
                    colors: "#4e6097",
                    fontSize: "11px",
                    fontWeight: "500",
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        legend: {
            show: true,
            floating: false,
            fontSize: '15px',
            fontFamily: 'Helvetica, Arial',
            horizontalAlign: 'center',
            fontWeight: 100,
            offsetX: 0,
            offsetY: 10,
            height: 40,
        },
        yaxis: [
            {
                show: false,
            },
            {
                show: false,
                seriesName: 'Previsto (kg)',
            },
            {
                show: true,
                seriesName: '% Realizado',
            },
        ],
        fill: {
            type: "gradient",
            colors: ['#00004d', '#0099ff', '#ff0000'],
        },
    }

    function renderAcompanhamentoDaProducao() {
        const sizeF = '13px';
        const sizeSubTotal = '15px';
        const espacoW = 0;
        const largTr = 0;
        let corLinha = false;
        const largX = expandedColunas ? '12%' : '16%';
        const largDinamica = '12%';
        return (
            <Box mb={'30px'} mt={'-10px'}>
                <Flex h={'25px'} justify='end' align='center'>
                    {expandedColunas && (
                        <Flex h={'25px'} px={'10px'} bg={'black'} justify='center' align='center' borderTopRadius={'5px'}>
                            <Text color={'white'} fontSize={'14px'} mb={marginText} fontWeight={'light'} lineHeight={'100%'} align={'center'}>{'Baseado nas sangrias Possíveis Realizadas'}</Text>
                        </Flex>
                    )}
                </Flex>
                <Box position={'relative'}>
                    <Box position={'absolute'} top={'0px'} right={'1px'}>
                        <Flex justify='center' align='center' py={'0px'} px={'0px'} borderRadius={'5px'}>
                            <Box
                                width="35px"
                                height="35px"
                                bg="blackAlpha.900"
                                color="white"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                fontWeight="bold"
                                //animation="blink 1s ease-in-out 1" // 1s de duração, 2 repetições
                                _hover={{ cursor: 'pointer' }}
                            >
                                <Icon as={expandedColunas ? TiArrowRightThick : TiArrowLeftThick} width='20px' height='20px' color={'white'} cursor={'pointer'} onClick={() => { setExpandedColunas(!expandedColunas) }} />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box>
                    <TableContainer>
                        <Table size='sm' variant='unstyled' >
                            <Thead bg={colorFooterAndHeader}>
                                <Tr bgGradient={gradientHeader} h={'35px'}>
                                    <Th w={largX} sx={{ paddingX: espacoW, paddingY: largTr }} >
                                        <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'start'} mb={marginText} textTransform={'none'}>{'Mês'}</Text>
                                    </Th>
                                    <Th w={largX} sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'Árvores Sangradas'}</Text>
                                    </Th>
                                    <Th w={largX} sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'Distrib'}</Text>
                                    </Th>
                                    <Th w={largX} sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'Previsto (kg)'}</Text>
                                    </Th>
                                    <Th w={largX} sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'Realizado (kg)'}</Text>
                                    </Th>
                                    <Th w={largX} sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'%'}</Text>
                                    </Th>
                                    {expandedColunas && (
                                        <Th w={largDinamica} sx={{ paddingX: espacoW, paddingY: largTr }} bgGradient={gradientFocus}>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'Reprevisão (kg)'}</Text>
                                        </Th>
                                    )}
                                    {expandedColunas && (
                                        <Th w={largDinamica} sx={{ paddingX: espacoW, paddingY: largTr }} bgGradient={gradientFocus}>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText} textTransform={'none'}>{'%'}</Text>
                                        </Th>
                                    )}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {extratoProducao.map((item, key) => {
                                    corLinha = !corLinha;
                                    const mesdesc = item.mes.substring(0, 3);
                                    const ttParcial = item.totalAteMesAnterior;
                                    const colorBack = pessoaSelecionado == '' ? 'transparent' : 'transparent';
                                    const linhaH = ttParcial != null ? '60px' : '30px';
                                    return (
                                        <Tr h={linhaH} key={key + 1} alignItems={'center'} justifyContent={'start'} bg={corLinha ? 'rgba(217, 217, 217, 0.9)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'}>
                                            <Td sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                                <Box position={'relative'} w={'100%'}>
                                                    <Text mb={marginText} ml={'10px'} color={textColor} fontSize={sizeF} fontWeight='700' lineHeight={'100%'}>{mesdesc}</Text>
                                                    {item.listSeringueiroDeletado.length > 0 &&
                                                        <Box position={'absolute'} top={'-5px'} left={'50px'}>
                                                            <TooltipSeringueiro
                                                                label={
                                                                    <Box py={'5px'}>
                                                                        <Text align={'center'}>{'Seringueiro Desativado'}</Text>
                                                                        {item.listSeringueiroDeletado.map((x, y) => {
                                                                            const dtDesativado = x.dtDeletado != null ? moment(x.dtDeletado).format('DD/MM/YY') : '';
                                                                            return (
                                                                                <Box key={y} px={'5px'} pb={'5px'}>
                                                                                    <Text align={'start'} mt={'10px'} lineHeight='100%'>{`${x.nomeSeringueiroDeletado} - desativado em: ${dtDesativado}`}</Text>
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                }
                                                                height={'22px'} color={'red'} />
                                                        </Box>
                                                    }
                                                    {item.listSeringueiroIncluido.length > 0 &&
                                                        <Box position={'absolute'} top={'-5px'} left={'100px'}>
                                                            <TooltipSeringueiro
                                                                label={
                                                                    <Box py={'5px'}>
                                                                        <Text align={'center'}>{'Seringueiro Incluido'}</Text>
                                                                        {item.listSeringueiroIncluido.map((x, y) => {
                                                                            const dtCadastro = x.dtCadastro != null ? moment(x.dtCadastro).format('DD/MM/YY') : '';
                                                                            return (
                                                                                <Box key={y} px={'5px'}>
                                                                                    <Text align={'start'} mt={'10px'} lineHeight='100%'>{`${x.nomeSeringueiroIncluido} - iniciou a programação em: ${dtCadastro}`}</Text>
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                }
                                                                height={'22px'} color={'green'} />
                                                        </Box>
                                                    }
                                                </Box>
                                                {ttParcial != null &&
                                                    <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{'Total Parcial'}</Text>
                                                    </Flex>
                                                }
                                            </Td>
                                            <Td sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.arvoresSangradas}</Text>
                                                {ttParcial != null &&
                                                    <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalArvoresSangradasAteMesAnterior}</Text>
                                                    </Flex>
                                                }
                                            </Td>
                                            <Td sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.distribuicao}%`}</Text>
                                                {ttParcial != null &&
                                                    <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{`${ttParcial.totalDistribuicaoAteMesAnterior}%`}</Text>
                                                    </Flex>
                                                }
                                            </Td>
                                            <Td sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.previsto}</Text>
                                                {ttParcial != null &&
                                                    <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalPrevistoAteMesAnterior}</Text>
                                                    </Flex>
                                                }
                                            </Td>
                                            <Td sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.realizado}</Text>
                                                {ttParcial != null &&
                                                    <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalRealizadoAteMesAnterior}</Text>
                                                    </Flex>
                                                }
                                            </Td>
                                            <Td sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.percentual}%`}</Text>
                                                {ttParcial != null &&
                                                    <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{`${ttParcial.totalPercentualAteMesAnterior}%`}</Text>
                                                    </Flex>
                                                }
                                            </Td>
                                            {expandedColunas && (
                                                <Td sx={{ paddingX: espacoW, paddingY: largTr }} bgGradient={gradientPercent}>
                                                    <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.previsaoKg}</Text>
                                                    {ttParcial != null &&
                                                        <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalPrevisaoKgMesAnterior}</Text>
                                                        </Flex>
                                                    }
                                                </Td>
                                            )}
                                            {expandedColunas && (
                                                <Td sx={{ paddingX: espacoW, paddingY: largTr }} bgGradient={gradientPercent}>
                                                    <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.percentualPrevisaoKg}%`}</Text>
                                                    {ttParcial != null &&
                                                        <Flex w={'100%'} mb={'-10px'} mt={'5px'} h={'30px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                            <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{`${ttParcial.totalPercentualPrevisaoKgMesAnterior}%`}</Text>
                                                        </Flex>
                                                    }
                                                </Td>
                                            )}
                                        </Tr>
                                    )
                                })}
                                <Tr h={'30px'} bgGradient={gradientHeader}>
                                    <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} fontWeight='700' mb={marginText}>{'Total árvores em sangria'}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Flex justify='center' align='center'>
                                            <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalArvoresSangradas}</Text>
                                            <Center py={'0px'} justifyContent={'center'} alignItems={'center'} ml={'5px'}>
                                                <TooltipIcon
                                                    label={
                                                        <Box py={'5px'} pb={'10px'}>
                                                            <Text px={'10px'} align={'center'} lineHeight={'100%'}>{'Total árvores sangradas'}</Text>
                                                            <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Cálculo:'}</Text>
                                                            <Text align={'start'} mt={'5px'} ml={'10px'} lineHeight={'100%'}>{'SOMA DOS MESES / 12'}</Text>
                                                        </Box>
                                                    }
                                                    height={'18px'} color={'white'} />
                                            </Center>
                                        </Flex>
                                    </Td>
                                    <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalDistribuicao}%`}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalPrevisto}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalRealizado}</Text>
                                    </Td>
                                    <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                        <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalPercentual}%`}</Text>
                                    </Td>
                                    {expandedColunas && (
                                        <Td sx={{ paddingX: espacoW, paddingY: largTr }} bgGradient={gradientFocus}>
                                            <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalPrevisaoKg}</Text>
                                        </Td>
                                    )}
                                    {expandedColunas && (
                                        <Td sx={{ paddingX: espacoW, paddingY: largTr }} bgGradient={gradientFocus}>
                                            <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalPercentualPrevisaoKg}%`}</Text>
                                        </Td>
                                    )}
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' py={'1'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card direction='column' overflowX={{ sm: "scroll", lg: "hidden" }} w='100%' pt='0px' px='0px' borderRadius={'10px'}>
            <Flex px='5px' justify='space-between' mb='2px' align='center'>
                <Box w='100%' >
                    <Flex px='25px' justify='space-between' mb='2px' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>Seringueiros</Text>
                    </Flex>
                    <Select
                        value={pessoaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="Todos"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={changePessoa}
                        h={'35px'}
                        w={'400px'}
                        ml={'15px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Box>
                <Box display={'flex'} w='100%' justifyContent={'flex-end'} alignItems={'center'} >
                    <Flex px='2px' justify='flex-end' mb='3px' align='center' >
                        <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} mt={'-10px'} >
                            <Image boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/excel_icon_download.png`} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                        </FormLabel>
                        <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} mt={'-10px'} >
                            <Image boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' onClick={downloadPdf} />
                        </FormLabel>
                    </Flex>
                </Box>
            </Flex>
            <Box ref={pdfRef} mt={'10px'} w='100%'>
                <Box display={'flex'} w='100%' mb={'10px'} alignItems={'center'} justifyContent={'center'}>
                    <Text color={textColor} fontSize={'18px'} fontWeight={'bold'}>Acompanhamento De Produção</Text>
                </Box>
                <Flex px='20px' justify='flex-start' mb='3px' align='center'>
                    <Text color={textColor} fontSize={'15px'} fontWeight={'light'} lineHeight={'100%'}>Propriedade:</Text>
                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} ml={'10px'} lineHeight={'100%'}>{nomeFazenda}</Text>
                </Flex>
                <Flex px='20px' justify='flex-start' mb='3px' align='center' >
                    <Text color={textColor} fontSize={'15px'} fontWeight={'light'} lineHeight={'100%'}>Safra:</Text>
                    <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} ml={'10px'} lineHeight={'100%'}>{nomeSafra}</Text>
                </Flex>
                <Flex px='20px' justify='flex-start' mb='3px' align='center'>
                    <Text color={textColor} fontSize={'15px'} fontWeight={'light'} lineHeight={'100%'}>Seringueiro:</Text>
                    <Box ml={'10px'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{nomePessoa}</Text>
                    </Box>
                </Flex>
                <Box w='100%' px={'2'}>
                    {renderAcompanhamentoDaProducao()}
                    <Box h='380px' mt='30px' bg={'white'} borderWidth={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.400'}>
                        <ReactApexChart options={options} series={series} type="line" height={350} width={'100%'} />
                    </Box>
                </Box>
            </Box>
            <LoaderBlack isOpen={loading} />
        </Card>
    );
}
