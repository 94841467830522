import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Box,
    Center,
    Button,
    CircularProgress,
    Image,
    Badge,
    Wrap,
    WrapItem,
    Input,
    Stack,
    Link,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import LoaderBlack from 'components/loading/LoaderBlack';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { CloseIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { useHistory } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { mascaraTelefone, mascaraCpf } from '../../../../util/Masck';
import { SALVAR_URL } from '../../../../util/SalvarImagem';
import { VALIDAR_AUTENTICACAO_USUARIO } from '../../../../util/ValidarAutenticacao';
import FormData from 'form-data';
import imageNotFound from 'assets/img/dashboards/image_not_found.png';
import PdfIconDownload from 'assets/img/layout/pdf_icon_download.png';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';

export default function HistoricoSeringueiro() {

    const pdfRef = useRef();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [marginText, setMarginText] = useState('0px');
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [fileInfo, setFileInfo] = useState({ name: '', size: 0 });
    const [arrayFiles, setArrayFiles] = useState([]);
    const [messageArryUpload, setMessageArryUpload] = useState('');
    const [isValidMaxSizeFile, setIsValidMaxSizeFile] = useState(false);
    const [Nome, setNome] = useState('');
    const [CPF, setCPF] = useState('');
    const [RG, setRG] = useState('');
    const [CNPJ, setCNPJ] = useState('');
    const [ie, setIe] = useState('');
    const [celular, setCelular] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [urlImagemPerfil, setUrlImagemPerfil] = useState('');
    const [listUrlArquivo, setListUrlArquivo] = useState([]);
    const [listHistoricoQualidade, setListHistoricoQualidade] = useState([]);
    const [listHistoricoProdutividade, setListHistoricoProdutividade] = useState([]);
    const [listHistoricoFrequencia, setListHistoricoFrequencia] = useState([]);
    const [listHistoricoLocalidade, setListHistoricoLocalidade] = useState([]);
    const [idPessoaP, setIdPessoaP] = useState(null);
    const [valueProgressBar, setValueProgressBar] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [nomeRelatorio, setNomeRelatorio] = useState('Historico-seringueiro');
    const [arrayFilesQualidade, setArrayFilesQualidade] = useState([]);
    const [seringueirodeletado, setSeringueirodeletado] = useState(false);
    const [dtDeletado, setDtDeletado] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const mButt = '5px';

    useEffect(() => {
        async function loadScreen() {
            try {
                window.scrollTo(0, 0);
                await VALIDAR_AUTENTICACAO_USUARIO();

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Historico-seringueiro-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);

                const dados = localStorage.getItem('@HE-datosParaExtratoSeringueiro');
                const param = JSON.parse(dados);
                const idPes = param.idPessoa;
                setIdPessoaP(idPes);

                await carregarImagesRelatorio();
                await pesquisar(idPes);

                setTelaCarregada(true);
            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loadScreen();
    }, []);

    const pesquisar = async (idPes) => {
        try {
            const idSafra = localStorage.getItem('@HE-idSafra');
            const response = await Api.getRequest(`api/pessoa/historicoPessoaPorAno?idPessoa=${idPes}&idSafra=${idSafra}`);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                setSeringueirodeletado(res.deletado != null ? res.deletado : false);
                setDtDeletado(res.dtDeletado != null ? moment(res.dtDeletado).format('DD/MM/YYYY') : '');

                setNome(res.nome);
                setCPF(res.cpf != null ? mascaraCpf(res.cpf) : '');
                setRG('');
                setCNPJ('');
                setIe('');
                setTelefone(res.telefoneFixo != null && res.telefoneFixo != '' ? mascaraTelefone(res.telefoneFixo) : '');
                setCelular(res.celular != null && res.celular != '' ? mascaraTelefone(res.celular) : '');
                setEmail('');
                setUrlImagemPerfil(res.urlImagemPerfil != null ? res.urlImagemPerfil : '');

                if (res.listUrlArquivo.length > 0) {
                    let arr = res.listUrlArquivo.map(item => {
                        const urlX = item.urlStorage;

                        const nmImage = urlX.substring(urlX.lastIndexOf('/') + 1);      // PEGANDO NOME DA IMAGEM
                        const dtCriacao = item.dtCriacao != null ? moment(item.dtCriacao).format('DD/MM/YYYY') : '';
                        return {
                            url: item.urlStorage,
                            dtCriacao: dtCriacao,
                            nomeImage: nmImage,
                        }
                    })
                    setListUrlArquivo(arr);
                }
                await carregaQualidade(res.listHistoricoQualiadeSangria);
                await carregaHistoricoProdutividade(res.listHistoricoProdutividadeSangria);
                await carregaFrequencia(res.listHistoricoFrequenciaSangria);

                setListHistoricoLocalidade(res.listHistoricoLocalidade);

            } else {
                return;
            }
        } catch (error) {
            console.log('Erro na classe ExtratoSeringueiro metodo pesquisar', error);
        }
    }

    const carregarImagesRelatorio = async () => {
        try {
            let obj = [];
            const imageUrl = `${ActionTypes.LINK_GOOGLE_STORAGE}/image_profile_default.png`;
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const file = new File([blob], 'imageFileName.jpg', { type: 'image/jpeg' });

            if (file) {
                const nomeFile = file.name;
                const readerProduca = new FileReader();
                readerProduca.onloadend = () => {
                    const base64String = readerProduca.result;
                    const objFile = {
                        nomeFile: nomeFile,
                        fileBase64: base64String
                    }
                    if (obj.length > 0) {
                        const newArray = [objFile];
                        const arr = [...obj, ...newArray];
                        setArrayFilesQualidade(arr);
                    } else {
                        const newArray = [objFile];
                        setArrayFilesQualidade(newArray);
                    }
                    obj.push(objFile);
                };
                readerProduca.readAsDataURL(file);
            }
        } catch (error) {
            console.log('Erro na classe HistoricoSeringueiro metodo carregarImagesRelatorio', error);
        }
    }

    const carregaQualidade = async (arr) => {
        let arrayPess = [];
        await new Promise(resolve => {
            resolve(
                arr.map(function (item) {

                    const qualidadeJan = item.qualidadeJan != null && item.qualidadeJan != '0,0' ? item.qualidadeJan : '-';
                    const qualidadeFev = item.qualidadeFev != null && item.qualidadeFev != '0,0' ? item.qualidadeFev : '-';
                    const qualidadeMar = item.qualidadeMar != null && item.qualidadeMar != '0,0' ? item.qualidadeMar : '-';
                    const qualidadeAbr = item.qualidadeAbr != null && item.qualidadeAbr != '0,0' ? item.qualidadeAbr : '-';
                    const qualidadeMai = item.qualidadeMai != null && item.qualidadeMai != '0,0' ? item.qualidadeMai : '-';
                    const qualidadeJun = item.qualidadeJun != null && item.qualidadeJun != '0,0' ? item.qualidadeJun : '-';
                    const qualidadeJul = item.qualidadeJul != null && item.qualidadeJul != '0,0' ? item.qualidadeJul : '-';
                    const qualidadeAgo = item.qualidadeAgo != null && item.qualidadeAgo != '0,0' ? item.qualidadeAgo : '-';
                    const qualidadeSet = item.qualidadeSet != null && item.qualidadeSet != '0,0' ? item.qualidadeSet : '-';
                    const qualidadeOut = item.qualidadeOut != null && item.qualidadeOut != '0,0' ? item.qualidadeOut : '-';
                    const qualidadeNov = item.qualidadeNov != null && item.qualidadeNov != '0,0' ? item.qualidadeNov : '-';
                    const qualidadeDez = item.qualidadeDez != null && item.qualidadeDez != '0,0' ? item.qualidadeDez : '-';
                    const qualidadeAno = item.qualidadeAno != null && item.qualidadeAno != '0,0' ? item.qualidadeAno : '-';

                    const obj = {
                        ano: item.ano,
                        qualidadeJan: qualidadeJan,
                        qualidadeFev: qualidadeFev,
                        qualidadeMar: qualidadeMar,
                        qualidadeAbr: qualidadeAbr,
                        qualidadeMai: qualidadeMai,
                        qualidadeJun: qualidadeJun,
                        qualidadeJul: qualidadeJul,
                        qualidadeAgo: qualidadeAgo,
                        qualidadeSet: qualidadeSet,
                        qualidadeOut: qualidadeOut,
                        qualidadeNov: qualidadeNov,
                        qualidadeDez: qualidadeDez,
                        qualidadeAno: qualidadeAno,
                    }
                    arrayPess.push(obj);
                })
            )
            setListHistoricoQualidade(arrayPess);
        });

    }
    const carregaHistoricoProdutividade = async (arr) => {
        let arrayPess = [];
        await new Promise(resolve => {
            resolve(
                arr.map(function (item) {

                    const produtividadeJan = item.produtividadeJan != null && item.produtividadeJan != 0 ? `${item.produtividadeJan}%` : '-';
                    const produtividadeFev = item.produtividadeFev != null && item.produtividadeFev != 0 ? `${item.produtividadeFev}%` : '-';
                    const produtividadeMar = item.produtividadeMar != null && item.produtividadeMar != 0 ? `${item.produtividadeMar}%` : '-';
                    const produtividadeAbr = item.produtividadeAbr != null && item.produtividadeAbr != 0 ? `${item.produtividadeAbr}%` : '-';
                    const produtividadeMai = item.produtividadeMai != null && item.produtividadeMai != 0 ? `${item.produtividadeMai}%` : '-';
                    const produtividadeJun = item.produtividadeJun != null && item.produtividadeJun != 0 ? `${item.produtividadeJun}%` : '-';
                    const produtividadeJul = item.produtividadeJul != null && item.produtividadeJul != 0 ? `${item.produtividadeJul}%` : '-';
                    const produtividadeAgo = item.produtividadeAgo != null && item.produtividadeAgo != 0 ? `${item.produtividadeAgo}%` : '-';
                    const produtividadeSet = item.produtividadeSet != null && item.produtividadeSet != 0 ? `${item.produtividadeSet}%` : '-';
                    const produtividadeOut = item.produtividadeOut != null && item.produtividadeOut != 0 ? `${item.produtividadeOut}%` : '-';
                    const produtividadeNov = item.produtividadeNov != null && item.produtividadeNov != 0 ? `${item.produtividadeNov}%` : '-';
                    const produtividadeDez = item.produtividadeDez != null && item.produtividadeDez != 0 ? `${item.produtividadeDez}%` : '-';
                    const produtividadeAno = item.produtividadeAno != null && item.produtividadeAno != 0 ? `${item.produtividadeAno}%` : '-';

                    const obj = {
                        ano: item.ano,
                        produtividadeJan: produtividadeJan,
                        produtividadeFev: produtividadeFev,
                        produtividadeMar: produtividadeMar,
                        produtividadeAbr: produtividadeAbr,
                        produtividadeMai: produtividadeMai,
                        produtividadeJun: produtividadeJun,
                        produtividadeJul: produtividadeJul,
                        produtividadeAgo: produtividadeAgo,
                        produtividadeSet: produtividadeSet,
                        produtividadeOut: produtividadeOut,
                        produtividadeNov: produtividadeNov,
                        produtividadeDez: produtividadeDez,
                        produtividadeAno: produtividadeAno,
                    }
                    arrayPess.push(obj);
                })
            )
            setListHistoricoProdutividade(arrayPess);
        });
    }
    const carregaFrequencia = async (arr) => {
        let arrayPess = [];
        await new Promise(resolve => {
            resolve(
                arr.map(function (item) {

                    const frequenciaJan = item.frequenciaJan != null && item.frequenciaJan != 0 ? `${item.frequenciaJan}%` : '-';
                    const frequenciaFev = item.frequenciaFev != null && item.frequenciaFev != 0 ? `${item.frequenciaFev}%` : '-';
                    const frequenciaMar = item.frequenciaMar != null && item.frequenciaMar != 0 ? `${item.frequenciaMar}%` : '-';
                    const frequenciaAbr = item.frequenciaAbr != null && item.frequenciaAbr != 0 ? `${item.frequenciaAbr}%` : '-';
                    const frequenciaMai = item.frequenciaMai != null && item.frequenciaMai != 0 ? `${item.frequenciaMai}%` : '-';
                    const frequenciaJun = item.frequenciaJun != null && item.frequenciaJun != 0 ? `${item.frequenciaJun}%` : '-';
                    const frequenciaJul = item.frequenciaJul != null && item.frequenciaJul != 0 ? `${item.frequenciaJul}%` : '-';
                    const frequenciaAgo = item.frequenciaAgo != null && item.frequenciaAgo != 0 ? `${item.frequenciaAgo}%` : '-';
                    const frequenciaSet = item.frequenciaSet != null && item.frequenciaSet != 0 ? `${item.frequenciaSet}%` : '-';
                    const frequenciaOut = item.frequenciaOut != null && item.frequenciaOut != 0 ? `${item.frequenciaOut}%` : '-';
                    const frequenciaNov = item.frequenciaNov != null && item.frequenciaNov != 0 ? `${item.frequenciaNov}%` : '-';
                    const frequenciaDez = item.frequenciaDez != null && item.frequenciaDez != 0 ? `${item.frequenciaDez}%` : '-';
                    const frequenciaAno = item.frequenciaAno != null && item.frequenciaAno != 0 ? `${item.frequenciaAno}%` : '-';

                    const obj = {
                        ano: item.ano,
                        frequenciaJan: frequenciaJan,
                        frequenciaFev: frequenciaFev,
                        frequenciaMar: frequenciaMar,
                        frequenciaAbr: frequenciaAbr,
                        frequenciaMai: frequenciaMai,
                        frequenciaJun: frequenciaJun,
                        frequenciaJul: frequenciaJul,
                        frequenciaAgo: frequenciaAgo,
                        frequenciaSet: frequenciaSet,
                        frequenciaOut: frequenciaOut,
                        frequenciaNov: frequenciaNov,
                        frequenciaDez: frequenciaDez,
                        frequenciaAno: frequenciaAno,
                    }
                    arrayPess.push(obj);
                })
            )
            setListHistoricoFrequencia(arrayPess);
        });
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await criarDownloadPdf();
            setMarginText('0px');
            setLoading(false);
        }, 1000);
    }

    const criarDownloadPdf = async () => {
        const input = pdfRef.current;

        await html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setIsValidMaxSizeFile(false);

        if (file) {
            const fileSizeInMB = await file.size / (1024 * 1024); // CONVERT FILE SIZE TO MB
            setFileInfo({ name: file.name, size: fileSizeInMB });

            if (fileSizeInMB < 20) {                               // VERIFY FILE SIZE IS LESS THAN 20 MB

                const verifyExist = arrayFiles.filter(x => x.name == file.name && x.size == file.size);     // CHECK IF THEN FILE EXISTS
                if (verifyExist.length > 0) {
                    setMessageArryUpload('O arquivo já foi adicionado à lista de upload');
                    setIsValidMaxSizeFile(true);
                    return;
                }

                if (arrayFiles.length > 0) {

                    const newArray = [file];
                    const arr = [...arrayFiles, ...newArray];

                    setArrayFiles(arr);
                } else {
                    const newArray = [file];
                    setArrayFiles(newArray);
                }
            } else {
                setIsValidMaxSizeFile(true);
                setMessageArryUpload('O arquivo selecionado é maior que 20 MB');
            }
        } else {
            setFileInfo({ name: '', size: 0 });
        }
    };

    const deleteFileToUpload = (item) => {
        const array = arrayFiles.filter(x => x.name != item.name && x.size != item.size);
        setArrayFiles(array);

        if (array.length == 0) {
            setFileInfo({ name: '', size: 0 });
        }
    }

    const salvarImagens = async () => {
        try {
            setShowProgress(true);
            const res = await UPLOAD_FILE_EXTRATO_SERINGUEIRO(arrayFiles, idPessoaP);

            setFileInfo({ name: '', size: 0 });
            setArrayFiles([]);
            await pesquisar(idPessoaP);

            setShowProgress(false);
        } catch (error) {
            setShowProgress(false);
            console.log('Erro na classe ExtratoSeringueiro metodo salvarImagens');
        }
    }

    async function UPLOAD_FILE_EXTRATO_SERINGUEIRO(arrayFiles, idPessoa) {
        let arr = [];
        await Promise.all(
            arrayFiles.map(async (val) => {
                const response = await UPLOAD_FILE_AND_URL(val, idPessoa);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = response.respostaRequisicao.data;
                    arr.push(res);
                }
            })
        )
        return arr.length;
    }

    async function UPLOAD_FILE_AND_URL(img, idPessoa) {
        let result = null;
        try {
            const numeroParaImagem = ActionTypes.ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO;
            const tipoArquivo = 'image/png';
            const response = await UPLOAD_FILE(img);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const responseUrl = await SALVAR_URL(idPessoa, response, numeroParaImagem, tipoArquivo);
                result = responseUrl;
            }
        } catch (error) {
            console.log('Erro na classe UploadFile metodo UPLOAD_FILE_AND_URL ', error);
        }
        return result;
    }

    async function UPLOAD_FILE(img) {
        let result = null;
        try {
            const formData = new FormData()
            formData.append('file', img);
            formData.append('bucketName', 'hevea_bucket');
            formData.append('folderName', 'user_uploads');

            const response = await Api.UPLOAD_FILE_GOOGLE_STORAGE_SEM_TOKEM(`filesGoogleStorage/userUploadDiversosTiposDeArquivo`, formData);
            result = response;

        } catch (error) {
            console.log('Erro na classe UploadFile metodo uploadImageEvidencia ', error);
            return null;
        }
        return result;
    }

    function renderCartdImage() {
        const fontSiz = '13px';
        return (
            <Box p={'3'} >
                <Flex h={'100px'} px='2px' justify='center' align='center'>
                    <Flex h={'100%'} justify='center' align='start'>
                        {arrayFilesQualidade.length > 0 &&
                            <Image w={'150px'} h={'120px'} src={arrayFilesQualidade[0].fileBase64} alt={'alt'} />
                        }
                    </Flex>
                    <Flex flexDirection={'column'} h={'100%'} w={'100%'} px='5px' justify='space-between' align='start'>
                        <Flex mb={'5px'} w={'100%'} justify='start' align='center'>
                            <Text color={textColor} fontSize={fontSiz} fontWeight='normal' >Nome:  </Text>
                            <Text color={textColor} fontSize={fontSiz} fontWeight='bold' ml={'4'}>{Nome}</Text>
                        </Flex>
                        <Flex mb={'5px'} w={'100%'} justify='start' align='flex-start'>
                            <Text color={textColor} fontSize={fontSiz} fontWeight='normal' >CPF:  </Text>
                            <Text color={textColor} fontSize={fontSiz} fontWeight='bold' ml={'4'}>{CPF}</Text>
                        </Flex>
                        <Flex mb={'0px'} w={'100%'} justify='start' align='flex-start'>
                            <Text color={textColor} fontSize={fontSiz} fontWeight='normal' >Telefone:  </Text>
                            <Text color={textColor} fontSize={fontSiz} fontWeight='bold' ml={'4'}>{celular}</Text>
                        </Flex>
                        <Flex mb={'0px'} w={'100%'} justify='start' align='flex-start' mt={'5px'}>
                            <Text mb={marginText} color={textColor} fontSize={fontSiz} fontWeight='normal' >E-mail:  </Text>
                            <Text mb={marginText} color={textColor} fontSize={fontSiz} fontWeight='bold' ml={'4'}>{email}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderHistoricoQualidadeSangria() {
        return (
            <TableContainer pb={'5px'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'orange.400'}>
                <Table size='sm' variant='unstyled'>
                    <Thead px={'3px'}>
                        <Tr h={'35px'} bgGradient={gradientHeader}>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'}>Ano</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jan'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Fev'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Mar'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Abri'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Mai'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jun'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jul'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Ago'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Set'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Out'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Nov'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Dez'}</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Média'}</Text>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listHistoricoQualidade.map((item, key) => {
                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700'>{item.ano}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeJan}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeFev}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeMar}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeAbr}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeMai}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeJun}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeJul}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeAgo}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeSet}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeOut}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeNov}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeDez}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qualidadeAno}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderHistoricoProdutividade() {
        return (
            <TableContainer pb={'5px'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'orange.400'}>
                <Table size='sm' variant='unstyled' >
                    <Thead px={'3px'}>
                        <Tr h={'35px'} bgGradient={gradientHeader}>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Text align={'start'} color={textColorHeader} fontSize='14px' >Ano</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jan'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Fev'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Mar'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Abri'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Mai'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jun'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jul'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Ago'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Set'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Out'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Nov'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Dez'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Média'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listHistoricoProdutividade.map((item, key) => {

                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.ano}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeJan}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeFev}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeMar}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeAbr}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeMai}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeJun}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeJul}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeAgo}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeSet}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeOut}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeNov}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeDez}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.produtividadeAno}`}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderHistoricoFrequencia() {
        return (
            <TableContainer pb={'5px'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'orange.400'}>
                <Table size='sm' variant='unstyled' >
                    <Thead px={'3px'}>
                        <Tr h={'35px'} bgGradient={gradientHeader}>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Text align={'start'} color={textColorHeader} fontSize='14px' >Ano</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jan'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Fev'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Mar'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Abri'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Mai'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jun'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Jul'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Ago'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Set'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Out'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Nov'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Dez'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >{'Média'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listHistoricoFrequencia.map((item, key) => {
                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700'>{item.ano}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaJan}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaFev}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaMar}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaAbr}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaMai}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaJun}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaJul}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaAgo}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaSet}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaOut}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaNov}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaDez}`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{`${item.frequenciaAno}`}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderHistoricoLocalidade() {
        return (
            <TableContainer pb={'5px'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'orange.400'}>
                <Table size='sm' variant='unstyled' >
                    <Thead px={'3px'}>
                        <Tr h={'35px'} bgGradient={gradientHeader}>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'} >Mês/Ano</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'}>Nome do Seringal</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'}>Municipio/UF</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'}>Árvores em Sangria</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize='14px' align={'center'}>Frequência</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listHistoricoLocalidade.map((item, key) => {
                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.mesAno}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.nomeFazenda}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.nomeMunicipio}/{item.idUf}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.qtdArvores}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize='13px' fontWeight='700' align={'center'}>{item.frequencia}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderDeletado() {
        return (
            <Box>
                {seringueirodeletado &&
                    <Flex justify='start' align='center'>
                        <Box bg={'red.600'} borderRadius={'10px'} px={'8px'} py={'3px'}>
                            <Text mb={marginText} color={'white'} fontSize='14px' fontWeight={'bold'}>{`Desativado em: ${dtDeletado}`}</Text>
                        </Box>
                    </Flex>
                }
            </Box>
        )
    }
    function renderAnexo() {
        return (
            <Box px={'10px'} mx={'10px'} mt={'5px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.300'}>
                <Box mt={'10px'}>
                    <Stack spacing={0} >
                        {isValidMaxSizeFile &&
                            <Text color={'red.500'} fontSize='14px' fontWeight='700' ml={'10px'} mb={'10px'} >{messageArryUpload}</Text>
                        }
                        <Flex justify='space-between' align='center' >
                            <label htmlFor="fileUp">
                                <Badge
                                    cursor={'pointer'}
                                    opacity={seringueirodeletado ? 0.5 : 1}
                                    p={'1'}
                                    w={'180px'}
                                    h={'40px'}
                                    colorScheme='whiteAlpha'
                                    bg={'orange.600'}
                                    color={'white'}
                                    textAlign={'center'}
                                    variant="outline">
                                    <Center>
                                        <Text color={'white'} fontSize='15px' fontWeight='700' mt={'10px'} textTransform={'none'} >Selecione um Arquivo</Text>
                                    </Center>
                                </Badge>
                            </label>
                            <Button
                                rightIcon={<ArrowUpIcon fontSize={'25px'} />}
                                bg={'orange.600'}
                                color={'white'}
                                disabled={fileInfo.name ? false : true}
                                onClick={salvarImagens}
                                variant='solid'>
                                Salvar Arquivos
                            </Button>
                        </Flex>
                        <Input
                            id="fileUp"
                            type="file"
                            onChange={handleFileChange}
                            disabled={seringueirodeletado}
                            display={'none'}
                            accept=",image/*
                            ,video/*
                            ,audio/*
                            ,.xml
                            ,.pdf
                            ,.doc
                            ,.docx
                            ,.pptx
                            ,.xlsx
                            ,application/msword
                            ,application/vnd.openxmlformats-officedocument.wordprocessingml.document
                            ,application/vnd.ms-powerpoint
                            ,application/vnd.openxmlformats-officedocument.presentationml.slideshow
                            application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            placeholder='custom placeholder'
                            _placeholder={{ opacity: 1, color: 'gray.500' }}
                        />
                        <Box ml={'20px'} >
                            {arrayFiles.length > 0 &&
                                arrayFiles.map((item, index) => {
                                    return (
                                        <Box key={index}>
                                            <Flex justify='start' mb={'3'} align='flex-start' borderBottom={'1px'} borderColor={'blackAlpha.400'} >
                                                <Button
                                                    rightIcon={<CloseIcon fontSize={'10px'} fontWeight={'bold'} mr={'8px'} />}
                                                    bg={'orange.600'}
                                                    color={'white'}
                                                    size='xs'
                                                    w={'25px'}
                                                    disabled={fileInfo.name ? false : true}
                                                    onClick={() => { deleteFileToUpload(item) }}
                                                    variant='outline'>
                                                </Button>
                                                <Text color={textColor} fontSize='15px' fontWeight='100' ml={'4'}>{item.name}</Text>
                                                {showProgress && <CircularProgress ml={'20px'} value={valueProgressBar} size='25px' thickness='20px' />}
                                            </Flex>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Stack>
                </Box>
                <Text color={textColor} fontSize='16px' fontWeight='700' mt={'0'} ml={'5'}>Anexos:</Text>
                {listUrlArquivo.length > 0 ?
                    <Wrap align={'center'} justify={'space-between'} mt={'10px'}>
                        {listUrlArquivo.map((item, index) => {
                            return (
                                <WrapItem key={index} w={'48%'} bg={'blackAlpha.100'}>

                                    <Text color={textColor} fontSize='14px' fontWeight='100' py={'1'} ml={'4'}>
                                        <Link color='teal.500' href={`${item.url}`}>{`${item.dtCriacao} - ${item.nomeImage}`}</Link>
                                    </Text>
                                </WrapItem>
                            )
                        })}
                    </Wrap>
                    :
                    <Wrap align={'center'} justify={'space-between'} mt={'0px'}>
                        <WrapItem w={'48%'} justifyContent={'start'} alignSelf={'center'} >
                            <Image boxSize='80px' ml={'4'} objectFit='contain' src={imageNotFound} alt={'alt'} />
                        </WrapItem>
                    </Wrap>
                }
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
        <Card direction='column' w='100%' px='0px' py='10px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='25px' justify='space-between' align='center'>
                <Image boxSize='35px' objectFit='contain' src={PdfIconDownload} alt='Dan Abramov' cursor={'pointer'} onClick={downloadPdf} />
            </Flex>
            <Box ref={pdfRef} w='100%'>
                <Text color={textColor} fontSize='20px' fontWeight='700' lineHeight={'100%'} align={'center'}>Relatório Histórico Seringueiro</Text>
                {renderCartdImage()}
                <Card direction='column' w='100%' px='0px' py='0px'>
                    <Box mb={'10px'} >
                        <Center h={'100%'}>
                            <Text mb={marginText} color={textColor} fontSize='20px' fontWeight='700' >Histórico de Qualidade de Sangria (Pontuação)</Text>
                        </Center>
                        {renderHistoricoQualidadeSangria()}
                    </Box>
                    <Box mb={'10px'}>
                        <Center h={'100%'}>
                            <Text mb={marginText} color={textColor} fontSize='20px' fontWeight='700'>Histórico de Produtividade (% pela meta local)</Text>
                        </Center>
                        {renderHistoricoProdutividade()}
                    </Box>
                    <Box mb={'10px'}>
                        <Center h={'100%'}>
                            <Text mb={marginText} color={textColor} fontSize='20px' fontWeight='700'>Histórico de Frequência de Sangria (% pela meta de 100%)</Text>
                        </Center>
                        {renderHistoricoFrequencia()}
                    </Box>
                    <Box mb={'10px'}>
                        <Center h={'100%'}>
                            <Text mb={marginText} color={textColor} fontSize='20px' fontWeight='700'>Histórico de Localidade de Sangria</Text>
                        </Center>
                        {renderHistoricoLocalidade()}
                    </Box>
                    <Box mb={'10px'}>
                        {renderDeletado()}
                    </Box>
                </Card>
            </Box>
            <Text py={'5px'} color={textColor} fontSize='16px' fontWeight='700' align={'center'} >Arquivos</Text>
            {renderAnexo()}
            <Wrap spacing='30px' mx={'10px'} my={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
            <LoaderBlack isOpen={loading} />
        </Card>
        </Box>
    );
}
