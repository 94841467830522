import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Input,
    Wrap,
    WrapItem,
    Icon,
    Select,
    Center,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { RiAccountCircleFill, RiArrowRightSLine, RiArrowLeftSLine, RiArrowRightUpLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraTelefone } from '../../../../util/Masck';
import moment from 'moment';

const qtdPage = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

export default function ListaSeringueirosDisponiveis() {

    const history = useHistory();
    const [listaProdutos, setListaProdutos] = useState([]);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [idUa, setIdUa] = useState(null);
    const [idSafra, setIdSafra] = useState(null);

    const colorFooterAndHeader = useColorModeValue("orange.600", "black");
    const textColorHeader = useColorModeValue("white", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientOrange = 'linear-gradient(orange.700, orange.600, orange.500)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dados = localStorage.getItem('@HE-datosParaEdicaoSeringueiro');
            if (dados != null) {

                const dadosProdutor = JSON.parse(dados);
                const idUaX = dadosProdutor.idUa;
                const idSafraX = dadosProdutor.idSafra;
                const nomeFazendaX = dadosProdutor.nomeFazenda;

                setIdUa(idUaX);
                setIdSafra(idSafraX);
                setNomeFazenda(nomeFazendaX);

                await pesquisar(idUaX, idSafraX);
            }
        }
        loaderScreen();
    }, [])

    const pesquisar = async (idUaP, idSafraP) => {
        try {
            setListaProdutos([]);
            setCurrentPage(1);
            const url = `api/pessoa/listaTodasPessoasDeumaUa?idUa=${idUaP}&idSafra=${idSafraP}&disponivel=true&nome=${impuNomeSeringueiro}`;

            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                await carregaLista(res);
            }
        } catch (error) {
            console.log('');
        }
    };

    async function carregaLista(array) {
        if (array.length > 0) {
            let arrayPess = [];

            for (let i = 0; i < array.length; i++) {
                const item = array[i];

                const dtCadastro = item.dtCadastro != '' && item.dtCadastro != null ? moment(item.dtCadastro).format('DD/MM/YY') : '';
                const celular = item.celular != '' && item.celular != null ? await mascaraTelefone(item.celular) : '';

                const obj = {
                    ...item,
                    celular: celular,
                    dtCadastro: dtCadastro,
                }
                arrayPess.push(obj);
            }
            setListaProdutos(arrayPess);
        }
    }

    const totalPages = Math.ceil(listaProdutos.length / itemsPerPage);
    const paginatedData = listaProdutos.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const adicionarSeringuerio = (item) => {
        localStorage.setItem('@HE-retornoTelaSeringueiroDisponivel', JSON.stringify(item));
        history.goBack();
    }

    const cadastrarSeringueiros = () => {
        const json = {
            idUa: idUa,
            idSafra: idSafra,
            nomeFazenda: nomeFazenda,
            idPessoa: null,
            operacao: ActionTypes.OPERACAO_INSERT
        }
        localStorage.setItem('@HE-datosParaEdicaoSeringueiroCadastro', JSON.stringify(json));
        history.push("/admin/form-seringueiro-cadastro");
    }

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    function renderInfo() {
        return (
            <Box >
                <Flex px='25px' mb={'10px'} justify='start' align='center'>
                    <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>Propriedade:</Text>
                    <Text ml={'2'} color={'orange.700'} fontSize='17px' fontWeight='700' lineHeight='100%'>{nomeFazenda}</Text>
                </Flex>
                <Box mx={'20px'} borderRadius={'20px'} mb={'20px'} bgGradient={gradientOrange}>
                    <Flex px={'10px'} py={'10px'} justify={'center'} align="center">
                        <Box w={'80%'}>
                            <Text mb={'10px'} color={'white'} fontSize='22px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Seringueiros Disponíveis'}</Text>
                            <Text color={'gray.100'} fontSize='15px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Os seringueiros listados estão disponíveis para essa propriedadde.'}</Text>
                            <Text color={'gray.100'} fontSize='16px' fontWeight={'light'} align={'center'} lineHeight='100%'>{'Caso não encontre um nome específico na listagem, você pode cadastra-lo clicando em Novo'}</Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderFiltro() {
        return (
            <Box w={'100%'} mb={'20px'}>
                <Wrap spacing='10px' px={'30px'} mt={'1px'} justify='space-between'>
                    <WrapItem>
                        <Box>
                            <Input
                                value={impuNomeSeringueiro}
                                onChange={(e) => setImpuNomeSeringueiro(e.target.value)}
                                errorBorderColor='crimson'
                                variant="filled"
                                placeholder="Pesquisar por nome"
                                _placeholder={{ opacity: 0.5, color: 'inherit' }}
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'400px'}
                            />
                        </Box>
                        <Center ml={'5px'} justifyContent={'center'}>
                            <Button variant="miniBrand" onClick={() => { pesquisar(idUa, idSafra) }}>pesquisar</Button>
                        </Center>
                    </WrapItem>
                    <WrapItem>
                        <Box >
                            <Button variant="miniBrand" onClick={() => { cadastrarSeringueiros() }}>Novo</Button>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderSeringueiros() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} direction="column" align="center">
                <Table variant="striped" colorScheme='blackAlpha' size="sm" bg={'blackAlpha.100'}>
                    <Thead>
                        <Tr h={'35px'}>
                            <Th w={'40%'} textTransform={'none'} bg={colorFooterAndHeader} borderTopLeftRadius={'10px'}>
                                <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{'Nome'}</Text>
                            </Th>
                            <Th w={'25%'} textTransform={'none'} bg={colorFooterAndHeader}>
                                <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Dt cadastro'}</Text>
                            </Th>
                            <Th w={'25%'} textTransform={'none'} bg={colorFooterAndHeader}>
                                <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Telefone'}</Text>
                            </Th>
                            <Th w={'10%'} bg={colorFooterAndHeader} borderTopRightRadius={'10px'}>
                                <Text color={textColorHeader} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{''}</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginatedData.map((item, index) => {
                            const disponivel = item.disponivel == true ? true : false;
                            const colorIndicator = item.associadoEmSafraVigente == true ? 'green.500' : 'orange.500';

                            return (
                                <Tr key={index}>
                                    <Td>
                                        <Flex justify='start' mb='5px' align='center'>
                                            <Icon as={RiAccountCircleFill} color={colorIndicator} w={'25px'} h={'25px'} mr={'10px'} />
                                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{item.nome}</Text>
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <Flex justify='center' mb='5px' align='center'>
                                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.dtCadastro}</Text>
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <Flex justify='center' mb='5px' align='center'>
                                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{item.celular}</Text>
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <Flex justify={'center'} align='center'>
                                            <Button
                                                rightIcon={<RiArrowRightUpLine />}
                                                variant="action"
                                                key={index}
                                                isDisabled={!disponivel}
                                                onClick={() => { adicionarSeringuerio(item) }}>Adicionar</Button>
                                        </Flex>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
                <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                    <Flex justify='center' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                        <Box ml={'10px'}>
                            <Select
                                value={itemsPerPage}
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={'gray.200'}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                textColor={textColor}
                                size="sm"
                                h={'35px'}
                                onChange={changePage}>
                                {qtdPage.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaProdutos.length}`}</Text>
                        <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    function renderButton() {
        return (
            <Flex px='25px' justify='start' mt='20px' align='center'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Flex>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                {renderInfo()}
                {renderFiltro()}
                {renderSeringueiros()}
                {renderButton()}
            </Box>
        </Card>
    );
};
