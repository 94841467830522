import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Flex,
    Spinner,
    Text,
    Progress,
} from "@chakra-ui/react";

function LoaderProgressBar({ isOpen, titulo = 'Processando...', subTitulo = '', valueProgress = 1, maxValue = 1 }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay
                bg='none'
                backdropFilter='auto'
                backdropInvert='20%'
                backdropBlur='10px'
            />
            <ModalContent>
                <ModalBody>
                    <Flex 
                        h={subTitulo == '' ? '150px' : '180px'} 
                        flexDirection={'column'} 
                        my={'10px'} 
                        justify='space-between' 
                        align='center'>
                        <Text color={'blackAlpha.900'} fontSize='18px' fontWeight='bold' align={'center'} lineHeight='100%'>{titulo}</Text>
                        <Spinner thickness='5px' speed='0.65s' color='orange.400' size='lg' />
                        <Text py={'10px'} color={'blackAlpha.800'} fontSize='13px' fontWeight='bold' align={'center'} lineHeight='100%'>{`${valueProgress}/${maxValue}`}</Text>
                        <Progress w={'100%'} value={valueProgress} size="lg" colorScheme="blue" max={maxValue} bg={'blackAlpha.200'} />
                        <Text color={'blackAlpha.900'} fontSize='13px' fontWeight='bold' align={'center'} lineHeight='100%'>{subTitulo}</Text>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LoaderProgressBar;
