import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    Wrap,
    SimpleGrid,
    Checkbox,
    Progress,
    Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { IoAlertCircle } from "react-icons/io5";
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { retiraMascaraMaterNumeros } from '../../../../util/Masck';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function AvaliacaoModal() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);

    const [nomeSeringueiro, setnomeSeringueiro] = useState('');
    const [produtivBaixoP, setProdutivBaixoP] = useState(false);
    const [produtivMedioP, setProdutivMedioP] = useState(false);
    const [produtivAltoP, setProdutivAltoP] = useState(false);

    const [consumoBaixoP, setConsumoBaixoP] = useState(false);
    const [consumoMedioP, setConsumoMedioP] = useState(false);
    const [consumoAltoP, setConsumoAltoP] = useState(false);

    const [ataqueGeratrizesP, setAtaqueGeratrizesP] = useState(false);
    const [declividadeCorteP, setDeclividadeCorteP] = useState(false);
    const [conformidadeEquipamentosP, setConformidadeEquipamentosP] = useState(false);
    const [impurezasCoaguloP, setImpurezasCoaguloP] = useState(false);
    const [facaNaoAmoladaP, setFacaNaoAmoladaP] = useState(false);
    const [arvoreSangradaP, setArvoreSangradaP] = useState(false);
    const [controleFitossanitarioP, setControleFitossanitarioP] = useState(false);
    const [latexPerdaProducaoP, setLatexPerdaProducaoP] = useState(false);
    const [idQualidadeSangriaP, setIdQualidadeSangriaP] = useState(null);
    const [nrArvoreP, setNrArvoreP] = useState('');
    const [idPessoaP, setIdPessoaP] = useState('');
    const [idArvoreP, setIdArvoreP] = useState('');
    const [progressArvores, setProgressArvores] = useState(0);
    const [arvoreChecadaP, setArvoreChecadaP] = useState(false);
    const [imputFerimentoDesviadoP, setImputFerimentoDesviadoP] = useState('');
    const [imputFerimentoNaoDesviadoP, setImputFerimentoNaoDesviadoP] = useState('');
    const [isValidFeriDesviado, setisValidFeriDesviado] = useState(false);
    const [isValidFeriNaoDesviado, setisValidFeriNaoDesviado] = useState(false);
    const [descMesP, setDescMesP] = useState('');
    const [anoCorrente, setAnoCorrente] = useState('');

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const borderColorCheck = 'blackAlpha.700';
    const cardShadowCard = useColorModeValue('0px 2px 5px rgba(0, 0, 0, 0.5)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);
                const ano = moment().format('YYYY');
                const mes = moment().format('YYYY-MM-DD');
                const nomeMes = await retornaNomeDoMes(mes);
                setAnoCorrente(ano);
                setDescMesP(nomeMes);

                const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
                setSangriaAtiva(sangriaAti == 'true' ? true : false);    

                const dadosSeringueiro = localStorage.getItem('@HE-objSeringueiroParaTelaAvaliacao');
                if (dadosSeringueiro != null) {
                    const dados = JSON.parse(dadosSeringueiro);

                    setnomeSeringueiro(dados.nome);
                    setIdQualidadeSangriaP(dados.idQualidadeSangria);
                    setNrArvoreP(dados.numeroArvore);
                    setIdPessoaP(dados.idPessoa);

                    if (dados.objArvore != null) {
                        const avr = dados.objArvore;

                        setIdArvoreP(avr.idArvore);

                        setArvoreChecadaP(avr.arvoreChecada != null && avr.arvoreChecada != false ? true : false);
                        setArvoreSangradaP(avr.arvoreSangrada != null && avr.arvoreSangrada != '' ? true : false);
                        setAtaqueGeratrizesP(avr.ataqueGeratrizes != null && avr.ataqueGeratrizes != '' ? true : false);
                        setConformidadeEquipamentosP(avr.conformidadeEquipamentos != null && avr.conformidadeEquipamentos != '' ? true : false);
                        setControleFitossanitarioP(avr.controleFitossanitario != null && avr.controleFitossanitario != '' ? true : false);
                        setDeclividadeCorteP(avr.declividadeCorte != null && avr.declividadeCorte != '' ? true : false);
                        setFacaNaoAmoladaP(avr.facaNaoAmolada != null && avr.facaNaoAmolada != '' ? true : false);
                        setImpurezasCoaguloP(avr.impurezasCoagulo != null && avr.impurezasCoagulo != '' ? true : false);
                        setLatexPerdaProducaoP(avr.latexPerdaProducao != null && avr.latexPerdaProducao != '' ? true : false);
                        setConsumoAltoP(avr.consumoAlto != null && avr.consumoAlto != '' ? true : false);
                        setConsumoBaixoP(avr.consumoBaixo != null && avr.consumoBaixo != '' ? true : false);
                        setConsumoMedioP(avr.consumoMedio != null && avr.consumoMedio != '' ? true : false);
                        setProdutivAltoP(avr.profundidadeAlto != null && avr.profundidadeAlto != '' ? true : false);
                        setProdutivBaixoP(avr.profundidadeBaixo != null && avr.profundidadeBaixo != '' ? true : false);
                        setProdutivMedioP(avr.profundidadeMedio != null && avr.profundidadeMedio != '' ? true : false);
                        setImputFerimentoDesviadoP(avr.valorFerimentoDesviado != null && avr.valorFerimentoDesviado != '' ? avr.valorFerimentoDesviado : '');
                        setImputFerimentoNaoDesviadoP(avr.valorFerimentoNaoDesviado != null && avr.valorFerimentoNaoDesviado != '' ? avr.valorFerimentoNaoDesviado : '');
                    }
                    const xx = Number(dados.numeroArvore);
                    const qtdProgress = xx * 10;
                    setProgressArvores(qtdProgress);
                }
                setTelaCarregada(true);

            } catch (error) {
                console.log('Error na classe AvaliacaoModal metodo useEffect ', error);
            }
        }
        loaderScreen();
        return ()=> {
            setIsVisibleAlert(false);
            console.log('Saiu da tela de AvaliacaoModal');
        }
    }, []);

    const salvarAvaliacao = async () => {
        try {
            let valid = true;
            const idUaP = localStorage.getItem('@HE-idUa');

            if (imputFerimentoDesviadoP.length > 0) {
                const valFeriDes = Number(imputFerimentoDesviadoP);
                if (valFeriDes > 100) {
                    setisValidFeriDesviado(true);
                    valid = false;
                } else {
                    setisValidFeriDesviado(false);
                }
            } else {
                setisValidFeriDesviado(false);
            }

            if (imputFerimentoNaoDesviadoP.length > 0) {
                const valFeriNaoDes = Number(imputFerimentoNaoDesviadoP);
                if (valFeriNaoDes > 100) {
                    setisValidFeriNaoDesviado(true);
                    valid = false;
                } else {
                    setisValidFeriNaoDesviado(false);
                }
            } else {
                setisValidFeriNaoDesviado(false);
            }

            if (!valid) {
                return;
            }

            const arrayArvores = [];

            const jsonAvaliacao = {
                idArvore: 0,
                idUa: idUaP,
                idPessoa: idPessoaP,
                idQualidadeSangria: idQualidadeSangriaP,
                numeroArvore: nrArvoreP,
                profundidadeBaixo: produtivBaixoP ? ActionTypes.PROFUNDIDADE_BAIXO : null,
                profundidadeMedio: produtivMedioP ? ActionTypes.PROFUNDIDADE_MEDIO : null,
                profundidadeAlto: produtivAltoP ? ActionTypes.PROFUNDIDADE_ALTO : null,
                consumoBaixo: consumoBaixoP ? ActionTypes.CONSUMO_BAIXO : null,
                consumoMedio: consumoMedioP ? ActionTypes.CONSUMO_MEDIO : null,
                consumoAlto: consumoAltoP ? ActionTypes.CONSUMO_ALTO : null,
                ataqueGeratrizes: ataqueGeratrizesP ? ActionTypes.ATAQUE_GERATRIZES : null,
                declividadeCorte: declividadeCorteP ? ActionTypes.DECLIVIDADE_CORTE : null,
                conformidadeEquipamentos: conformidadeEquipamentosP ? ActionTypes.CONFORMIDADE_EQUIPAMENTOS : null,
                impurezasCoagulo: impurezasCoaguloP ? ActionTypes.IMPUREZAS_COAGULO : null,
                facaNaoAmolada: facaNaoAmoladaP ? ActionTypes.FACA_NAO_AMOLADA : null,
                arvoreSangrada: arvoreSangradaP ? ActionTypes.ARVORE_SANGRADA : null,
                controleFitossanitario: controleFitossanitarioP ? ActionTypes.CONTROLE_FITOSSANITARIO : null,
                latexPerdaProducao: latexPerdaProducaoP ? ActionTypes.LATEX_PERDA_PRODUCAO : null,

                ferimentoDesviado: imputFerimentoDesviadoP.length > 0 ? ActionTypes.FERIMENTO_DESVIADO : null,
                ferimentoNaoDesviado: imputFerimentoNaoDesviadoP.length > 0 ? ActionTypes.FERIMENTO_NAO_DESVIADO : null,

                valorFerimentoDesviado: imputFerimentoDesviadoP.length > 0 ? imputFerimentoDesviadoP : null,
                valorFerimentoNaoDesviado: imputFerimentoNaoDesviadoP.length > 0 ? imputFerimentoNaoDesviadoP : null,
                arvoreChecada: true,
                totalPontos: null,
            }
            const totalPontosPP = await calcularPontosSangrador(jsonAvaliacao);
            jsonAvaliacao.totalPontos = totalPontosPP;
            arrayArvores.push(jsonAvaliacao);

            const response = await Api.postRequestSemAccessToken(`api/qualidadeSangria/atualizarOuInserirListaDeArvore`, arrayArvores);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setOpenModalConfimacao(true);
                //setNumStatusResposta(response.numeroStatusResposta);
                //showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            console.log('Error na classe AvaliacaoModal metodo salvarAvaliacao', error);
        }
    }

    async function calcularPontosSangrador(jsonAvaliacao) {
        let result = 0;
        try {
            const js = jsonAvaliacao;

            let profundidadeBaixoPP = await js.profundidadeBaixo != null ? Number(js.profundidadeBaixo) : 0;
            let profundidadeMedioPP = js.profundidadeMedio != null ? Number(js.profundidadeMedio) : 0;
            let profundidadeAltoPP = js.profundidadeAlto != null ? Number(js.profundidadeAlto) : 0;
            let consumoBaixoPP = js.consumoBaixo != null ? Number(js.consumoBaixo) : 0;
            let consumoMedioPP = js.consumoMedio != null ? Number(js.consumoMedio) : 0;
            let consumoAltoPP = js.consumoAlto != null ? Number(js.consumoAlto) : 0;
            let ataqueGeratrizesPP = js.ataqueGeratrizes != null ? Number(js.ataqueGeratrizes) : 0;
            let declividadeCortePP = js.declividadeCorte != null ? Number(js.declividadeCorte) : 0;
            let conformidadeEquipamentosPP = js.conformidadeEquipamentos != null ? Number(js.conformidadeEquipamentos) : 0;
            let impurezasCoaguloPP = js.impurezasCoagulo != null ? Number(js.impurezasCoagulo) : 0;
            let facaNaoAmoladaPP = js.facaNaoAmolada != null ? Number(js.facaNaoAmolada) : 0;
            let arvoreSangradaPP = js.arvoreSangrada != null ? Number(js.arvoreSangrada) : 0;
            let controleFitossanitarioPP = js.controleFitossanitario != null ? Number(js.controleFitossanitario) : 0;
            let latexPerdaProducaoPP = js.latexPerdaProducao != null ? Number(js.latexPerdaProducao) : 0;

            let ferimentoDesviadoPP = js.ferimentoDesviado != null ? Number(js.ferimentoDesviado) : 0;
            let ferimentoNaoDesviadoPP = js.ferimentoNaoDesviado != null ? Number(js.ferimentoNaoDesviado) : 0;

            let valorFerimentoDesviadoPP = js.valorFerimentoDesviado != null ? Number(js.valorFerimentoDesviado) : 0;
            let valorFerimentoNaoDesviadoPP = js.valorFerimentoNaoDesviado != null ? Number(js.valorFerimentoNaoDesviado) : 0;

            if (js.valorFerimentoDesviado != null) {
                valorFerimentoDesviadoPP = valorFerimentoDesviadoPP * 1;  // SEI QUE UM VALOR * 1 E ESSE MESMO MAS PODE DEIXAR ESSE CARA AQUI, VAI QUE MUDA A REGRA
            }
            if (js.valorFerimentoNaoDesviado != null) {
                valorFerimentoNaoDesviadoPP = valorFerimentoNaoDesviadoPP * 4
            }

            let totalPontosPP = profundidadeBaixoPP +
                profundidadeMedioPP +
                profundidadeAltoPP +
                consumoBaixoPP +
                consumoMedioPP +
                consumoAltoPP +
                ataqueGeratrizesPP +
                declividadeCortePP +
                conformidadeEquipamentosPP +
                impurezasCoaguloPP +
                facaNaoAmoladaPP +
                arvoreSangradaPP +
                controleFitossanitarioPP +
                latexPerdaProducaoPP +
                //ferimentoDesviadoPP +
                //ferimentoNaoDesviadoPP +
                valorFerimentoDesviadoPP +
                valorFerimentoNaoDesviadoPP;

            result = totalPontosPP.toFixed(1);
        } catch (error) {
            console.log('Error na classe AvaliacaoModal metodo calcularPontosSangrador', error);
        }
        return result;
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    const changeFerimentoDesviado = (e) => {
        const val = e.target.value;
        const soNumeros = retiraMascaraMaterNumeros(val);
        setImputFerimentoDesviadoP(soNumeros);
    }

    const changeFerimentoNaoDesviado = (e) => {
        const val = e.target.value;
        const soNumeros = retiraMascaraMaterNumeros(val);
        setImputFerimentoNaoDesviadoP(soNumeros);
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 9000);
    }

    function renderTitulo() {
        return (
            <Box mb={'10px'}>
                <Flex justify='center' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{'1ª semana'}</Text>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'5px'}>{`${descMesP} de ${anoCorrente}`}</Text>
                </Flex>
                <Flex justify='start' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight='light' align={'center'} lineHeight={'100%'}>{'Seringueiro:'}</Text>
                    <Text color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{nomeSeringueiro}</Text>
                </Flex>
            </Box>
        )
    }

    function renderProgressBar() {
        return (
            <Box mt={'2px'} px={'10px'} pb={'20px'} borderBottomWidth={'0px'} borderColor={'blackAlpha.500'}>
                <Flex mt={'10px'} flexDirection={'column'} justify='center' align='center'>
                    <Text mb={'10px'} color={textColor} fontSize={'18px'} fontWeight='bold' align={'center'} lineHeight={'100%'}>{`Árvore ${nrArvoreP} de 10`}</Text>
                    <Progress
                        h={'20px'}
                        colorScheme="blue"
                        w={'100%'}
                        bg={'blackAlpha.300'}
                        value={progressArvores} />
                </Flex>
            </Box>
        )
    }

    function renderProdutividade() {
        return (
            <Card px={'0px'} py={'0px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{`Profundidade`}</Text>
                </Box>
                <Flex px={'10px'} mb={'20px'} mt={'10px'} flexDirection={'column'} justify='center' align='start'>
                    <Flex justify='center' align='center'>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={produtivBaixoP}
                            borderWidth={'1px'}
                            borderColor={borderColorCheck}
                            onChange={() => { setProdutivBaixoP(!produtivBaixoP) }} />
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Baixo'}</Text>
                    </Flex>
                    <Flex mt={'10px'} justify='center' align='center'>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={produtivMedioP}
                            borderWidth={'1px'}
                            borderColor={borderColorCheck}
                            onChange={() => { setProdutivMedioP(!produtivMedioP) }} />
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Médio'}</Text>
                    </Flex>
                    <Flex mt={'10px'} justify='center' align='center'>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={produtivAltoP}
                            borderWidth={'1px'}
                            borderColor={borderColorCheck}
                            onChange={() => { setProdutivAltoP(!produtivAltoP) }} />
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Alto'}</Text>
                    </Flex>
                </Flex>
            </Card>
        )
    }

    function renderConsumo() {
        return (
            <Card px={'0px'} py={'0px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Consumo'}</Text>
                </Box>
                <Flex px={'10px'} mb={'20px'} mt={'10px'} flexDirection={'column'} justify='center' align='start'>
                    <Flex justify='center' align='center'>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={consumoBaixoP}
                            borderWidth={'1px'}
                            borderColor={borderColorCheck}
                            onChange={() => { setConsumoBaixoP(!consumoBaixoP) }} />
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Baixo'}</Text>
                    </Flex>
                    <Flex mt={'10px'} justify='center' align='center'>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={consumoMedioP}
                            borderWidth={'1px'}
                            borderColor={borderColorCheck}
                            onChange={() => { setConsumoMedioP(!consumoMedioP) }} />
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Médio'}</Text>
                    </Flex>
                    <Flex mt={'10px'} justify='center' align='center'>
                        <Checkbox
                            size='lg'
                            colorScheme='blue'
                            isChecked={consumoAltoP}
                            borderWidth={'1px'}
                            borderColor={borderColorCheck}
                            onChange={() => { setConsumoAltoP(!consumoAltoP) }} />
                        <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Alto'}</Text>
                    </Flex>
                </Flex>
            </Card>
        )
    }

    function renderFerimento() {
        return (
            <Card px={'0px'} py={'0px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Ferimento'}</Text>
                </Box>
                <Flex flexDirection={'column'} h={'100%'} px={'10px'} justify='space-around' align='center'>
                    <Flex w={'100%'} px={'10px'} justify='center' align='start'>
                        <Icon as={IoAlertCircle} width='25px' height='25px' color={'orange.600'} />
                        <Text color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'10px'}>{'O campo Não desviado será multiplicado pela penalização de 4 vezes o indicado'}</Text>
                    </Flex>
                    <Flex w={'100%'} px={'10px'} justify='center' align='center'>
                        <Flex w={'50%'} flexDirection={'column'} justify='center' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} ml={'10px'}>Desviado</Text>
                            <Input
                                value={imputFerimentoDesviadoP}
                                onChange={changeFerimentoDesviado}
                                isInvalid={isValidFeriDesviado}
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'15px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'180px'}
                                maxLength={3}
                            />
                            {isValidFeriDesviado && <Text color={'red'} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Valor máximo 100'}</Text>}
                        </Flex>
                        <Flex flexDirection={'column'} w={'50%'} justify='center' align='center'>
                            <Text color={textColor} fontSize='15px' fontWeight={'bold'} ml={'10px'}>Não desviado</Text>
                            <Box>
                                <InputGroup size='md'>
                                    <Input
                                        value={imputFerimentoNaoDesviadoP}
                                        onChange={changeFerimentoNaoDesviado}
                                        isInvalid={isValidFeriNaoDesviado}
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        fontSize={'15px'}
                                        fontWeight={'bold'}
                                        h={'35px'}
                                        w={'180px'}
                                        maxLength={3}
                                    />
                                    <InputRightElement display='flex' alignItems='center'>
                                        <Text color={'green.500'} fontSize={'15px'} fontWeight={'bold'}>{'x 4'}</Text>
                                    </InputRightElement>
                                </InputGroup>
                            </Box>
                            {isValidFeriNaoDesviado && <Text color={'red'} fontSize={'13px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Valor máximo 100'}</Text>}
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        )
    }

    function renderAspecto() {
        return (
            <Card px={'0px'} py={'0px'} boxShadow={cardShadowCard}>
                <Box py={'5px'} borderTopRadius={'20px'} bgGradient={gradientHeader}>
                    <Text color={'white'} fontSize={'18px'} fontWeight='bold' lineHeight={'100%'} align={'center'}>{'Aspecto'}</Text>
                </Box>
                <Flex flexDirection={'column'} px={'10px'} mb={'20px'} mt={'0px'} justify='center' align='start'>
                    <Flex w={'100%'} mt={'10px'} justify='center' align='center'>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={ataqueGeratrizesP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setAtaqueGeratrizesP(!ataqueGeratrizesP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Ataque a geratrizes'}</Text>
                        </Flex>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={declividadeCorteP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setDeclividadeCorteP(!declividadeCorteP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Declividade do corte'}</Text>
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={'10px'} justify='center' align='center'>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={conformidadeEquipamentosP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setConformidadeEquipamentosP(!conformidadeEquipamentosP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Conformidade equip'}</Text>

                        </Flex>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={impurezasCoaguloP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setImpurezasCoaguloP(!impurezasCoaguloP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Impurezas no coagulo'}</Text>
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={'10px'} justify='center' align='center'>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={facaNaoAmoladaP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setFacaNaoAmoladaP(!facaNaoAmoladaP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Faca não amolada'}</Text>
                        </Flex>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={arvoreSangradaP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setArvoreSangradaP(!arvoreSangradaP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Árvore não sangrada'}</Text>
                        </Flex>
                    </Flex>
                    <Flex w={'100%'} mt={'10px'} justify='center' align='center'>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={controleFitossanitarioP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setControleFitossanitarioP(!controleFitossanitarioP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Controle Fitossanitário'}</Text>
                        </Flex>
                        <Flex w={'50%'} justify='start' align='center'>
                            <Checkbox
                                size='lg'
                                colorScheme='blue'
                                isChecked={latexPerdaProducaoP}
                                borderWidth={'1px'}
                                borderColor={borderColorCheck}
                                onChange={() => { setLatexPerdaProducaoP(!latexPerdaProducaoP) }} />
                            <Text color={textColor} fontSize={'15px'} fontWeight='bold' align={'center'} lineHeight={'100%'} ml={'10px'}>{'Látex não guiado/perda'}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        )
    }

    function renderAlert() {
        return (
            <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                <Flex px='25px' justify='center' my='5px' align='center' >
                    <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                </Flex>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button isDisabled={!sangriaAtiva} variant="brand" onClick={salvarAvaliacao}>Salvar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'}>
                <Box mb={'20px'}>
                    {renderTitulo()}
                    {renderProgressBar()}
                </Box>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                    {renderProdutividade()}
                    {renderConsumo()}
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                    {renderAspecto()}
                    {renderFerimento()}
                </SimpleGrid>
                {isVisibleAlert && renderAlert()}
            </Card>
            {renderButton()}
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />
        </Box>
    );
}
