
import 'assets/css/StyleFonte.css';
import React, { useState, useEffect } from "react";
import {
    Flex,
    useColorModeValue,
    Box,
    Text,
    Button,
    Select,
    Wrap,
    WrapItem,
    Center,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import { QTD_PAGE } from 'model/MockData';
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { IoArrowRedoSharp } from "react-icons/io5";
import TooltipIcon from 'components/tooltip/TooltipIcon';

export default function ListarTarefasSeringueiro() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listaDiasUteisSeringueiroP, setListaDiasUteisSeringueiroP] = useState([]);
    const [idPessoa, setIdPessoa] = useState(0);
    const [nomePessoa, setNomePessoa] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [responseDataCompleto, setResponseDataCompleto] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);

    const [qtdRealizadas, setQtdRealizadas] = useState(0);
    const [qtdPendentes, setQtdPendentes] = useState(0);
    const [atrasadas, setAtrasadas] = useState('0');
    const [listAtrasadasParaAcessoRapido, setListAtrasadasParaAcessoRapido] = useState([]);
    const [percentualIndiceDeEficienciaP, setPercentualIndiceDeEficienciaP] = useState(0);
    const [listFrequenciaRealizada, setListFrequenciaRealizada] = useState(null);
    const [corIndeiceDeEficiencia, setCorIndeiceDeEficiencia] = useState('green');

    const textColor = useColorModeValue("black", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const boxShadowP = ('0px 0px 10px 1px rgba(0, 0, 0, 0.90)');         // 1-posicao horizontal  2-possicao vertical  3-desfoque 4-largura
    const gradientColorBlack = 'linear-gradient(gray.100, gray.50, white, white, gray.50, gray.100)';
    const gradientColorRed = 'linear-gradient(rgba(128, 0, 0, 0.5), white, white, white, white, rgba(128, 0, 0, 0.5))';
    const gradientColorBlue = 'linear-gradient(blue.200, blue.50, white, white, white, white, blue.50, blue.200)';
    const gradientColorMarron = 'linear-gradient(rgba(45, 22, 6, 0.5), rgba(45, 22, 6, 0.2), white, white, white, white, rgba(45, 22, 6, 0.2), rgba(45, 22, 6, 0.5))';
    const shadowCards = ('0px 2px 5px 3px rgba(0, 0, 0, 0.40)');
    const shadowAcessoRapido = ('0px 2px 5px 3px rgba(0, 0, 0, 0.40)');
    const colorTextCard = 'black';

    const gradientAcessoRapido = 'linear-gradient(gray.900, gray.400, gray.100, gray.400, gray.900)';
    const gradientGreen = 'linear-gradient(green.300, green.400, green.500)';
    const gradientYellow = 'linear-gradient(yellow.300, yellow.400, yellow.500)';
    const gradientred = 'linear-gradient(red.300, red.400, red.500)';
    const gradientWhite = 'linear-gradient(gray.400, gray.300, gray.200)';
    const gradientDiaNaoUtil = 'linear-gradient(#fbefcc, #f7e1a1, #f3d372)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            setResponseData([]);
            setCurrentPage(1);

            const val1 = localStorage.getItem('@HE-dadosParaTelaLancamentoDeTarefa');
            if (val1 != null) {

                const param = JSON.parse(val1);
                const idPes = param.idPessoa;
                const nomePessoaP = param.nome;
                setNomePessoa(nomePessoaP);
                setIdPessoa(idPes);

                const dtF = moment().format('YYYY-MM-DD HH:mm:ss');
                const dtX = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');
                const dtInit = moment(dtX).subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss');
                const ua = localStorage.getItem('@HE-idUa');
                const idSafra = localStorage.getItem('@HE-idSafra');

                const url = `api/listaDadosParaOperacaoOffline/listaDadosParaOffline?idUa=${ua}&idSafra=${idSafra}&idPessoa=${idPes}&idTipoPessoa=3&dtProgramadoDe=${dtInit}&dtProgramadoAte=${dtF}`;
                const response = await Api.getAllSemAccessToken(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    const listFrequenciaRealizadaP = res.listFrequenciaRealizada;
                    if (listFrequenciaRealizadaP != null) {
                        const xx = listFrequenciaRealizadaP.length > 0 ? listFrequenciaRealizadaP[0] : null;
                        setListFrequenciaRealizada(xx);
                    }

                    const val3 = res.somaDasTarefasAndDiasDaSemanaParaCadaSeringueiro;
                    if (val3.length > 0) {

                        const listaDiasUteisP = val3[0].listaDiasUteis;
                        setListaDiasUteisSeringueiroP(listaDiasUteisP);

                        const resCount = val3[0];
                        const indiceEficiencia = resCount.percentualIndiceDeEficiencia;

                        setQtdRealizadas(resCount.realizadas);
                        setQtdPendentes(resCount.pendentes);
                        setAtrasadas(resCount.atrasadas);
                        setPercentualIndiceDeEficienciaP(indiceEficiencia);

                        let corIn = ActionTypes.COR_REALIZADA;
                        if (indiceEficiencia > 94) {
                            corIn = ActionTypes.COR_REALIZADA;
                        } else if (indiceEficiencia > 89) {
                            corIn = ActionTypes.COR_PENDENTE;
                        } else {
                            corIn = ActionTypes.COR_ATRASADA;
                        }
                        setCorIndeiceDeEficiencia(corIn);
                    }
                    const val2 = res.listControleSangria;

                    const listaAtrasadasDataAtual = val2[0].listAtrasadas;
                    setListAtrasadasParaAcessoRapido(listaAtrasadasDataAtual);
                    setResponseData(val2);
                    setResponseDataCompleto(val2);
                }
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, []);

    const totalPages = Math.ceil(responseData.length / itemsPerPage);
    const paginatedData = responseData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo(0, 0);
    };

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    const acessarTarefa = (item) => {
        const id = item.idControleSangriaAtrasada;
        const array = responseDataCompleto.filter(x => x.idControleSangria == id);
        setResponseData(array);
    }

    const lancarTarefa = (item) => {
        const json = {
            descData: item.descData,
            descMotivo: item.descMotivo,
            diaSemanaRealizado: item.diaSemanaRealizado,
            dsStatus: item.dsStatus,
            dtProgramado: item.dtProgramado,
            dtRealizado: item.dtRealizado,
            idControleSangria: item.idControleSangria,
            idMotivo: item.idMotivo,
            idPessoa: idPessoa,
            idStatusTarefa: item.idStatusTarefa,
            letraTarefa: item.letraTarefa,
            listaDiasUteisSeringueiro: listaDiasUteisSeringueiroP,
            pontoVermelho: item.pontoVermelho,
            observacao: item.observacao,
            letraCustomizada: item.letraCustomizada,
            idTarefa: item.idTarefa,
        }
        localStorage.setItem('@HE-dadosParaTelaLancarTarefa', JSON.stringify(json));
        history.push("/admin/lancar-tarefa");
    }

    function renderCards() {
        const ftSiz = '14px';
        const heightP = '50px';
        return (
            <Box px={'20px'}>
                <Wrap spacing='10px' justify={'space-between'} align={'center'}>
                    <WrapItem w={'15%'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' boxShadow={shadowCards} bg={'white'} borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text noOfLines={2} color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Tarefas realizadas'}</Text>
                            <Text color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{qtdRealizadas}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' boxShadow={shadowCards} bg={'white'} borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text noOfLines={2} color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Tarefas pendentes'}</Text>
                            <Text color={'orange.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{qtdPendentes}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' boxShadow={shadowCards} bg={'white'} borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text noOfLines={2} color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Tarefas atrasadas'}</Text>
                            <Text color={'red.600'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{atrasadas}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' boxShadow={shadowCards} bg={'white'} borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} h={heightP}>
                            <Text noOfLines={2} color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Índice de eficiência'}</Text>
                            <Text color={corIndeiceDeEficiencia} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{`${percentualIndiceDeEficienciaP}%`}</Text>
                        </Flex>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderAcessoRapido() {
        const ftSizeP = '16px';
        return (
            <Box position={'relative'} pb={'20px'} pt={'10px'} my={'30px'} w={'100%'} bgGradient={gradientAcessoRapido}>
                {listAtrasadasParaAcessoRapido.length > 0 ?
                    <Box w={'100%'}>
                        <Flex pt={'15px'} justify='start' align='center' overflowX={'auto'}>
                            {listAtrasadasParaAcessoRapido.map((item, index) => {

                                let statusP = item.dsStatus;
                                let corLetra = 'black';
                                let degraadeBg = gradientColorBlack;
                                const idStatusTarefaAtrasada = item.idStatusTarefaAtrasada != null ? item.idStatusTarefaAtrasada : 0;

                                if (idStatusTarefaAtrasada == 3) {
                                    corLetra = ActionTypes.COR_ATRASADA;
                                    degraadeBg = gradientColorRed;

                                } else if (idStatusTarefaAtrasada == 4) {

                                    const idMotivo = item.idMotivo != null ? item.idMotivo : 0;
                                    statusP = item.descMotivo != null && item.descMotivo != '' ? item.descMotivo : item.dsStatus;

                                    if (idMotivo == 2) {
                                        corLetra = ActionTypes.COR_CHUVA;
                                        degraadeBg = gradientColorBlue;
                                    } else {
                                        corLetra = ActionTypes.COR_MARRON;
                                        degraadeBg = gradientColorMarron;
                                    }
                                }

                                const dtProgramadoP = item.dtProgramado != null ? moment(item.dtProgramado).format('DD/MM/YY') : '';
                                return (
                                    <Box
                                        key={index}
                                        onClick={() => { acessarTarefa(item) }}
                                        cursor={'pointer'}
                                        minWidth={'80px'}
                                        p={'5px'}
                                        ml={'5px'}
                                        boxShadow={shadowAcessoRapido}
                                        bgGradient={degraadeBg}
                                        borderWidth={'1px'}
                                        borderColor={'blackAlpha.600'}
                                        borderRadius={'8px'}>
                                        <Text color={corLetra} fontSize={'15px'} fontWeight={'light'} lineHeight='100%' align={'center'} className='bowlby-one-regular'>{item.letra}</Text>
                                        <Text mt={'4px'} color={colorTextCard} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{statusP}</Text>
                                        <Text mt={'2px'} color={colorTextCard} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{dtProgramadoP}</Text>
                                    </Box>
                                )
                            })}
                        </Flex>
                    </Box>
                    :
                    <Box w={'100%'} mt={'20px'}>
                        <Text color={'gray.600'} fontSize={ftSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Sem pendências nessa data'}</Text>
                    </Box>
                }
                <Box position={'absolute'} top={'-8px'} left={'0px'} right={'0px'}>
                    <Flex justify='center' align='center'>
                        <Box px={'10px'} py={'5px'} bg={'gray.800'} borderRadius={'10px'}>
                            <Center ml={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                <Text color={'white'} fontSize={ftSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'} mr={'10px'}>{'Acesso rápido'}</Text>
                                <TooltipIcon
                                    label={
                                        <Box px={'0px'} py={'5px'} >
                                            <Text align={'center'}>{'Acesso rápido'}</Text>
                                            <Text align={'start'} mt={'5px'} ml={'5px'} lineHeight='100%'>{'Clique na tarefa para selecionar na lista'}</Text>
                                        </Box>
                                    }
                                    height={'18px'} color={'white'} />
                            </Center>

                        </Box>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderTarefas() {
        const ftSizeP = '16px';

        let isDiaUtil = false;
        let corQtdDiasTarefaAtrasada = 'transparent';
        let descQtdDiasTarefaAtrasada = '';
        if (listFrequenciaRealizada != null) {
            const obj = listFrequenciaRealizada;
            isDiaUtil = obj.diaUtil == true ? true : false;
            descQtdDiasTarefaAtrasada = obj.descQtdDiasTarefaAtrasada;
            if (!isDiaUtil || obj.dtDeletado != null) {
                corQtdDiasTarefaAtrasada = gradientDiaNaoUtil;
                descQtdDiasTarefaAtrasada = 'Sem program.';
            } else if (obj.idQtdDiasTarefaAtrasada == 1) {
                corQtdDiasTarefaAtrasada = gradientGreen;
            } else if (obj.idQtdDiasTarefaAtrasada == 2) {
                corQtdDiasTarefaAtrasada = gradientYellow;
            } else if (obj.idQtdDiasTarefaAtrasada == 3) {
                corQtdDiasTarefaAtrasada = gradientred;
            } else if (obj.idQtdDiasTarefaAtrasada == 0) {
                corQtdDiasTarefaAtrasada = gradientWhite;
            }
        }
        return (
            <Flex px={'10px'} direction="column" align="center">
                {paginatedData.map((item, index) => {
                    let corStatus = 'black';
                    if (item.idStatusTarefa == 1) {           // realizada
                        corStatus = ActionTypes.COR_REALIZADA;
                    } else if (item.idStatusTarefa == 2) {     // pendente
                        corStatus = ActionTypes.COR_PENDENTE;
                    } else if (item.idStatusTarefa == 3) {     // atrazada
                        corStatus = ActionTypes.COR_ATRASADA;
                    } else if (item.idStatusTarefa == 4) {     // nao realizada
                        corStatus = ActionTypes.COR_NAO_REALIZADA;
                    }
                    return (
                        <Box key={index} position={'relative'} mb={'40px'} w={'100%'} borderWidth={'1px'} borderColor={'blackAlpha.900'} borderRadius={'10px'} boxShadow={boxShadowP} bg={'white'}>

                            {/* PROGRAMACAO DO DIA */}
                            <Box position={'relative'} py={'5px'} mx={'10px'} mt={'5px'} borderWidth={'0px'} borderColor={'blackAlpha.900'} borderRadius={'10px'} bg={'white'}>
                                <Flex px={'0px'} justify='start' align='start'>

                                    <Flex w={'80%'} minHeight={'60px'} flexDirection={'column'} justify='center' align='start'>
                                        <Text color={'gray.600'} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%'>{'Programação do dia'}</Text>
                                        <Flex justify='center' align='center'>
                                            <Text color={corStatus} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%'>{item.dsStatus}</Text>
                                            <Text color={'red.500'} fontSize={'16px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{item.descComplementar}</Text>
                                        </Flex>

                                    </Flex>
                                    <Flex w={'20%'} justify='end' align='center' >
                                        <Flex flexDirection={'column'} justify='center' align='center' >
                                            <Button
                                                w={'100px'}
                                                h={'30px'}
                                                fontSize={'13px'}
                                                rightIcon={<IoArrowRedoSharp size={'20px'} />}
                                                variant="brand"
                                                onClick={() => { lancarTarefa(item) }}>Lançar</Button>
                                            <Text color={corStatus} mt={'5px'} fontSize='25px' fontWeight={'light'} lineHeight={'100%'} align={'center'} className='bowlby-one-regular'>{item.letraTarefa}</Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Box>

                            {/* ATIVIDADES REALIZADAS NESSA DATA */}
                            <Box mx={'5px'} mt={'0px'} borderWidth={'1px'} borderColor={'blackAlpha.900'} borderRadius={'10px'} bg={'rgba(0, 0, 0, 0.1)'}>
                                <Text mt={'5px'} color={textColor} fontSize={ftSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Atividades realizadas nessa data'}</Text>
                                {item.listAtividadesRealizadasNessaData.length > 0 ?
                                    <Box px={'10px'} w={'100%'} py={'10px'}>
                                        {item.listAtividadesRealizadasNessaData.map((xx, yy) => {

                                            let corSt = 'black';
                                            let corDescricao = textColor;
                                            if (xx.idStatusTarefa == 1) {           // realizada

                                                corSt = ActionTypes.COR_REALIZADA;
                                                if (xx.pontoVermelho == true) {
                                                    corDescricao = ActionTypes.COR_ATRASADA;
                                                } else {
                                                    corDescricao = ActionTypes.COR_REALIZADA;
                                                }

                                            } else if (xx.idStatusTarefa == 2) {     // pendente
                                                corSt = ActionTypes.COR_PENDENTE;
                                            } else if (xx.idStatusTarefa == 3) {     // atrazada
                                                corSt = ActionTypes.COR_ATRASADA;
                                            } else if (xx.idStatusTarefa == 4) {     // nao realizada
                                                corSt = ActionTypes.COR_NAO_REALIZADA;
                                            }

                                            return (
                                                <Flex key={yy} justify='start' align='end'>
                                                    <Text color={corSt} fontSize={'18px'} fontWeight={'light'} lineHeight='100%' className='bowlby-one-regular'>{xx.letraTarefa}</Text>
                                                    <Text color={corSt} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'} >{xx.dsStatus}</Text>
                                                    <Text color={corDescricao} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' ml={'5px'} >{xx.descricao}</Text>
                                                </Flex>
                                            )
                                        })}
                                    </Box>
                                    :
                                    <Box w={'100%'} mb={'5px'} mt={'10px'}>
                                        <Text color={'gray.400'} fontSize={ftSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Sem lançamentos para essa data'}</Text>
                                    </Box>
                                }
                            </Box>

                            {/* TAREFAS PENDENTES NESSA DATA */}
                            <Box py={'10px'}>
                                {item.listAtrasadas.length > 0 ?
                                    <Box w={'100%'}>
                                        <Text mb={'5px'} color={textColor} fontSize={ftSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Tarefas pendentes nessa data'}</Text>
                                        <Flex justify='start' align='center' overflowX={'auto'}>
                                            {item.listAtrasadas.map((xx, yy) => {
                                                const dtProgramadoP = xx.dtProgramado != null ? moment(xx.dtProgramado).format('DD/MM/YY') : '';
                                                return (
                                                    <Box key={yy} minWidth={'80px'} p={'5px'} ml={'5px'} borderWidth={'1px'} borderColor={'blackAlpha.600'} borderRadius={'8px'} bg={'blackAlpha.400'}>
                                                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{xx.letra}</Text>
                                                        <Text mt={'4px'} color={textColor} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{xx.dsStatus}</Text>
                                                        <Text mt={'2px'} color={textColor} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{dtProgramadoP}</Text>
                                                    </Box>
                                                )
                                            })}
                                        </Flex>
                                    </Box>
                                    :
                                    <Box w={'100%'} mt={'20px'}>
                                        <Text color={'gray.600'} fontSize={ftSizeP} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Sem pendências nessa data'}</Text>
                                    </Box>
                                }
                                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                                    <Flex justify='center' align='center'>
                                        <Box px={'10px'} py={'5px'} bg={'gray.800'} borderRadius={'10px'}>
                                            <Text color={'white'} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{`${item.descData}`}</Text>
                                        </Box>
                                    </Flex>
                                </Box>
                                {item.editavel == true &&
                                    <Box position={'absolute'} top={'-10px'} left={'10px'}>
                                        <Flex justify='center' align='center'>
                                            <Box px={'10px'} py={'5px'} minW={'100px'} bgGradient={corQtdDiasTarefaAtrasada} borderRadius={'10px'} borderWidth={'1px'} borderColor={'black'}>
                                                <Text color={'black'} fontSize={'15px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{descQtdDiasTarefaAtrasada}</Text>
                                            </Box>
                                        </Flex>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    )
                })}
            </Flex>
        )
    }

    function renderPaginacao() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                <Flex justify='center' align='center'>
                    <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                    <Box ml={'10px'}>
                        <Select
                            value={itemsPerPage}
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={'gray.200'}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            size="sm"
                            h={'35px'}
                            bg={'white'}
                            onChange={changePage}>
                            {QTD_PAGE.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </Flex>
                <Flex justify='center' align='center'>
                    <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${responseData.length}`}</Text>
                    <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} ml={2} />
                </Flex>
            </Flex>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} my={'10px'} justify='start'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center'>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }} >
            <Card px={'0px'} py={'0px'} bg={'transparent'}>
                <Card px={'0px'} py={'0px'} bg={'transparent'}>

                    <Flex px={'25px'} mb={'15px'} justify='start' align='center'>
                        <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%'>Seringueiro:</Text>
                        <Text ml={'10px'} color={'orange.600'} fontSize='18px' fontWeight='bold' lineHeight='100%'>{nomePessoa}</Text>
                    </Flex>

                    {renderCards()}
                    {renderAcessoRapido()}
                    {renderTarefas()}
                </Card>
                {renderPaginacao()}
                {renderButton()}
            </Card>
        </Box>
    );
}
