import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Select,
    TableContainer,
    WrapItem,
    Wrap,
    Center,
    Grid,
    Image,
    Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { retornaNomeDoMes, ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO } from '../../../util/FuncoesUteis';
import LoaderBlack from 'components/loading/LoaderBlack';
import MessageSreen from 'components/message/MessageSreen';
import TooltipSeringueiro from 'components/tooltip/TooltipSeringueiro';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import TooltipObservacao from 'components/tooltip/TooltipObservacao';
import { jsPDF } from "jspdf";
import iconPdf from "assets/img/layout/pdf_icon_download.png";
import html2canvas from 'html2canvas';
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../util/CalculoRelatorioSangriaAndProducao';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function AcompanhamentoAtividadesSangria() {

    const history = useHistory();
    const pdfRef = useRef();
    const [loading, setLoading] = useState(false);
    const [saldoAcumulado, setSaldoAcumulado] = useState(0);
    const [porcentagemRealizada, setPorcentagemRealizada] = useState(0);
    const [producaoCoagulosPorArvorePrevista, setProducaoCoagulosPorArvorePrevista] = useState(0);
    const [producaoCoagulosPorArvoreRealizada, setProducaoCoagulosPorArvoreRealizada] = useState(0);
    const [arrayMeses, setArrayMeses] = useState([]);
    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [mesInicioSelecionado, setMesInicioSelecionado] = useState('');

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [totalPercentual, setTotalPercentual] = useState(0);
    const [extratoProducao, setExtratoProducao] = useState([]);
    const [listAtividadeSangriaItem, setListAtividadeSangriaItem] = useState([]);

    const [percentualSangriasPossiveisRealizadas, setPercentualSangriasPossiveisRealizadas] = useState('0');
    const [percentualIndiceEficiencia, setPercentualIndiceEficiencia] = useState('0');
    const [chuvaFeriado, setChuvaFeriado] = useState('0');
    const [diasPossiveisPeriodo, setDiasPossiveisPeriodo] = useState('0');
    const [realizadas, setRealizadas] = useState('0');
    const [faltas, setFaltas] = useState('0');
    const [realizadasMenosTotalDeDias, setRealizadasMenosTotalDeDias] = useState('0');

    const [totalConsumo, setTotalConsumo] = useState('0');
    const [totalProfundidade, setTotalProfundidade] = useState('0');
    const [totalFerimento, setTotalFerimento] = useState('0');
    const [totalAspecto, setTotalAspecto] = useState('0');
    const [totalPontos, setTotalPontos] = useState('0');
    const [avaliacaoSangria, setAvaliacaoSangria] = useState('');
    const [corNota, setCorNota] = useState('');
    const [notaSeringueiro, setNotaSeringueiro] = useState('');
    const [listTarefa, setListTarefa] = useState([]);
    const [totalSangriaPorTarefa, setTotalSangriaPorTarefa] = useState(null);
    const [listFrequenciaMedia, setListFrequenciaMedia] = useState([]);
    const [diasUteisNoMes, setDiasUteisNoMes] = useState('0');
    const [dtInicioSangria, setDtInicioSangria] = useState(null);
    const [dtFimSangria, setDtFimSangria] = useState(null);

    const [nomeUa, setNomeUa] = useState('');
    const [marginText, setMarginText] = useState('0px');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [nomeRelatorio, setNomeRelatorio] = useState('Atividades-sangria');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('--');
    const [listaDiasUteisSeringueiroP, setListaDiasUteisSeringueiroP] = useState([]);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [programacaoEmAndamento, setProgramacaoEmAndamento] = useState(false);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [messageModalConfimacao, setMessageOpenModalConfimacao] = useState(false);

    const textColorHeader = useColorModeValue("white", "white");
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientSubTotal = 'linear-gradient(#ff8000, #ff9933, #ffcc99)';
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientGreen = 'linear-gradient(green.300, green.400, green.500)';
    const gradientYellow = 'linear-gradient(yellow.300, yellow.400, yellow.500)';
    const gradientred = 'linear-gradient(red.300, red.400, red.500)';
    const gradientWhite = 'linear-gradient(gray.300, white, gray.300)';
    const gradientBlack = 'linear-gradient(gray.600, gray.700, gray.900)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const startDate = localStorage.getItem('@HE-dtInicioSafraAsync');
            const endDate = localStorage.getItem('@HE-dtFimSafraAsync');
            const arrayDatas = await ARRAY_DE_DATAS_ENTRE_UM_PERIODO_PARA_COMBO(startDate, endDate);
            setArrayMesInicio(arrayDatas);

            const sangriaAti = localStorage.getItem('@HE-sangriaAtivaAsync');
            setSangriaAtiva(sangriaAti == 'true' ? true : false);

            const val1 = localStorage.getItem('@HE-dadosParaTelaLancamentoDeTarefa');
            if (val1 != null) {
                const param = JSON.parse(val1);
                const listaDiasUteisP = param.listaDiasUteis;
                setListaDiasUteisSeringueiroP(listaDiasUteisP);
            }

            const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
            const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
            const nmUa = localStorage.getItem('@HE-nomeUa');
            setIdUf(idUfP);
            setNomeMunicipio(nomeMunicipioP);
            setNomeUa(nmUa);

            const dia = moment().format('DD');
            const ano = moment().format('YYYY');
            const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
            const nmRelatorio = `Atividades-sangria-${dia}-${nmMes}-${ano}`;
            setNomeRelatorio(nmRelatorio);

            const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
            const fim = localStorage.getItem('@HE-dtFimSafraAsync');

            setDtInicioSangria(moment(ini).format('DD/MM/YY'));
            setDtFimSangria(moment(fim).format('DD/MM/YY'))

            const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
            setArrayMeses(ARRAY_MES);

            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const url = `safraPessoa/listaPessoaParaUmaUaAndSafra?idUa=${idUa}&idSafra=${idSafra}&deletado=false`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const listSeringueiros = response.respostaRequisicao.data;

                let arrayPess = [];
                if (listSeringueiros.length > 0) {
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }

                let initMes = '';
                const paramX = localStorage.getItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria');

                if (paramX != null) {
                    const param = JSON.parse(paramX);
                    const idPess = param.idPessoa;
                    const mes = param.mes != null ? param.mes : ini;

                    setPessoaSelecionado(idPess);

                    initMes = moment(mes).startOf('month').format('YYYY-MM-DD');
                    setMesInicioSelecionado(initMes);

                    await pesquisarComDatas(initMes, idPess, ARRAY_MES);
                } else {
                    const zz = moment(ini).add(1, 'month');
                    initMes = moment(zz).startOf('month').format('YYYY-MM-DD');
                }
                const mesAndAno = moment(initMes).format('MM/YYYY');
                setMesInicioSelecionado(initMes);
                setNomeMesCorrente(mesAndAno);
            }
            setTelaCarregada(true);
        }
        loaderScreen();
    }, [])

    const pesquisarComDatas = async (mesInicioSelecionadoP, pessoaSelecionadoP, arrayMesesP) => {
        try {
            const jsonX = {
                mes: mesInicioSelecionadoP,
                idPessoa: pessoaSelecionadoP,
            }
            localStorage.setItem('@HE-mesAndPessoaNaTelaAcompanhamentoSangria', JSON.stringify(jsonX));

            const fil = listSeringueiro.filter(x => x.value == pessoaSelecionadoP);
            const nomeSe = fil.length > 0 ? fil[0].label : '';
            setNomeSeringueiro(nomeSe);

            setNomeMesCorrente(moment(mesInicioSelecionadoP).format('MM/YYYY'));
            const primeiroDiaDoMes = moment(mesInicioSelecionadoP).startOf('month');
            const UltimoDiaDoMes = moment(mesInicioSelecionadoP).endOf('month');

            let startDate = moment(primeiroDiaDoMes);
            let endDate = moment(UltimoDiaDoMes);
            let date = [];

            for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'month')) {
                date.push(m.format('YYYY-MM-DD'));
            }

            setLoading(true);

            let arrayPess = [];
            await new Promise(resolve => {
                resolve(
                    arrayMesesP.map((item, index) => {
                        let statusP = false;
                        if (index > 0) {
                            const arrFilter = date.filter(x => x == item.dtMes);
                            statusP = arrFilter.length > 0 ? true : false;
                        }
                        const obj = {
                            dtMes: item.dtMes,
                            mes: item.mes,
                            status: statusP,
                        }
                        arrayPess.push(obj);
                    })
                )
            });

            const dtI = moment(primeiroDiaDoMes).format('YYYY-MM-DD');
            const dtF = moment(UltimoDiaDoMes).format('YYYY-MM-DD');
            const idUa = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: idUa,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                idPessoa: pessoaSelecionadoP,
                arrayMesDataFilter: arrayMesesP,
            }
            const response = await Api.postRequest(`atividadeSangria/relatorioAtividadeSangria`, json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                const listAtividadeSangriaItemP = res.listAtividadeSangriaItem;
                const listTarefaP = res.listTarefa;

                setListAtividadeSangriaItem(listAtividadeSangriaItemP);
                setListTarefa(listTarefaP);
                setTotalSangriaPorTarefa(res.totalSangriaPorTarefa);
                setListFrequenciaMedia(res.listFrequenciaMedia);
                setProgramacaoEmAndamento(res.programacaoEmAndamento);

                await carregaDadosSeringueiro(res, false);
                setLoading(false);
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe AcompanhamentoAtividadesSangria metodo pesquisar', error);
        }
    }

    const calculaMedia = (val) => {
        let corN = 'black';
        if (val == 1) {
            corN = 'gray.500';
        } else if (val == 2) {
            corN = 'green.500';
        } else if (val == 3) {
            corN = 'orange.600';
        } else if (val == 4) {
            corN = 'red';
        }
        setCorNota(corN);
    }

    const carregaDadosSeringueiro = async (array, seringueiroSelecionadoP = false, nomePess = 'Todos') => {

        const indicador = array.indicadorPorSeringueiro;

        const percentualSangriasPossiveisRealizadasP = indicador.percentualSangriasPossiveisRealizadas;
        const percentualIndiceEficienciaP = indicador.percentualIndiceEficiencia;
        const chuvaFeriadoP = indicador.chuvaFeriado;
        const diasPossiveisPeriodoP = indicador.diasPossiveisPeriodo;
        const realizadasP = indicador.realizadas;
        const faltasP = indicador.faltas;
        const realizadasMenosTotalDeDiasP = indicador.realizadasMenosTotalDeDias;
        const seringureiroP = indicador.seringueiro != null ? indicador.seringueiro : '';

        const totalConsumoP = array.totalConsumo != null ? Number(array.totalConsumo) : 0;
        const totalProfundidadeP = array.totalProfundidade != null ? Number(array.totalProfundidade) : 0;
        const totalFerimentoP = array.totalFerimento != null ? Number(array.totalFerimento) : 0;
        const totalAspectoP = array.totalAspecto != null ? Number(array.totalAspecto) : 0;
        const totalPontosP = array.totalPontos != null ? Number(array.totalPontos) : 0;
        const notaSeringueiroP = array.notaSeringueiro != null ? Number(array.notaSeringueiro) : 0;
        const idAvaliacaoP = array.idAvaliacao != null ? Number(array.idAvaliacao) : 1;
        const diasUteisNoMesP = array.diasUteisNoMes != null ? array.diasUteisNoMes : 0;

        setAvaliacaoSangria(array.avaliacaoDeSangria);
        calculaMedia(idAvaliacaoP);
        setTotalConsumo(totalConsumoP.toFixed(1).replace('.', ','));
        setTotalProfundidade(totalProfundidadeP.toFixed(1).replace('.', ','));
        setTotalFerimento(totalFerimentoP.toFixed(1).replace('.', ','));
        setTotalAspecto(totalAspectoP.toFixed(1).replace('.', ','));
        setTotalPontos(totalPontosP.toFixed(1).replace('.', ','));
        setDiasUteisNoMes(diasUteisNoMesP);

        setPercentualSangriasPossiveisRealizadas(percentualSangriasPossiveisRealizadasP);
        setPercentualIndiceEficiencia(percentualIndiceEficienciaP);
        setChuvaFeriado(chuvaFeriadoP);
        setDiasPossiveisPeriodo(diasPossiveisPeriodoP);
        setRealizadas(realizadasP);
        setFaltas(faltasP);
        setRealizadasMenosTotalDeDias(realizadasMenosTotalDeDiasP);
        setNomeSeringueiro(seringureiroP);

        let notaX = '';
        if (notaSeringueiroP >= 100) {
            notaX = notaSeringueiroP.toFixed(0);
        } else {
            const xw = notaSeringueiroP.toFixed(1);
            notaX = xw.replace('.', ',');
        }
        setNotaSeringueiro(notaX);

        const arraySeringueirosComMeses = array.responseProducao.listRelatorioAcompanhamentoProducao[0]
        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);

        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setExtratoProducao(res.arrayProducaoAndGrafico);
        setTotalPercentual(res.totalPercentual);
        setSaldoAcumulado(res.saldoAcumulado);

        setPorcentagemRealizada(res.porcentagemRealizado);
        setProducaoCoagulosPorArvorePrevista(res.producaoCoagulosPorArvorePrevista);
        setProducaoCoagulosPorArvoreRealizada(res.producaoCoagulosPorArvoreRealizada);

        const jsonProducao = {
            totalRealizado: res.totalRealizado,
            totalPrevisto: res.totalPrevisto,
            totalArvoresSangradas: res.totalArvoresSangradas,
            totalDistribuicao: res.totalDistribuicao,
            extratoProducao: res.arrayProducaoAndGrafico,
            totalPercentual: res.totalPercentual,
            nomePessoa: nomePess
        }
        localStorage.setItem('HE@-dadosRelatorioAcompanhamentoProducao', JSON.stringify(jsonProducao));
    }

    const verificarSeSafraAtiva = (item) => {
        if (sangriaAtiva) {
            if (item.diaUtil == true) {
                lancarTarefa(item);
            } else {
                setMessageOpenModalConfimacao('Não é um dia útiel para lançamento.')
                setOpenModalConfimacao(true);
            }
        } else {
            setMessageOpenModalConfimacao('Não é possível realizar lançamentos para safra finalizada.')
            setOpenModalConfimacao(true);
        }
    }

    async function lancarTarefa(item) {
        try {
            const idMotivoX = item.idMotivo != null ? item.idMotivo : 0;
            const descObservacao = await pesquisaObservacao(item.idControleSangria);

            const json = {
                descMotivo: item.descMotivo,
                diaSemanaRealizado: item.diaSemanaRealizado,
                dsStatus: item.dsStatus,
                dtProgramado: item.dtProgramado,
                dtRealizado: item.dtRealizado,
                idControleSangria: item.idControleSangria,
                idMotivo: idMotivoX,
                idPessoa: pessoaSelecionado,
                idStatusTarefa: item.idStatusTarefa,
                letraTarefa: item.letraTarefa,
                listaDiasUteisSeringueiro: listaDiasUteisSeringueiroP,
                pontoVermelho: item.pontoVermelho,
                observacao: descObservacao,
                letraCustomizada: item.letraCustomizada,
                idTarefa: item.idTarefa,
            }

            localStorage.setItem('@HE-dadosParaTelaLancarTarefa', JSON.stringify(json));
            history.push("/admin/lancar-tarefa");

        } catch (error) {
            console.log('Erro na classe lancarTarefa metodo pesquisar', error);
        }
    }

    async function pesquisaObservacao(idControle = 0) {
        let descObservacao = '';

        for (let i = 0; i < listAtividadeSangriaItem.length; i++) {
            const element = listAtividadeSangriaItem[i];

            const listObservacao = element.listObservacao;
            if (listObservacao.length > 0) {
                const arr = listObservacao.filter(x => x.idControleSangria == idControle);

                if (arr.length > 0) {
                    if (arr[0].observacao != '') {
                        descObservacao = arr[0].observacao
                    }
                }
            }
        }
        return descObservacao;
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('10px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const changeMes = (val) => {
        setMesInicioSelecionado(val);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    function renderMeses() {
        return (
            <Box position={'relative'} borderRadius={'10px'} py={'20px'}>
                <Wrap px={'10px'} spacing='10px' justify='start'>
                    <WrapItem>
                        <Box w='100%' h={'100%'}>
                            <Flex flexDirection={'column'} h={'100%'} justify={'end'} align={'start'}>
                                <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} mb={'4px'} align={'start'}>mês</Text>
                                <Select
                                    value={mesInicioSelecionado}
                                    errorBorderColor='crimson'
                                    placeholder=""
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    onChange={e => changeMes(e.target.value)}
                                    w={'200px'}
                                    h={'35px'}
                                >
                                    {arrayMesInicio.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: item.colorOp, color: 'black', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Flex>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='100%'>
                            <Flex justify={'start'} align={'center'} mb={'4px'}>
                                <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Seringueiros</Text>
                                <Center py={'0px'} h={'100%'} justifyContent={'center'} alignItems={'end'} ml={'5px'}>
                                    <TooltipIcon
                                        label={
                                            <Box py={'5px'} pb={'10px'}>
                                                <Text align={'center'} lineHeight={'100%'}>{'Seringueiros'}</Text>
                                                <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Os seringueiros desativados ou sem programação não estão nessa lista.'}</Text>
                                            </Box>
                                        }
                                        height={'16px'} />
                                </Center>
                            </Flex>
                            <Select
                                value={pessoaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={(e) => setPessoaSelecionado(e.target.value)}
                                h={'35px'}
                                w={'350px'}
                            >
                                {listSeringueiro.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='100%' h={'100%'}>
                            <Center alignItems={'flex-end'} h={'100%'}>
                                <Button isDisabled={pessoaSelecionado != '' ? false : true} variant="brand" onClick={() => { pesquisarComDatas(mesInicioSelecionado, pessoaSelecionado, arrayMeses) }}>Pesquisar</Button>
                            </Center>
                        </Box>
                    </WrapItem>
                    {sangriaAtiva == false && (
                        <WrapItem flex={'1'}>
                            <Box w='100%' h={'100%'}>
                                <Flex h={'100%'} justify={'center'} align={'end'}>
                                    <Badge colorScheme='red' variant='solid'>Safra finalizada</Badge>
                                </Flex>
                            </Box>
                        </WrapItem>
                    )}
                </Wrap>
                <Flex mt={'10px'} justify={'start'} align={'center'}>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'20px'}>{'Inicio Sangria:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{dtInicioSangria}</Text>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'40px'}>{'Fim Sangria:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{dtFimSangria}</Text>
                </Flex>
                <Box position={'absolute'} top={'1px'} right={'15px'} zIndex="15">
                    <Image boxSize='35px' objectFit='contain' src={iconPdf} alt='Dan Abramov' cursor={'pointer'} onClick={downloadPdf} />
                </Box>
            </Box>
        )
    }

    function renderDadosRelatorio() {
        return (
            <Box>
                <Flex px={'5px'} justify='start' align='center'>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'}>{'Seringueiro:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{nomeSeringueiro}</Text>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'30px'}>{'Mês/Ano:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{nomeMesCorrente}</Text>
                    <Text mb={marginText} color={textColor} fontSize='15px' fontWeight={'light'} lineHeight={'100%'} ml={'30px'}>{'Dias Úteis/Mês:'}</Text>
                    <Text mb={marginText} color={'black'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{diasUteisNoMes}</Text>
                </Flex>
            </Box>
        )
    }

    function renderAtividadesSangria() {
        const sizeF = '13px';
        const largTr = 0;
        const altura = 1;
        const qtdTarefas = listTarefa.length;
        return (
            <Box mt={'0px'} borderRadius={'10px'} px={'5px'} py={'5px'}>
                <Table size='sm' variant='unstyled' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                    <Thead position="sticky" top="0" zIndex="10">
                        <Tr h={'30px'} bgGradient={gradientHeader}>
                            <Th w={'110px'} borderRightWidth={'1px'} borderColor={'black'} sx={{ paddingX: largTr, paddingY: altura }}>
                                <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Dia'}</Text>
                            </Th>
                            <Th w={'110px'} borderRightWidth={'1px'} borderColor={'black'} sx={{ paddingX: largTr, paddingY: altura }}>
                                <Flex justify={'center'} align={'center'}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Program'}</Text>
                                    <Center py={'6px'} h={'100%'} justifyContent={'center'} alignItems={'end'} ml={'5px'}>
                                        <TooltipIcon
                                            label={
                                                <Box py={'5px'} pb={'10px'}>
                                                    <Text align={'center'} lineHeight={'100%'}>{'Tarefas Programadas'}</Text>
                                                    <Text align={'center'} lineHeight={'100%'}>{'Indicadores das tarefas do dia'}</Text>
                                                    <Box py={'5px'} w={'100%'} borderBottomWidth={'1px'} borderColor={'gray.500'} />
                                                    <Text align={'start'} mt={'15px'} lineHeight={'100%'} color={'red.400'} fontWeight={'bold'}>{'mês / ano'}</Text>
                                                    <Text align={'start'} mt={'5px'} ml={'15px'} lineHeight={'100%'} color={'white'}>{'Indica que a tarefa programada foi realizada em outra data, a data de realização estará destacada em vermelho ao lado da tarefa.'}</Text>
                                                    <Box py={'5px'} w={'100%'} borderBottomWidth={'1px'} borderColor={'gray.500'} />
                                                    <Text align={'start'} mt={'15px'} lineHeight={'100%'} color={'lime'} fontWeight={'bold'}>{'OK'}</Text>
                                                    <Text align={'start'} mt={'5px'} ml={'15px'} lineHeight={'100%'} color={'white'}>{'Indica que a tarefa foi realizada no dia programado.'}</Text>
                                                    <Box py={'5px'} w={'100%'} borderBottomWidth={'1px'} borderColor={'gray.400'} />
                                                    <Text align={'start'} mt={'15px'} lineHeight={'100%'} color={'gray.500'} fontWeight={'bold'}>{'N/A'}</Text>
                                                    <Text align={'start'} mt={'5px'} ml={'15px'} lineHeight={'100%'} color={'white'}>{'Indica que nenhuma ação foi executada para a tarefa programada do dia.'}</Text>
                                                    <Text align={'start'} mt={'5px'} ml={'15px'} lineHeight={'100%'} color={'white'}>{'OBS: MESMO QUE NÃO EXISTA AÇÃO PARA A TAREFA DO DIA, OUTRAS TAREFAS PODEM TER LANCAMENTOS NESSA DATA.'}</Text>
                                                </Box>
                                            }
                                            height={'16px'} color={'white'} />
                                    </Center>
                                </Flex>
                            </Th>
                            {listTarefa.map((item, index) => {
                                return (
                                    <Th key={index} borderRightWidth={'1px'} borderColor={'black'} sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{item.letraCustomizada}</Text>
                                    </Th>
                                )
                            })}
                            <Th w={'180px'} sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={'black'}>
                                <Flex justify={'center'} align={'center'}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Frequência Realizada'}</Text>
                                    <Center py={'6px'} h={'100%'} justifyContent={'center'} alignItems={'end'} ml={'5px'}>
                                        <TooltipIcon
                                            label={
                                                <Box py={'5px'} pb={'10px'}>
                                                    <Text align={'center'} lineHeight={'100%'}>{'Frequência Realizada'}</Text>
                                                    <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'As cores são relacionadas calculando os dias entre a tarefa anterior até a tarefa atual da mesma alternância, com base na frequência.'}</Text>
                                                    <Text align={'start'} mt={'20px'} lineHeight={'100%'}>{'- Exemplo:'}</Text>
                                                    <Text align={'start'} mt={'0px'} ml={'20px'} lineHeight={'100%'}>{'Digamos que o seringueiro tem a frequência D4, e entre a tarefa “A” anterior até a tarefa “A” atual ele levou a quantidade em dias:'}</Text>
                                                    <Text align={'start'} mt={'10px'} ml={'20px'} lineHeight={'100%'} color={'green.400'}>{'Para 4 dias é verde'}</Text>
                                                    <Text align={'start'} mt={'10px'} ml={'20px'} lineHeight={'100%'} color={'yellow.400'}>{'Para 5 dias é amarelo'}</Text>
                                                    <Text align={'start'} mt={'10px'} ml={'20px'} lineHeight={'100%'} color={'red.400'}>{'Para 6 dias ou mais é vermelho'}</Text>
                                                </Box>
                                            }
                                            height={'16px'} color={'white'} />
                                    </Center>
                                </Flex>
                            </Th>
                            <Th w={'60px'} sx={{ paddingX: largTr, paddingY: altura }}>
                                <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'OBS'}</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {listAtividadeSangriaItem.map((item, index) => {
                            const isDiaUtil = item.diaUtil == true ? true : false;
                            const dataEstaEntreInicioAndFimDaProgramacao = item.dataEstaEntreInicioAndFimDaProgramacao == true ? true : false;
                            const idStatusTarefa = item.idStatusTarefa;
                            const tarefaNaoRelizada = item.tarefaNaoRelizada != null ? item.tarefaNaoRelizada : false;
                            const borderColorP = 'black';
                            let corQtdDiasTarefaAtrasada = 'transparent';

                            let dtRealizado = '';
                            let corDataTarefa = 'gray.400';

                            if (item.dtRealizado != null && item.dtRealizado != '') {
                                dtRealizado = item.tarefaRelizadaNoDiaProgramado == true ? 'OK' : moment(item.dtRealizado).format('DD/MM');
                                corDataTarefa = item.tarefaRelizadaNoDiaProgramado == true ? 'green.500' : 'red';
                            } else {
                                dtRealizado = 'N/A';
                                corDataTarefa = 'gray.400';
                            }
                            let val01 = '';
                            let bgDiaUtil = 'transparent';
                            if (isDiaUtil) {
                                //val01 = item.letraTarefa;
                                val01 = item.letraCustomizada;
                                bgDiaUtil = 'white';
                            } else {
                                val01 = item.descDiaSemana;
                                bgDiaUtil = 'gray.500';
                            }

                            let descQtdDiasTarefaAtrasada = item.descQtdDiasTarefaAtrasada.trim();
                            const isExisteCaracter = descQtdDiasTarefaAtrasada.indexOf('0');        // VERIFICO SE EXISTE ESSE CARACTER NA STRING

                            if (item.idQtdDiasTarefaAtrasada == 1) {
                                corQtdDiasTarefaAtrasada = gradientGreen;
                            } else if (item.idQtdDiasTarefaAtrasada == 2) {
                                corQtdDiasTarefaAtrasada = gradientYellow;
                            } else if (item.idQtdDiasTarefaAtrasada == 3) {
                                corQtdDiasTarefaAtrasada = gradientred;
                            } else if (item.idQtdDiasTarefaAtrasada == 0 && isExisteCaracter >= 0) {
                                corQtdDiasTarefaAtrasada = gradientWhite;
                                descQtdDiasTarefaAtrasada = 'Primeira Tarefa';
                            }

                            // TAREFA NAO REALIZADA
                            if (idStatusTarefa == 4 || tarefaNaoRelizada) {
                                return (
                                    <Tr key={index} bg={bgDiaUtil} borderWidth={'1px'} borderColor={borderColorP}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={'12px'} fontWeight={'bold'} align={'center'}>{item.descDate}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            {dataEstaEntreInicioAndFimDaProgramacao ? (
                                                <Flex justify={'space-evenly'} align={'end'}>
                                                    <Flex py={'1px'} justify={'center'} align={'center'} cursor={'pointer'} w={'25px'} borderRadius={'4px'} onClick={() => { verificarSeSafraAtiva(item) }} bg={'blackAlpha.300'}>
                                                        <Text mb={marginText} color={'blue'} fontSize={'13px'} fontWeight={'bold'} lineHeight={'100%'}>{val01}</Text>
                                                    </Flex>
                                                    <Text mb={marginText} w={'45px'} color={corDataTarefa} fontSize={'12px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{dtRealizado}</Text>
                                                </Flex>
                                            ) : (
                                                <Text mb={marginText} w={'45px'} color={corDataTarefa} fontSize={'12px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{''}</Text>
                                            )}
                                        </Td>
                                        <Td colSpan={qtdTarefas} sx={{ paddingX: largTr, paddingY: altura }} bg={'gray.200'} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} pl={'10px'}>{item.descMotivo}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP} bgGradient={corQtdDiasTarefaAtrasada}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{descQtdDiasTarefaAtrasada}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Center py={'0px'} h={'100%'} justifyContent={'center'} alignItems={'center'} >
                                                <TooltipObservacao arrayLabel={item.listObservacao} />
                                            </Center>
                                        </Td>
                                    </Tr>
                                )

                                // SEM ATIVIDADES E DIAS NAO UTIL
                            } else if (item.idQtdDiasTarefaAtrasada == 0 && item.idMotivo == null && isExisteCaracter < 0) {

                                const corDiaNaoUtil = '#fbefcc';
                                let colorTr = isDiaUtil ? 'white' : corDiaNaoUtil;
                                let colorTd = isDiaUtil ? 'gray.200' : corDiaNaoUtil;
                                let labelText = isDiaUtil ? 'Sem Atividades' : '';
                                let bg1 = 'blackAlpha.300';

                                if (!dataEstaEntreInicioAndFimDaProgramacao) {
                                    colorTr = 'white';
                                    colorTd = 'white';
                                    labelText = '';
                                    val01 = '';
                                    dtRealizado = '';
                                    bg1 = 'transparent';
                                }
                                return (
                                    <Tr key={index} bg={colorTr} borderWidth={'1px'} borderColor={borderColorP}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={'12px'} fontWeight={'bold'} align={'center'}>{item.descDate}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            {dataEstaEntreInicioAndFimDaProgramacao && isDiaUtil ? (
                                                <Flex justify={'space-evenly'} align={'end'}>
                                                    <Flex py={'1px'} justify={'center'} align={'center'} cursor={'pointer'} w={'25px'} borderRadius={'4px'} bg={bg1} onClick={() => { verificarSeSafraAtiva(item) }}>
                                                        <Text mb={marginText} color={'blue'} fontSize={'13px'} fontWeight={'bold'} lineHeight={'100%'}>{val01}</Text>
                                                    </Flex>
                                                    <Text mb={marginText} w={'45px'} color={corDataTarefa} fontSize={'12px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{dtRealizado}</Text>
                                                </Flex>
                                            ) : (
                                                <Text mb={marginText} w={'45px'} color={corDataTarefa} fontSize={'12px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{''}</Text>
                                            )}
                                        </Td>
                                        <Td colSpan={qtdTarefas} sx={{ paddingX: largTr, paddingY: altura }} bg={colorTd} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} pl={'10px'}>{labelText}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP} bgGradient={corQtdDiasTarefaAtrasada}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{descQtdDiasTarefaAtrasada}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} >
                                            <Center py={'0px'} h={'100%'} justifyContent={'center'} alignItems={'center'} >
                                                <TooltipObservacao arrayLabel={item.listObservacao} />
                                            </Center>
                                        </Td>
                                    </Tr>
                                )
                            } else {
                                return (
                                    <Tr key={index} bg={bgDiaUtil} borderWidth={'1px'} borderColor={borderColorP}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={'12px'} fontWeight={'bold'} align={'center'}>{item.descDate}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            {dataEstaEntreInicioAndFimDaProgramacao ? (
                                                <Flex justify={'space-evenly'} align={'end'}>
                                                    <Flex py={'1px'} justify={'center'} align={'center'} cursor={'pointer'} w={'25px'} borderRadius={'4px'} bg={'blackAlpha.300'} onClick={() => { verificarSeSafraAtiva(item) }}>
                                                        <Text mb={marginText} color={'blue'} fontSize={'13px'} fontWeight={'bold'} lineHeight={'100%'}>{val01}</Text>
                                                    </Flex>
                                                    <Text mb={marginText} w={'45px'} color={corDataTarefa} fontSize={'12px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{dtRealizado}</Text>
                                                </Flex>
                                            ) : (
                                                <Text mb={marginText} w={'45px'} color={corDataTarefa} fontSize={'12px'} fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{''}</Text>
                                            )}
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaA}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaB}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaC}</Text>
                                        </Td>
                                        {qtdTarefas > 3 &&
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaD}</Text>
                                            </Td>
                                        }
                                        {qtdTarefas > 4 &&
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaE}</Text>
                                            </Td>
                                        }
                                        {qtdTarefas > 5 &&
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaF}</Text>
                                            </Td>
                                        }
                                        {qtdTarefas > 6 &&
                                            <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP}>
                                                <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.realizadaG}</Text>
                                            </Td>
                                        }
                                        <Td sx={{ paddingX: largTr, paddingY: altura }} borderRightWidth={'1px'} borderColor={borderColorP} bgGradient={corQtdDiasTarefaAtrasada}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{descQtdDiasTarefaAtrasada}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Center py={'0px'} h={'100%'} justifyContent={'center'} alignItems={'center'} >
                                                <TooltipObservacao arrayLabel={item.listObservacao} />
                                            </Center>
                                        </Td>
                                    </Tr>
                                )
                            }
                        })}
                        {totalSangriaPorTarefa != null &&
                            <Tr h={'30px'} bgGradient={gradientBlack} borderWidth={'1px'} borderColor={'white'}>
                                <Td colSpan={2} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Sangrias Realizadas'}</Text>
                                </Td>
                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaA}</Text>
                                </Td>
                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaB}</Text>
                                </Td>
                                <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaC}</Text>
                                </Td>
                                {qtdTarefas > 3 &&
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaD}</Text>
                                    </Td>
                                }
                                {qtdTarefas > 4 &&
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaE}</Text>
                                    </Td>
                                }
                                {qtdTarefas > 5 &&
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaF}</Text>
                                    </Td>
                                }
                                {qtdTarefas > 6 &&
                                    <Td sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{totalSangriaPorTarefa.totalTarefaG}</Text>
                                    </Td>
                                }
                                <Td colSpan={2} sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{''}</Text>
                                </Td>
                            </Tr>
                        }
                        {listFrequenciaMedia.length > 0 &&
                            <Tr bgGradient={gradientBlack} borderWidth={'1px'} borderColor={'white'}>
                                <Td colSpan={2} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Flex justify='center' align='center'>
                                        <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Frequência Média'}</Text>
                                        <Center ml={'10px'} h={'100%'} justifyContent={'center'} alignItems={'end'}>
                                            <TooltipIcon
                                                label={
                                                    <Box px={'0px'} py={'5px'} >
                                                        <Text align={'center'}>{'Frequência Média'}</Text>
                                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'É calculada com a divisão dos dias uteis pelas tarefas realizadas.'}</Text>
                                                    </Box>
                                                }
                                                height={'15px'} color={'white'} />
                                        </Center>
                                    </Flex>
                                </Td>
                                {listFrequenciaMedia.map((item, index) => {
                                    let corQtdDiasTarefaAtrasada = 'transparent';
                                    let textColor = 'black';

                                    if (item.idQtdDiasTarefaAtrasada == 1) {
                                        corQtdDiasTarefaAtrasada = gradientGreen;
                                    } else if (item.idQtdDiasTarefaAtrasada == 2) {
                                        corQtdDiasTarefaAtrasada = gradientYellow;
                                    } else if (item.idQtdDiasTarefaAtrasada == 3) {
                                        corQtdDiasTarefaAtrasada = gradientred;
                                    } else {
                                        textColor = 'transparent';
                                    }
                                    return (
                                        <Td key={index} sx={{ paddingX: largTr, paddingY: altura }} borderWidth={'1px'} borderColor={'white'} bgGradient={corQtdDiasTarefaAtrasada}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{item.descQtdDiasTarefaAtrasada}</Text>
                                        </Td>
                                    )
                                })}
                                <Td colSpan={2} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Text mb={marginText} color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{''}</Text>
                                </Td>
                            </Tr>
                        }
                    </Tbody>
                </Table>
            </Box>
        )
    }

    function renderIndicadores() {
        const largTr = 1;
        const ftSiz = '14px';
        return (
            <Box px={'5px'} py={'5px'} borderRadius={'10px'}>
                <Box mt={{ base: 4, md: 0 }}>
                    <TableContainer w={'100%'} overflowY={'auto'} borderRadius={'10px'} borderWidth={'0px'} borderColor={'blackAlpha.700'}>
                        <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                            <Thead>
                                <Tr bgGradient={gradientHeader}>
                                    <Th colSpan={2} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Box textTransform={'none'} px={'10px'}>
                                            <Text mb={marginText} color={'white'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{'Indicador de Sangria'}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Tarefas possíveis de sangria'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{diasPossiveisPeriodo}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Total de tarefas realizadas'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={'green.500'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{realizadas}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Possíveis realizadas'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{`${percentualSangriasPossiveisRealizadas}%`}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Atrasadas no período'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={'red.600'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{realizadasMenosTotalDeDias}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Tarefas Afetadas por Chuva'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{chuvaFeriado}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Tarefas Afetadas por Faltas'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={'red.600'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{faltas}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Índice de eficiência'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{`${percentualIndiceEficiencia}%`}</Text>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    function renderQualidade() {
        const largTr = 1;
        const ftSiz = '14px';
        return (
            <Box px={'5px'} py={'5px'} mt={'10px'} borderRadius={'10px'}>
                <Box mt={{ base: 4, md: 0 }}>
                    <TableContainer w={'100%'} overflowY={'auto'} borderRadius={'10px'} borderWidth={'0px'} borderColor={'blackAlpha.700'}>
                        <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                            <Thead>
                                <Tr bgGradient={gradientHeader}>
                                    <Th colSpan={2} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Box textTransform={'none'} px={'10px'}>
                                            <Text mb={marginText} color={'white'} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{'Qualidade de Sangria'}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Consumo'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalConsumo}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Ferimento'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalProfundidade}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Profundidade'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalFerimento}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Aspectos'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalAspecto}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Total de Pontos'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{totalPontos}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Nota dos seringueiro'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={corNota} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{`${notaSeringueiro}%`}</Text>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={textColor} fontSize={ftSiz} fontWeight={'bold'}>{'Avaliação da sangria'}</Text>
                                    </Td>
                                    <Td w={'55px'} sx={{ paddingX: largTr, paddingY: largTr }}>
                                        <Text mb={marginText} color={corNota} fontSize={ftSiz} fontWeight={'bold'} align={'center'}>{avaliacaoSangria}</Text>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    function renderAcompanhamentoDaProducao() {
        const sizeF = '13px';
        const sizeSubTotal = '15px';
        const espacoW = 0;
        let largTr = 1;
        const alturaTr = '25px';
        let corLinha = false;
        return (
            <Box px={'5px'} py={'5px'} mt={'5px'} borderRadius={'10px'}>
                <TableContainer borderRadius={'10px'} borderWidth={'1px'}>
                    <Table size='sm' variant='unstyled' >
                        <Thead bg={colorFooterAndHeader}>
                            <Tr bgGradient={gradientHeader} h={'30px'}>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'start'} mb={marginText}>{'Mês'}</Text>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Center>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Previsto'}</Text>
                                        </Center>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Center>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Realizado'}</Text>
                                        </Center>
                                    </Box>
                                </Th>
                                <Th sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Box textTransform={'none'}>
                                        <Center>
                                            <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'%'}</Text>
                                        </Center>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {extratoProducao.map((item, key) => {
                                corLinha = !corLinha;
                                const mesdesc = item.mes.substring(0, 3);
                                const ttParcial = item.totalAteMesAnterior;
                                const colorBack = 'transparent';
                                let pyX = '0px';
                                if(ttParcial != null){
                                    largTr = 0;
                                    pyX = '5px';
                                }else{
                                    largTr = 1;
                                    pyX = '0px';
                                }
                                return (
                                    <Tr key={key + 1}  alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.4)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                        <Td w={'10%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Box position={'relative'} w={'100%'}>

                                                <Text mb={marginText} py={pyX} ml={'10px'} color={textColor} fontSize={sizeF} fontWeight='700' lineHeight={'100%'}>{mesdesc}</Text>
                                                {item.listSeringueiroDeletado.length > 0 &&
                                                    <Box position={'absolute'} top={'-5px'} left={'50px'}>
                                                        <TooltipSeringueiro
                                                            label={
                                                                <Box py={'5px'}>
                                                                    <Text align={'center'}>{'Seringueiro Desativado'}</Text>
                                                                    {item.listSeringueiroDeletado.map((x, y) => {
                                                                        const dtDesativado = x.dtDeletado != null ? moment(x.dtDeletado).format('DD/MM/YY') : '';
                                                                        return (
                                                                            <Box key={y} px={'5px'} pb={'5px'}>
                                                                                <Text align={'start'} mt={'10px'} lineHeight='100%'>{`${x.nomeSeringueiroDeletado} - desativado em: ${dtDesativado}`}</Text>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            }
                                                            height={'22px'} color={'red'} />
                                                    </Box>
                                                }
                                                {item.listSeringueiroIncluido.length > 0 &&
                                                    <Box position={'absolute'} top={'-5px'} left={'100px'}>
                                                        <TooltipSeringueiro
                                                            label={
                                                                <Box py={'5px'}>
                                                                    <Text align={'center'}>{'Seringueiro Incluido'}</Text>
                                                                    {item.listSeringueiroIncluido.map((x, y) => {
                                                                        const dtCadastro = x.dtCadastro != null ? moment(x.dtCadastro).format('DD/MM/YY') : '';
                                                                        return (
                                                                            <Box key={y} px={'5px'}>
                                                                                <Text align={'start'} mt={'10px'} lineHeight='100%'>{`${x.nomeSeringueiroIncluido} - iniciou a programação em: ${dtCadastro}`}</Text>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            }
                                                            height={'22px'} color={'green'} />
                                                    </Box>
                                                }

                                            </Box>
                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={alturaTr} justify='start' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} ml={'10px'} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' align={'start'}>{'Total Parcial'}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td w={'20%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Text mb={marginText} py={pyX} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.previsto}</Text>

                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={alturaTr} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalPrevistoAteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td w={'20%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Text mb={marginText} py={pyX} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.realizado}</Text>

                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={alturaTr} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalRealizadoAteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td w={'10%'} sx={{ paddingX: espacoW, paddingY: largTr }} bg={colorBack}>
                                            <Text mb={marginText} py={pyX} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.percentual}%`}</Text>

                                            {ttParcial != null &&
                                                <Flex w={'100%'} h={alturaTr} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{`${ttParcial.totalPercentualAteMesAnterior}%`}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                    </Tr>
                                )
                            })}
                            <Tr bgGradient={gradientHeader}>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} fontWeight='700' mb={marginText}>{'Total ano'}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalPrevisto}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalRealizado}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW, paddingY: largTr }}>
                                    <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalPercentual}%`}</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderCoagulos() {
        const ftSiz = '13px';
        const heightP = '65px';
        return (
            <Box px={'5px'} pb={'10px'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                <Text mb={marginText} py={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Valores Anuais (kg)'}</Text>
                <Wrap spacing='10px' justify={'space-between'} align={'center'}>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} minH={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Prevista'}</Text>
                            <Text mb={marginText} color={'orange.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{totalPrevisto}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} minH={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Realizada'}</Text>
                            <Text mb={marginText} color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{totalRealizado}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} minH={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Saldo Acumulado'}</Text>
                            <Text mb={marginText} color={'red.600'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{saldoAcumulado}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} minH={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Porcentagem Realizado'}</Text>
                            <Text mb={marginText} color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{`${porcentagemRealizada}%`}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} minH={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Por Árvore Prevista'}</Text>
                            <Text mb={marginText} color={'orange.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{producaoCoagulosPorArvorePrevista}</Text>
                        </Flex>
                    </WrapItem>
                    <WrapItem w={'15%'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                        <Flex flexDirection={'column'} justify='space-between' align='center' borderRadius={'10px'} py={'5px'} px={'5px'} w={'100%'} minH={heightP}>
                            <Text color={textColor} fontSize={ftSiz} fontWeight='700' lineHeight='100%' align={'center'}>{'Produção de Coágulos Por Árvore Realizada'}</Text>
                            <Text mb={marginText} color={'green.500'} fontSize={'18px'} fontWeight='700' lineHeight='100%' align={'center'}>{producaoCoagulosPorArvoreRealizada}</Text>
                        </Flex>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'} zIndex="14">
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderBooton() {
        return (
            <Box >
                <Flex justify='start' align='center' py={'0px'}>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                </Flex>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box>
            <Card direction='column' w='100%' px='0px' py='0px'>
                <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
                    {renderAlert()}
                    {renderBooton()}
                    {renderMeses()}
                    <Box pb={'20px'} ref={pdfRef}>
                        <Box position={'relative'}>
                            <Flex flexDirection={'column'} justify='center' align='center' mb={'10px'}>
                                <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'} lineHeight={'100%'}>{'Atividades de Sangria'}</Text>
                                <Text mt={'3px'} color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                                <Text mt={'3px'} color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{`${nomeMunicipio}/${idUf}`}</Text>
                            </Flex>
                            <Box position={'absolute'} top={'1px'} left={'10px'}>
                                <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                            </Box>
                        </Box>
                        {renderDadosRelatorio()}
                        <Grid mb='5px' gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }} gap={{ base: "10px", xl: "10px" }} display={{ base: "block", xl: "grid" }}>
                            <Flex flexDirection='column' gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
                                {listAtividadeSangriaItem.length > 0 && renderAtividadesSangria()}
                            </Flex>
                            <Flex flexDirection='column' gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
                                {listAtividadeSangriaItem.length > 0 && renderIndicadores()}
                                {listAtividadeSangriaItem.length > 0 && renderQualidade()}
                                {listAtividadeSangriaItem.length > 0 && renderAcompanhamentoDaProducao()}
                            </Flex>
                        </Grid>
                        {renderCoagulos()}
                    </Box>
                </Box>
                {renderBooton()}
                {loading && <LoaderBlack isOpen={loading} />}
                {openModalConfimacao && <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { setOpenModalConfimacao(false) }} titulo={'Ops!'} descricao={messageModalConfimacao} />}
            </Card>
        </Box>
    );
};
