import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import * as Api from '../../../store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { mascaraTelefone, retiraMascaraMaterNumeros } from '../../../util/Masck';
import DefaultAuth from "layouts/auth/Default";
import Ilustration from "assets/img/dashboards/auth.png";
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function RedefinirSenha() {

    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [loading, setLoading] = useState(false);
    const [telefone, setTelefone] = useState('');
    const [senha, setSenha] = useState('');
    const [isValidTelefone, setIsValidTelefone] = useState(false);
    const [isValidSenha, setIsValidSenha] = useState(false);
    const [isValidSenhaDiferente, setIsValidSenhaDiferente] = useState(false);
    const [mensageTelefone, setMensageTelefone] = useState('');
    const [mensageSenha, setMensageSenha] = useState('');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [novaSenha, setNovaSenha] = useState('');
    const [isValidNovaSenha, setIsValidNovaSenha] = useState(false);
    const [mensageNovaSenha, setMensageNovaSenha] = useState('');

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    useEffect(() => {
        async function loadScreen() {
            console.log('');
        }
        loadScreen();
    }, []);

    const redefinirSenhaApi = async () => {
        try {
            let valid = false;
            if (telefone.length < 15) {
                setIsValidTelefone(true);
                setMensageTelefone('Telefone inválido');
                valid = true;
            } else {
                setIsValidTelefone(false);
                setMensageTelefone('');
            }

            if (senha.length < 6) {
                setIsValidSenha(true);
                setMensageSenha('Mínimo 6 caracteres');
                valid = true;
            } else {
                setIsValidSenha(false);
                setMensageSenha('');
            }

            if (novaSenha.length < 6) {
                setIsValidNovaSenha(true);
                setMensageNovaSenha('Mínimo 6 caracteres');
                valid = true;
            } else {
                setIsValidNovaSenha(false);
                setMensageNovaSenha('');
            }

            if (senha != novaSenha) {
                setIsValidSenhaDiferente(true);
                valid = true;
            } else {
                setIsValidSenhaDiferente(false);
            }

            if (valid) return;

            const telSemMascara = retiraMascaraMaterNumeros(telefone);
            const jsonRedefinirSenha = {
                numeroCelular: telSemMascara,
                novaSenha: senha,
            }

            setLoading(true);
            const response = await Api.putRequestSemAccessToken(`api/usuario/redefinirSenhaSemValidacaoPorCelular`, jsonRedefinirSenha);
            setLoading(false);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;

                const senhaRedefinida = res.senhaRedefinida != null ? res.senhaRedefinida : false;
                if (senhaRedefinida) {
                    setOpenModalConfimacao(true);
                } else {
                    setMensageTelefone('Telefone não localizado na base de dados');
                    setIsValidTelefone(true);
                }
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Erro na classe RedefinirSenha metodo redefinirSenhaApi', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const acessarLogin = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setTelefone(telComMascara);
    }

    const handleSenha = (e) => {
        const pass = e.target.value
        setSenha(pass.trim());
    }

    const handleNovaSenha = (e) => {
        const pass = e.target.value
        setNovaSenha(pass.trim());
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} >
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    return (
        <DefaultAuth illustrationBackground={Ilustration} image={Ilustration}>
            {renderAlert()}
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>Redefinir Senha</Heading>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}>
                    <Text pl={'10px'} mb={'5px'} color={textColor} fontSize='14px' fontWeight='700' lineHeight='100%'>Telefone</Text>
                    <Input
                        value={telefone}
                        onChange={changeCelular}
                        isInvalid={isValidTelefone}
                        errorBorderColor='crimson'
                        mask="(99)9999-9999"
                        id="telefone"
                        variant='auth'
                        ms={{ base: "0px", md: "0px" }}
                        placeholder='(99) 9999-9999'
                        mb='5px'
                        size='lg'
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        h={'35px'}
                        borderColor={'gray.400'}
                        borderRadius={'10px'}
                    />
                    {isValidTelefone && <Text pl={'10px'} color={'red.400'} fontSize='14px' fontWeight='700' lineHeight='100%'>{mensageTelefone}</Text>}
                    <Text pl={'10px'} mb={'5px'} mt={'20px'} color={textColor} fontSize='14px' fontWeight='700' lineHeight='100%'>Nova Senha</Text>
                    <InputGroup size='md'>
                        <Input
                            onChange={handleSenha}
                            value={senha}
                            isInvalid={isValidSenha}
                            errorBorderColor='crimson'
                            placeholder='Min. 6 characteres'
                            mb='5px'
                            size='lg'
                            type={show ? "text" : "password"}
                            variant='auth'
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            borderColor={'gray.400'}
                            borderRadius={'10px'}
                        />
                        <InputRightElement display='flex' alignItems='center'>
                            <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick}
                            />
                        </InputRightElement>
                    </InputGroup>
                    {isValidSenha && <Text pl={'10px'} color={'red.400'} fontSize='14px' fontWeight='700' lineHeight='100%'>{mensageSenha}</Text>}
                    <Text pl={'10px'} mb={'5px'} mt={'20px'} color={textColor} fontSize='14px' fontWeight='700' lineHeight='100%'>confirmar Senha</Text>
                    <InputGroup size='md'>
                        <Input
                            onChange={handleNovaSenha}
                            value={novaSenha}
                            isInvalid={isValidNovaSenha}
                            errorBorderColor='crimson'
                            placeholder='Min. 6 characteres'
                            mb='5px'
                            size='lg'
                            type={show ? "text" : "password"}
                            variant='auth'
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                            borderColor={'gray.400'}
                            borderRadius={'10px'}
                        />
                        <InputRightElement display='flex' alignItems='center'>
                            <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick}
                            />
                        </InputRightElement>
                    </InputGroup>
                    {isValidNovaSenha && <Text pl={'10px'} color={'red.400'} fontSize='14px' fontWeight='700' lineHeight='100%'>{mensageNovaSenha}</Text>}
                    <Flex h={'60px'} justify={'center'} align={'center'}>
                        {isValidSenhaDiferente && <Text color={'red.400'} fontSize='17px' fontWeight='700' lineHeight='100%' align={'center'}>{'As senhas estão diferentes'}</Text>}
                    </Flex>
                    <Flex px={'0px'} py={'20px'} justify={'space-between'} align={'center'}>
                        <Button
                            variant="brand"
                            h={'40px'}
                            w={'100px'}
                            fontSize={'18px'}
                            onClick={() => { history.goBack() }}>Voltar</Button>
                        <Button
                            variant="brand"
                            h={'40px'}
                            w={'100px'}
                            fontSize={'18px'}
                            onClick={() => { redefinirSenhaApi() }}>Salvar</Button>
                    </Flex>
                </Flex>
            </Flex>
            <Loader isOpen={loading} />
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { acessarLogin() }} descricao={'Operação realizada com sucesso!'} />
        </DefaultAuth>
    );
}


