import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Wrap,
    Select,
    Center,
    Image,
    Icon,
    TableContainer,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import moment from 'moment';
import Loader from 'components/loading/Loader';
import { RiEdit2Fill } from "react-icons/ri";
import { MdDoubleArrow } from "react-icons/md";
import { RETORNA_DATA_INICIO_FIM_PARA_CALENDARIO_ESTIMULACAO } from '../../../util/FuncoesUteis';
import TooltipIcon from 'components/tooltip/TooltipIcon';

export default function FormAdubacao() {

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [screenFullLoad, setScreenFullLoad] = useState(false);
    const [idUa, setIdUa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [arrySeringueiros, setArrySeringueiros] = useState([]);
    const [seringueiroSelecionado, setSeringueiroSelecionado] = useState('');
    const [arraySeringueiroFiltro, setArraySeringueiroFiltro] = useState([]);
    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [clone, setClone] = useState('');
    const [tamanhoLote, setTamanhoLote] = useState('');
    const [qtdArvoes, setQtdArvoes] = useState('');
    const [producaoEsperada, setProducaoEsperada] = useState('');
    const [painel, setPainel] = useState('');
    const [frequencia, setFrequencia] = useState('');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [listTarefaBasica, setListTarefaBasica] = useState([]);
    const [listAtividadesGeral, setListAtividadesGeral] = useState([]);
    const [qtdTarefas, setQtdTarefas] = useState(0);
    const [sangriaAtiva, setSangriaAtiva] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientHeaderGray = 'linear-gradient(gray.700, gray.600, gray.500)';
    const gradientGray = 'linear-gradient(gray.700, gray.600, gray.500)';

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);
                setLoading(true);

                const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
                const isAtiva = sangriaAtivaP == 'false' ? true : false;
                setSangriaAtiva(isAtiva);

                localStorage.removeItem('@HE-telaForm-adubacao');
                localStorage.removeItem('@HE-DadosTela-formAdubacao');

                const idUa = localStorage.getItem('@HE-idUa');
                const idSafra = localStorage.getItem('@HE-idSafra');
                setIdUa(idUa);
                setIdSafra(idSafra);

                const dtIntSafra = localStorage.getItem('@HE-dtInicioSafraAsync');
                const dtFimSafraX = localStorage.getItem('@HE-dtFimSafraAsync');
                const resDatas = await RETORNA_DATA_INICIO_FIM_PARA_CALENDARIO_ESTIMULACAO(dtIntSafra, dtFimSafraX);
                const dtInt = resDatas.dtInit;
                const dtFim = resDatas.dtFim;
                setDtInicioSafra(dtInt);
                setDtFimSafra(dtFim);

                const url = `safraPessoa/listaPessoaParaUmaUaAndSafra?idUa=${idUa}&idSafra=${idSafra}`
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    if (res.length > 0) {
                        let arrayPess = [];
                        await new Promise(resolve => {
                            resolve(
                                res.map(function (item) {
                                    const obj = {
                                        label: item.nome,
                                        value: `${item.idPessoa}`
                                    }
                                    arrayPess.push(obj);
                                })
                            )
                        });
                        setArraySeringueiroFiltro(arrayPess);
                    }
                    const idPessoaRetorno = localStorage.getItem('@HE-id-pessoa-retorno');
                    if (idPessoaRetorno != null) await changePessoa(idPessoaRetorno, idUa, idSafra, dtInt, dtFim);
                    localStorage.removeItem('@HE-id-pessoa-retorno');

                    setLoading(false);
                } else {
                    setLoading(false);
                    setScreenFullLoad(true);
                }
                setScreenFullLoad(true);

            } catch (error) {
                setScreenFullLoad(true);
                setLoading(false);
                console.log('Erro na classe FormAdubacao metodo useEffect', error);
            }
        }
        loaderScreen();
    }, [])

    const changePessoa = async (idPessoaX, idUaX, idSafraX, dtInit, dtFim) => {
        try {
            const idPes = idPessoaX;

            if (idPes != null) {
                setSeringueiroSelecionado(idPes);
                setLoading(true);

                const url = `estimulacao/listaEstimulacaoCompleto?idUa=${idUaX}&idSafra=${idSafraX}&idPessoa=${idPes}&dtInicio=${dtInit}&dtFim=${dtFim}&idTipoCalendario=${ActionTypes.ID_TIPO_CALENDARIO_ADUBACAO}`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta == ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;
                    const listAtividades = res.listAtividadesGeralItem;

                    setListAtividadesGeral(listAtividades);
                    setListTarefaBasica(res.listTarefaBasica);
                    setClone(res.clone);
                    setProducaoEsperada(res.producaoEsperada);
                    setQtdArvoes(res.qtdArvores);
                    setPainel(res.painel);
                    setFrequencia(res.frequencia);
                    setNomeSeringueiro(res.nomeSeringueiro);
                    setArrySeringueiros(res.listSemana);
                    setQtdTarefas(res.qtdTarefas);

                    setLoading(false);
                } else {
                    setLoading(false);
                }

            } else {
                setSeringueiroSelecionado('');
                setArrySeringueiros([]);
                setListTarefaBasica([]);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('', error);
        }
    }

    const changeComboPessoa = async (idPes) => {
        changePessoa(idPes, idUa, idSafra, dtInicioSafra, dtFimSafra);
    }

    const telaEstimulacaoPdf = () => {
        const json = {
            clone: clone,
            tamanhoLote: tamanhoLote,
            qtdArvoes: qtdArvoes,
            producaoEsperada: producaoEsperada,
            painel: painel,
            frequencia: frequencia,
            nomeSeringueiro: nomeSeringueiro,
            idPessoa: seringueiroSelecionado,
            listTarefaBasica: listTarefaBasica,
            arrySeringueiros: arrySeringueiros,
            listAtividadesGeral: listAtividadesGeral,
            qtdTarefas: qtdTarefas,
            idTipoCalendario: ActionTypes.ID_TIPO_CALENDARIO_ADUBACAO,
        }
        localStorage.setItem('@HE-telaForm-adubacao', JSON.stringify(json));
        history.push("/admin/adubacao-pdf");
    }

    const changeSelect = (letraParam, numeroSemana, dtInicioSemana, dtFimSemana, dtParam, statusPeriodo, idEstimulacao) => {
        const ano = moment(dtFimSemana).format('YYYY');
        const json = {
            idUa: idUa,
            idSafra: idSafra,
            idPessoa: seringueiroSelecionado,
            nomeSeringueiro: nomeSeringueiro,
            letraParam: letraParam,
            numeroSemana: numeroSemana,
            dtInicioSemana: dtInicioSemana,
            dtFimSemana: dtFimSemana,
            dtParam: dtParam,
            statusPeriodo: statusPeriodo,
            idEstimulacao: idEstimulacao,
            ano: ano,
            clone: clone,
            tamanhoLote: tamanhoLote,
            qtdArvoes: qtdArvoes,
            producaoEsperada: producaoEsperada,
            painel: painel,
            frequencia: frequencia,
            listAtividadesGeral: listAtividadesGeral,
            qtdTarefas: qtdTarefas,
        }
        localStorage.setItem('@HE-DadosTela-formAdubacao', JSON.stringify(json));
        history.push("/admin/lancar-adubacao");
    }


    function renderSeringueiros() {
        return (
            <Box>
                <Flex justify='center' align='center'>
                    <Flex px={'40px'} py={'5px'} display={'inline-flex'} justify='center' align='center' bgGradient={gradientHeaderGray} borderRadius={'10px'} >
                        <Text color={'white'} fontSize='18px' fontWeight={'bold'} align={'center'}>{'Calendário de Controle Fitossanitário de Painel/Adubação'}</Text>
                    </Flex>
                </Flex>
                <Wrap spacing='3px' px={'5'} mb={'20px'} mt={'5px'} align={'center'} justify='space-between'>
                    <Box>
                        <Flex justify='start' align='center' >
                            <Box>
                                <Text ml={'10px'} color={textColor} fontSize='16px' fontWeight='500'>{'Selecione um Seringueiro para editar'}</Text>
                                <Select
                                    value={seringueiroSelecionado}
                                    errorBorderColor='crimson'
                                    placeholder="Selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    onChange={(e) => { changeComboPessoa(e.target.value) }}
                                    w={'350px'}
                                    h={'35px'}>
                                    {arraySeringueiroFiltro.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box mt={'20px'} ml={'10px'}>
                                <TooltipIcon
                                    label={
                                        <Box py={'5px'} >
                                            <Text align={'center'}>{'Selecione um seringueiro para'}</Text>
                                            <Text align={'center'}>{'carregar o calendário'}</Text>
                                        </Box>
                                    }
                                    height={'23px'} />
                            </Box>
                        </Flex>
                    </Box>
                    <Button
                        disabled={seringueiroSelecionado != '' ? false : true}
                        _hover={{ boxShadow: "none" }}
                        h="50px"
                        w={'80px'}
                        bg="transparent"
                        mb={'3px'}
                        onClick={telaEstimulacaoPdf}>
                        <Flex justify='flex-end' align='center' borderWidth={'2px'} borderRadius={'10px'} >
                            <Image boxSize='35px' objectFit='contain' src={`${ActionTypes.LINK_GOOGLE_STORAGE}/pdf_icon_download.png`} alt='Dan Abramov' />
                            <Icon as={MdDoubleArrow} width='20px' height='20px' color='inherit' />
                        </Flex>
                    </Button>
                </Wrap>
            </Box>
        )
    }

    function renderInfo() {
        const sizeF = '13px';
        const largTr = 0;
        const altura = 0;
        return (
            <Box>
                <TableContainer borderTopRadius={'8px'} borderBottomRadius={'8px'}>
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'25px'} bgGradient={gradientHeader}>
                                <Th w={'10%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'} >
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Tarefa/Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Árvores em Sangria'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Produção Lote'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Clone'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Painel'}</Text>
                                    </Box>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: largTr, paddingY: altura }}>
                                    <Box textTransform={'none'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Idade Sangria'}</Text>
                                    </Box>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listAtividadesGeral.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.letraCustomizada}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.tamanhoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.producaoLote}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomeClone}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.nomePainel}</Text>
                                        </Td>
                                        <Td sx={{ paddingX: largTr, paddingY: altura }}>
                                            <Text color={textColor} fontSize={sizeF} fontWeight={'medium'} align={'center'}>{item.idadeSangria}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            {listAtividadesGeral.length > 0 &&
                                <Tr h={'25px'} bgGradient={gradientHeader} >
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'} >
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{'Total'}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{qtdArvoes}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{producaoEsperada}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                    <Th sx={{ paddingX: largTr, paddingY: altura }}>
                                        <Box textTransform={'none'}>
                                            <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} align={'center'}>{''}</Text>
                                        </Box>
                                    </Th>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderLegenda() {
        return (
            <Box mt={'5px'} px={'20px'}>
                <Flex justify='start' mt={'10px'} align='center'>
                    <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Frequência:'}</Text>
                    <Text ml={'15px'} color={'orange.600'} fontSize='18px' fontWeight='700' align={'center'}>{frequencia}</Text>
                </Flex>
                <Flex justify='start' mt={'10px'} align='start'>
                    <Box w={'20px'} h={'20px'} borderRadius={'5px'} borderWidth={'3px'} borderColor={'orange.900'} bg={'orange.900'} />
                    <Text ml={'20px'} color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Número semana'}</Text>
                </Flex>
                <Flex justify='start' align='start'>
                    <Box w={'20px'} h={'20px'} borderRadius={'5px'} borderWidth={'1px'} borderColor={'orange.700'} bg={'orange.400'} />
                    <Text ml={'20px'} color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Tarefa'}</Text>
                </Flex>
                <Flex justify='start' align='start'>
                    <Box w={'20px'} h={'20px'} borderRadius={'5px'} borderWidth={'1px'} borderColor={'orange.700'} bg={'blue.500'} />
                    <Text ml={'20px'} color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Previsto'}</Text>
                </Flex>
                <Flex justify='start' align='start'>
                    <Box w={'20px'} h={'20px'} borderRadius={'5px'} borderWidth={'1px'} borderColor={'orange.700'} bg={'green.400'} />
                    <Text ml={'20px'} color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Realizado'}</Text>
                </Flex>
            </Box>
        )
    }

    function renderEstimulacao() {
        const corLingt = 'rgba(255, 166, 77, 0.01)';
        const corBlack = 'rgba(255, 166, 77, 0.3)';
        const SHADOW_GREEN = '1px 1px #00b300';
        const SHADOW_BLUE = '1px 1px #0033cc';
        return (
            <Box>
                <Box position={'relative'}>
                    <Box display={'flex'} overflowY="hidden" whiteSpace={'nowrap'} overflowX={'auto'} maxWidth={'100%'}>
                        <Box opacity={sangriaAtiva ? '0.6' : '1'} pointerEvents={sangriaAtiva ? 'none' : 'auto'}>
                            <Flex justify='start' mt={'10px'} align='start'>
                                <Box w={'40px'}>
                                    <Box h={'30px'} borderTopLeftRadius={'10px'} bg={'white'}>
                                        <Text color={'white'} fontSize='14px' fontWeight='700' align={'center'}>{''}</Text>
                                    </Box>
                                    <Box h={'60px'} borderTopLeftRadius={'10px'} bg={arrySeringueiros.length > 0 ? 'white' : 'white'}>
                                        <Text color={'white'} fontSize='14px' fontWeight='700' align={'center'}>{''}</Text>
                                    </Box>
                                </Box>
                                {arrySeringueiros.map((item, index) => {
                                    let dtInicioSemana = item.dtInicioSemana != null && item.dtInicioSemana != '' ? moment(item.dtInicioSemana).format('DD/MM/YY') : '';
                                    let dtFimSemana = item.dtFimSemana != null && item.dtFimSemana != '' ? moment(item.dtFimSemana).format('DD/MM/YY') : '';
                                    return (
                                        <Flex key={index} w={'90px'} justify='center' align='center'>
                                            <Box>
                                                <Box h={'30px'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                                    <Box
                                                        w={'30px'}
                                                        h={'25px'}
                                                        display={'flex'}
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        borderRadius={'8px'}
                                                        borderWidth={'2px'}
                                                        borderColor={'orange.900'}
                                                        bg={'orange.900'}
                                                    >
                                                        <Text color={'white'} fontSize='14px' fontWeight='700'>{item.numeroSemana}</Text>
                                                    </Box>
                                                </Box>
                                                <Box h={'60px'} borderRightWidth={'1px'} borderColor={'white'} bgGradient={gradientHeader}>
                                                    <Center h={'100%'} flexDirection={'column'}>
                                                        <Text color={'white'} fontSize='14px' fontWeight='100' align={'center'} lineHeight='100%'>{dtInicioSemana}</Text>
                                                        <Text color={'white'} fontSize='14px' fontWeight='100' align={'center'} >{'à'}</Text>
                                                        <Text color={'white'} fontSize='14px' fontWeight='100' align={'center'} lineHeight='100%'>{dtFimSemana}</Text>
                                                    </Center>
                                                </Box>
                                                {item.listTarefas.map((x, j) => {
                                                    let corLinha2 = corLingt;
                                                    if (x.letra == 'A') {
                                                        corLinha2 = corLingt;
                                                    } else if (x.letra == 'B') {
                                                        corLinha2 = corBlack;
                                                    } else if (x.letra == 'C') {
                                                        corLinha2 = corLingt;
                                                    } else if (x.letra == 'D') {
                                                        corLinha2 = corBlack;
                                                    } else if (x.letra == 'E') {
                                                        corLinha2 = corLingt;
                                                    } else if (x.letra == 'F') {
                                                        corLinha2 = corBlack;
                                                    } else {
                                                        corLinha2 = corLingt;
                                                    }
                                                    let descDtSelecionada = '';
                                                    let colorDt = 'transparent';
                                                    let shadowStatus = 'transparent';

                                                    if (x.descDtSelecionada == '') {
                                                        descDtSelecionada = '__/__/__';
                                                    } else {
                                                        descDtSelecionada = x.descDtSelecionada;
                                                        if (x.statusPeriodo != '') {
                                                            colorDt = 'blackAlpha.600';

                                                            if (x.statusPeriodo == '3' || x.statusPeriodo == '4' || x.statusPeriodo == '5') {
                                                                shadowStatus = SHADOW_BLUE;
                                                            } else if (x.statusPeriodo == '6' || x.statusPeriodo == '7' || x.statusPeriodo == '8') {
                                                                shadowStatus = SHADOW_GREEN;
                                                            }
                                                        }
                                                    }
                                                    let descStatus = x.descStatus == '' ? '-' : x.descStatus;
                                                    return (
                                                        <Box key={j} position={'relative'} w={'90px'} h={'70px'} borderRightWidth={'2px'} borderColor={'blackAlpha.300'} bg={corLinha2} >
                                                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'100%'}>
                                                                <Flex h={'100%'} flexDirection={'column'} justify='center' align='center'>
                                                                    <Text mt={'5px'} textShadow={shadowStatus} color={colorDt} fontSize='14px' fontWeight={'bold'} align={'center'} lineHeight={'100%'} whiteSpace={'break-spaces'}>{descStatus}</Text>
                                                                    <Text mt={'5px'} textShadow={shadowStatus} color={colorDt} fontSize='14px' fontWeight={'bold'} align={'center'} lineHeight={'100%'}>{descDtSelecionada}</Text>
                                                                </Flex>
                                                            </Box>
                                                            <Box position={'absolute'} right={'-2px'} top={'-5px'}>
                                                                <Flex
                                                                    justify={'center'}
                                                                    align={'center'}
                                                                    borderRadius={'10px'}
                                                                    p={'3px'}
                                                                    _hover={{ bg: 'blackAlpha.400' }}
                                                                    onClick={() => { changeSelect(x.letra, item.numeroSemana, item.dtInicioSemana, item.dtFimSemana, x.dtSelecionada, x.statusPeriodo, x.idEstimulacao) }}
                                                                    cursor={'pointer'}>
                                                                    <Icon as={RiEdit2Fill} width='20px' height='20px' color='inherit' />
                                                                </Flex>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </Flex>
                                    )
                                })}
                            </Flex>
                        </Box>
                    </Box>
                    <Box pos="absolute" left={'0px'} top={'100px'} zIndex={2} >
                        {listTarefaBasica.map((rr, ww) => {
                            return (
                                <Box key={ww} display={'flex'} justifyContent={'center'} alignItems={'center'} w={'40px'} h={'70px'} bg={'white'}>
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'30px'} h={'25px'} borderRadius={'8px'} bg={'orange.400'}>
                                        <Text color={textColorHeader} fontSize='15px' fontWeight='700' >{rr.letraCustomizada}</Text>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        )
    }

    if (!screenFullLoad) {
        return (
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Loader isOpen={loading} />
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='0px' py='20px'>
                {renderSeringueiros()}
                {renderInfo()}
                {renderLegenda()}
                {renderEstimulacao()}
                <Loader isOpen={loading} />
            </Card>
        </Box>
    );
};
