import React from "react";
import { Tooltip, Icon } from "@chakra-ui/react";
import { WarningTwoIcon } from '@chakra-ui/icons';      // ESSE CARA NAO PRECISA DE span
import { GiFarmer } from "react-icons/gi";              // PARA USAR react-icons PRECISA COLOCAR DENTRO DE UM span

const TooltipSeringueiro = ({ label = '', height = '23px', color = 'orange.600' }) => {

    return (
        <Tooltip label={label} borderRadius={'10px'} size="sm" bg={'black'}>
            <span>
                <Icon as={GiFarmer} width={height} height={height} color={color} />
            </span>
            {/* <WarningTwoIcon w={height} h={height} color={color} /> */}
        </Tooltip>
    );
};

export default TooltipSeringueiro;
