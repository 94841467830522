import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
} from "@chakra-ui/react";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/layout/banner.png";
import avatar from "assets/img/layout/logo_21.png";
import Notifications from "views/admin/profile/components/Notifications";

export default function Overview() {

    const [nomeUsuario, setNomeUsuario] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [tipoUsuario, setTipoUsuario] = useState('');
    const [objPermissoes, setObjPermissoes] = useState({});

    useEffect(() => {
        async function loaderScreen() {

            const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
            const obj = JSON.parse(val);
            setObjPermissoes(obj);

            const nomeUser = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');
            const tel = localStorage.getItem('@HE-telefoneUsuariLogado');
            const emailPessoal = localStorage.getItem('@HE-emailPessoalUsuario');
            const nomeUa = localStorage.getItem('@HE-nomeUa');
            const perfil = localStorage.getItem('@HE-descPerfilUsuarioLogado');

            setTipoUsuario(perfil);
            setNomeUsuario(nomeUser);
            setTelefone(tel);
            setEmail(emailPessoal);
            setNomeFazenda(nomeUa);

        }
        loaderScreen();
    }, []);

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='2px'>
                <Banner
                    banner={banner}
                    avatar={avatar}
                    name={nomeUsuario}
                    job={tipoUsuario}
                    posts='5'
                    followers='1'
                    following='1'
                />
            </SimpleGrid>
            {objPermissoes.gerente &&            
                <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
                    <Notifications
                        banner={banner}
                        avatar={avatar}
                        name='Adela Parkson'
                        job='Product Designer'
                        posts='17'
                        followers='9.7k'
                        following='274'
                    />
                </SimpleGrid>
            }
        </Box>
    );
}
